import { createContext, FC, useEffect, useState } from "react";

import { StreamChat, DefaultGenerics } from "stream-chat";
import shallow from "zustand/shallow";

import config from "../lib/config";
import { UserState, useUserStore } from "../store/useUserStore";

export type ChatContextState = {
  chatClient: StreamChat<DefaultGenerics> | null;
};

export const ChatContext = createContext<ChatContextState>(null!);

const currentUserSelector = (state: UserState) => ({
  userRole: state.role,
  getStreamId: state.getStreamId,
  firstName: state.firstName,
  lastName: state.lastName,
});

const ChatContextProvider: FC = ({ children }) => {
  const [chatClient, setChatClient] = useState<null | StreamChat<DefaultGenerics>>(null);

  const id = useUserStore(state => state.id);

  const { firstName, lastName } = useUserStore(currentUserSelector, shallow);

  useEffect(() => {
    (async () => {
      if (!chatClient && id) {
        const client = StreamChat.getInstance(config.STREAM_KEY!);

        await client.connectUser(
          {
            id: id!,
            name: `${firstName} ${lastName}`,
          },

          client.devToken(id!)
        );

        setChatClient(client);
      }
    })();

    return () => {
      chatClient && chatClient.disconnectUser();
    };
  }, [id]);

  const contextValue = {
    chatClient,
  };

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
};

export default ChatContextProvider;
