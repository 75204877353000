import { useContext } from "react";

import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import requirementsService from "../../services/requirements";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { ErrorContent } from "../../types/default";
import { MixpanelEvents } from "../../types/mixpanel";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;
const currentUserSelector = (state: UserState) => state;

export const useCreateManagerJobRequirement = () => {
  const { mixpanelTrack } = useContext(MixpanelContext);
  const addSnackBar = useSnackbar(addSnackbarSelector);
  const currentUser = useUserStore(currentUserSelector);

  const { mutate: createManagerJobRequirement, isLoading } = useMutation(
    requirementsService.createManagerJobRequirement,
    {
      mutationKey: QUERY_STRINGS.CREATE_MANAGER_JOB_REQUIREMENT,
      onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
      onSuccess: () => {
        mixpanelTrack(MixpanelEvents.CREATE_MANAGER_JOB_REQUIREMENT, {
          user: `${currentUser.firstName} ${currentUser.lastName}`,
          email: currentUser.email,
          role: currentUser.role,
        });
      },
    }
  );

  return { createManagerJobRequirement, isLoading };
};
