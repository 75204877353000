import { DEFAULT_PAGE } from "../constants";
import { FieldOptionNames, FormFieldsConstructor, SelectOptionsType } from "../types/default";
import { FilterOptions } from "../types/main";
import { RequirementData } from "../types/requirements";
import { RegisterConsultantFormTypes, UserBaseTypes } from "../types/users";
import { useConsultants } from "./consultants/useConsultants";
import { useEditConsultant } from "./consultants/useEditConsultant";
import { useRequirements } from "./requirements/useRequirements";
import { useSkills } from "./skills/useSkills";
import { useManagers } from "./users/useManagers";

export const useConsultantsScreenDataFetch = (
  masterUserList: boolean,
  page: number,
  perPage: number,
  handleChangePage?: (newPage: number) => void,
  filterOptions?: FilterOptions
) => {
  const { isLoading: isConsultantsLoading, usersData } = useConsultants(page, perPage, handleChangePage, filterOptions);

  const { skillsData, isLoading: isSkillsLoading } = useSkills();
  const { data: requirementsData, isLoading: isRequirementsLoading } = useRequirements(
    DEFAULT_PAGE,
    undefined,
    undefined,
    masterUserList
  );
  const { usersData: managersData, isLoading: isLoadingManagers } = useManagers();
  const { editConsultant, isLoading: isEditLoading } = useEditConsultant();

  const jobRequirements = requirementsData?.data.map((requirement: RequirementData) => ({
    id: requirement.id,
    name: requirement.title,
  }));

  const managersOptions = managersData?.data.map((manager: UserBaseTypes) => ({
    id: manager.id,
    name: `${manager.firstName} ${manager.lastName}`,
  }));

  const isLoading =
    isSkillsLoading || isRequirementsLoading || isLoadingManagers || isEditLoading || isConsultantsLoading;

  const getOptions = (field: FormFieldsConstructor<RegisterConsultantFormTypes>): SelectOptionsType[] | undefined => {
    switch (field.name) {
      case FieldOptionNames.SKILLS:
        return skillsData?.data;
      case FieldOptionNames.JOB_REQUIREMENTS:
        return jobRequirements;
      case FieldOptionNames.MANAGERS:
        return managersOptions;
      default:
        return field.options;
    }
  };

  return {
    editConsultant,
    isLoading,
    getOptions,
    skillsData,
    requirementsData,
    usersData,
  };
};
