import { TEXT_AND_NUMBERS_REGEX } from "../../../constants";
import { FormConstructor, SelectOptionsType } from "../../../types/default";
import { FieldType } from "../../../types/login";

export const addCompanyConfig: FormConstructor<SelectOptionsType> = {
  fields: [
    {
      name: "name",
      label: "companies:companyName",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_AND_NUMBERS_REGEX,
          label: "companies:errors.invalidCompanyName",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
  ],
};
