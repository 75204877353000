import { FC } from "react";

import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CardContentSection, ConsultantsTable } from "../components";
import { useConsultantById } from "../hooks/consultants/useConsultantById";
import Loading from "../layouts/Loading";

const SingleConsultant: FC = () => {
  const { t } = useTranslation();

  const { consultantId } = useParams<{ consultantId: string }>();

  const { consultantData, isLoading } = useConsultantById(consultantId!);

  if (isLoading || !consultantData) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t("consultants:consultantDetailsDescription", {
            firstName: consultantData.firstName,
            lastName: consultantData.lastName,
          })}
        />
        <title>
          {t("consultants:consultantDetails")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <Box paddingTop={0}>
        <CardContentSection
          title={t("consultants:consultantDetails")}
          table={<ConsultantsTable usersData={[consultantData]} />}
          hasAvailabilityData
        />
      </Box>
    </>
  );
};

export default SingleConsultant;
