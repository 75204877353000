import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import upcomingCVsService from "../../services/upcomingCVs";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useSendUpcomingCV = () => {
  const { t } = useTranslation();
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const { mutate: sendUpcomingCV, isLoading } = useMutation(upcomingCVsService.sendUpcomingCV, {
    mutationKey: QUERY_STRINGS.SEND_UPCOMING_CV,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => addSnackbar({ type: "success", text: t("upcomingCVs:sentCVs") }),
  });

  return { sendUpcomingCV, isLoading };
};
