import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useConsultantsBySublist = (sublistId?: string, page?: number, perPage?: number) => {
  const addSnackBar = useSnackbar(addSnackBarSelector);

  const { isLoading, data: usersData } = useQuery(
    [QUERY_STRINGS.GET_CONSULTANTS_BY_SUBLIST, page, perPage, sublistId],
    () => consultantsService.getConsultantsBySublist(sublistId!, page, perPage),
    {
      keepPreviousData: true,
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
    }
  );

  return {
    isLoading,
    usersData,
  };
};
