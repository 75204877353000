import { useConsultants } from "./consultants/useConsultants";
import { useAddConsultantsToRequirement } from "./requirements/useAddConsultantsToRequirement";
import { useEditJobRequirement } from "./requirements/useEditJobRequirement";
import { useSkills } from "./skills/useSkills";
import { useManagers } from "./users/useManagers";

export const useRequirementsDataFetch = () => {
  const { skillsData, isLoading: isSkillsLoading } = useSkills();
  const { usersData: managersData, isLoading: isLoadingManagers } = useManagers();

  const { usersData, isLoading: isConsultantsLoading } = useConsultants();
  const { addConsultantsToRequirement, isLoading: isAddingConsultants } = useAddConsultantsToRequirement();
  const { editJobRequirement, isLoading: isEditRequirementLoading } = useEditJobRequirement();

  const isLoading =
    isConsultantsLoading || isAddingConsultants || isEditRequirementLoading || isSkillsLoading || isLoadingManagers;

  return {
    skillsData: skillsData?.data,
    managersData: managersData?.data,
    usersData,
    addConsultantsToRequirement,
    editJobRequirement,
    isLoading,
  };
};
