import { Fragment } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormField, FormSubmitButton } from "..";
import { FormConstructor } from "../../types/default";

interface Props<TType> {
  closeModal: () => void;
  btnLabel: string;
  formConfig: FormConstructor<TType>;
  btnGapLarge?: boolean;
  handleSubmitForm: (data: TType) => void;
}

const CONTER_OFFER = "counterOffer";
const REMOTE_RATE = "remoteRate";

function MakeAnOfferForm<TType extends FieldValues>({
  closeModal,
  btnLabel,
  formConfig,
  btnGapLarge,
  handleSubmitForm,
}: Props<TType>) {
  const methods = useForm<TType>({ mode: "onSubmit" });
  const { t } = useTranslation();

  const handleSubmitButton: SubmitHandler<TType> = data => {
    handleSubmitForm(data as TType);
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" noValidate onSubmit={methods.handleSubmit(handleSubmitButton)}>
        <Grid container columnSpacing={2} paddingBottom={2}>
          {formConfig.fields.map(field => (
            <Fragment key={field.label}>
              <Grid item xs={field.name === CONTER_OFFER ? 12 : 6}>
                <Box>
                  <FormField {...field} name={field.name as string} />
                </Box>
              </Grid>

              {field.name === REMOTE_RATE && (
                <Grid item xs={12}>
                  <Typography variant={"subtitle1"} color={"textSecondary"} marginTop={4}>
                    {t("userProfile:actions.makeAnOffer.formFields.offerDetails")}
                  </Typography>
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>

        <FormSubmitButton label={btnLabel} btnGapLarge={btnGapLarge} />
      </Box>
    </FormProvider>
  );
}

export default MakeAnOfferForm;
