import { FC } from "react";

import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TeamCard } from "..";
import { DEFAULT_ROWS_PER_PAGE, MASTER_USER_LIST } from "../../constants";
import { useRequirements } from "../../hooks/requirements/useRequirements";
import { useChangePage } from "../../hooks/useChangePage";
import Loading from "../../layouts/Loading";
import Pagination from "../../layouts/Pagination";
import { UserState, useUserStore } from "../../store/useUserStore";
import { CardButtonTypes, CardTypes, SortOrder } from "../../types/default";
import { UserRoles } from "../../types/users";
import EmptyState from "../EmptyState";

interface Props {
  cardType: CardTypes;
  cardButton: CardButtonTypes;
  hasRequirements?: boolean;
  hasOptions?: boolean;
  handleChangeOrder: (order?: SortOrder, requirementId?: string) => () => void;
}

const userRoleSelector = (state: UserState) => state.role;

const ConsultantLists: FC<Props> = ({ hasRequirements, hasOptions, cardType, cardButton, handleChangeOrder }) => {
  const { t } = useTranslation();
  const userRole = useUserStore(userRoleSelector);
  const { page, handleChangePage } = useChangePage();

  const { data: requirementsData, isLoading } = useRequirements(
    page,
    handleChangePage,
    DEFAULT_ROWS_PER_PAGE,
    userRole !== UserRoles.superadmin
  );

  if (isLoading || !requirementsData) {
    return <Loading />;
  }

  if (!requirementsData.totalElements) {
    return <EmptyState text={t("main:newRequirement.emptyState")} />;
  }

  return (
    <>
      <Grid item xs={12} marginY={3}>
        {hasRequirements && (
          <Typography variant={"subtitle1"} color={"textSecondary"}>
            {t("main:requirements")}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} container spacing={3}>
        {requirementsData.data.map(team => (
          <Grid item xs={4} key={team.id}>
            <TeamCard
              team={team}
              cardClick={handleChangeOrder(team.title === MASTER_USER_LIST ? undefined : SortOrder.DESC, team.id)}
              hasOptions={hasOptions}
              cardType={cardType}
              cardButton={cardButton}
            />
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Pagination page={page} totalElements={requirementsData.totalElements} handleChangePage={handleChangePage} />
      </Grid>
    </>
  );
};

export default ConsultantLists;
