import { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  declineButton: {
    border: `1px solid ${theme.palette.dark.light}`,
  },
  textColor: {
    color: theme.palette.dark.main,
  },
}));

interface Props {
  label: string;
  handleDeleteAction: () => void;
  cancelDeleteAction: () => void;
}

const DeletePopup: FC<Props> = ({ label, handleDeleteAction, cancelDeleteAction }) => {
  const { classes } = useStyles();

  return (
    <Box paddingTop={4} marginX={-3}>
      <Typography variant={"subtitle1"} color={"textSecondary"}>
        <Trans i18nKey={"deletePopup:text"}>{{ label: label.toLowerCase() }}</Trans>
      </Typography>

      <Box display={"flex"} justifyContent={"flex-end"} marginTop={8.5} gap={4}>
        <Button variant={"outlined"} className={classes.declineButton} onClick={cancelDeleteAction}>
          <Typography variant={"button"} className={classes.textColor}>
            <Trans i18nKey={"deletePopup:keep"}>{{ label }}</Trans>
          </Typography>
        </Button>

        <Button variant={"contained"} color={"primary"} onClick={handleDeleteAction}>
          <Typography variant={"button"}>
            <Trans i18nKey={"deletePopup:delete"}>{{ label }}</Trans>
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default DeletePopup;
