import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import sublistService from "../../services/sublists";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const snackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useSublists = () => {
  const addSnackbar = useSnackbar(snackbarSelector);
  const { data: sublistsData, isLoading } = useQuery(QUERY_STRINGS.GET_SUBLISTS, sublistService.getSublists, {
    onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
  });

  return { sublistsData, isLoading };
};
