import { initializeApp } from "firebase/app";

import config from "./lib/config";

const firebaseConfig = {
  apiKey: config.FIREBASE_KEY,
  projectId: config.FIREBASE_PROJECT_ID,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER,
  appId: config.FIREBASE_APP_ID,
};

export const app = initializeApp(firebaseConfig);
