import { FC } from "react";

import { useTranslation } from "react-i18next";

import { AuthContent, LoginForm } from "../components";

const LoginPage: FC = () => {
  const { t } = useTranslation();

  return (
    <AuthContent text={t("login:title")}>
      <LoginForm />
    </AuthContent>
  );
};

export default LoginPage;
