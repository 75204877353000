import { FC, useContext } from "react";

import { Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { EmptyState } from "..";
import { DATE_FORMAT, MASTER_USER_LIST } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import { useChangeRequirementStatus } from "../../hooks/requirements/useChangeRequirementStatus";
import { useRequirements } from "../../hooks/requirements/useRequirements";
import { useChangePage } from "../../hooks/useChangePage";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { UserState, useUserStore } from "../../store/useUserStore";
import { MixpanelEvents } from "../../types/mixpanel";
import { RequirementData, RequirementStatus } from "../../types/requirements";
import { UserRoles } from "../../types/users";

const userRoleSelector = (state: UserState) => state.role;

const JobOrderTable: FC = () => {
  const { t } = useTranslation();
  const { mixpanelTrack } = useContext(MixpanelContext);

  const userRole = useUserStore(userRoleSelector);

  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();

  const { data, isLoading } = useRequirements(page, handleChangePage, perPage, false);
  const { changeRequirementStatus, isLoading: isChangeStatusLoading } = useChangeRequirementStatus();

  const handleChangeStatus = (data: RequirementData) => () => {
    const { id, status, title } = data;

    if (title !== MASTER_USER_LIST) {
      changeRequirementStatus({
        requirementId: id,
        status: status === RequirementStatus.ACTIVE ? RequirementStatus.INACTIVE : RequirementStatus.ACTIVE,
      });
      status === RequirementStatus.INACTIVE && mixpanelTrack(MixpanelEvents.ACTIVATE_JOB_ORDER);
    }
  };

  const tableBuilder: Column<RequirementData> = {
    columns: [
      {
        dataKey: "title",
        label: t("jobOrderPage:tableFields.title"),
        dataRender: data => data.title,
        sortable: false,
      },
      {
        dataKey: "owner",
        label: t("jobOrderPage:tableFields.owner"),
        dataRender: data => data.managerName,
        sortable: false,
        private: true,
      },
      {
        dataKey: "status",
        label: t("jobOrderPage:tableFields.status"),
        dataRender: data => (
          <Chip
            variant={"filled"}
            color={data.status === RequirementStatus.ACTIVE ? "primary" : "secondary"}
            label={
              <Typography variant={"subtitle2"} color={"unset"} fontWeight={700} textTransform={"uppercase"}>
                {data.status}
              </Typography>
            }
            onClick={handleChangeStatus(data)}
            size={"medium"}
          />
        ),
        sortable: false,
        centered: true,
      },
      {
        dataKey: "availability",
        label: t("jobOrderPage:tableFields.availability"),
        dataRender: data => dayjs(data.commitmentTimestamp).format(DATE_FORMAT),
        sortable: false,
        centered: true,
      },
      {
        dataKey: "numberOfPeople",
        label: t("jobOrderPage:tableFields.numberPeople"),
        dataRender: data => data.numberOfConsultants,
        sortable: false,
        centered: true,
      },
    ],
  };

  const managerTableColumns = tableBuilder.columns.filter(column => userRole === UserRoles.manager && !column.private);

  if (isLoading || !data || isChangeStatusLoading) {
    return <Loading />;
  }

  if (!data.totalElements) {
    return <EmptyState text={t("jobOrderPage:jobsEmptyState")} />;
  }

  return (
    <CustomTable
      tableBuilder={{
        ...tableBuilder,
        columns: userRole === UserRoles.manager ? managerTableColumns : tableBuilder.columns,
      }}
      data={data?.data}
      page={page}
      perPage={perPage}
      totalElements={data?.totalElements}
      showPagination
      showRowsPerPage
      handleChangePage={handleChangePage}
      handleChangePerPage={handleChangePerPage}
    />
  );
};

export default JobOrderTable;
