import { FC } from "react";

import { Box, Typography } from "@mui/material";

interface Props {
  text: string;
}

const AuthContent: FC<Props> = ({ text, children }) => {
  return (
    <Box>
      <Typography variant="h1" align={"left"} color={"textPrimary"} marginBottom={5.5}>
        {text}
      </Typography>
      {children}
    </Box>
  );
};

export default AuthContent;
