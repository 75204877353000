import { addMonthsToCurrentDate, parseFalseValue } from "../functions";
import kommlinkApi, { BackendError } from "../lib/axios";
import { ConsultantsSortValue, RateConsultant, RateConsultantResponse } from "../types/consultants";
import { PaginatedData } from "../types/default";
import { FilterOptions } from "../types/main";
import { MakeAnOfferBody } from "../types/rightSidebar";
import { PaginatedSublist } from "../types/sublist";
import { ConsultantsData, ConsultantsDataWithTitle, RegisterConsultantTypes } from "../types/users";
import filesService from "./files";

const rateConsultant = async ({ id, rating }: RateConsultant): Promise<RateConsultantResponse> => {
  try {
    const res = await kommlinkApi.post<RateConsultantResponse>(`/${id}/rating`, { rating });

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editConsultant = async (data: RegisterConsultantTypes): Promise<void> => {
  try {
    const { id, consultantCv } = data;
    if (consultantCv && typeof consultantCv !== "string") {
      await filesService.uploadConsultantCv(consultantCv, id);
    }

    await kommlinkApi.put<RegisterConsultantTypes>(`/superadmin/${id}`, data);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteConsultant = async (id: string) => {
  try {
    await kommlinkApi.delete<void>(`/superadmin/consultants/${id}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getConsultants = async (
  page?: number,
  perPage?: number,
  filterOptions?: FilterOptions
): Promise<PaginatedData<ConsultantsData>> => {
  try {
    const res = await kommlinkApi.get<PaginatedData<ConsultantsDataWithTitle>>("/users/me/consultants", {
      params: {
        page,
        perPage,
        ratingOrder: filterOptions?.order?.toUpperCase(),
        isRatingFiltered: filterOptions?.sortBy === ConsultantsSortValue.STARS || undefined,
        isRecentlyAdded: filterOptions?.recentlyAdded,
        skillId: filterOptions?.skill,
        jobRequirementsId: filterOptions?.projectOrder,
        availabilityDate: filterOptions?.availability
          ? addMonthsToCurrentDate(Number(filterOptions?.availability))
          : undefined,
        isInterviewed: parseFalseValue(filterOptions?.interviewed),
        hasAnsweredQuestionnaire: parseFalseValue(filterOptions?.questionaireAnswered),
      },
    });

    const updatedRequirementProps = res.data.data.map(nested => {
      const mappedRequirementProps = nested.jobRequirementsDetails.map(element => ({
        id: element.id,
        name: element.title,
      }));

      return { ...nested, jobRequirementsDetails: mappedRequirementProps };
    });

    return { ...res.data, data: updatedRequirementProps };
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getConsultantsBySublist = async (
  sublistId: string,
  page?: number,
  perPage?: number
): Promise<PaginatedSublist> => {
  try {
    const res = await kommlinkApi.get<PaginatedSublist>(`/sublists/${sublistId}`, {
      params: {
        page,
        perPage,
      },
    });

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getConsultantById = async (consultantId: string): Promise<ConsultantsData> => {
  try {
    const res = await kommlinkApi.get<ConsultantsData>(`/users/me/consultants/${consultantId}`);

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const makeAnOffer = async (data: MakeAnOfferBody) => {
  try {
    await kommlinkApi.post<MakeAnOfferBody>("/mails/offers", data);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const sendQuestionaire = async (consultantId: string) => {
  try {
    await kommlinkApi.post<void>(`/manager/mails/questions/${consultantId}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const shareConsultant = async ({ consultantId, managerId }: { consultantId: string; managerId: string }) => {
  try {
    await kommlinkApi.post<void>(`/managers/${managerId}/consultants/${consultantId}/share`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const consultantsService = {
  rateConsultant,
  editConsultant,
  deleteConsultant,
  getConsultants,
  getConsultantById,
  getConsultantsBySublist,
  makeAnOffer,
  sendQuestionaire,
  shareConsultant,
};

export default consultantsService;
