import { ReactElement } from "react";

import { Dayjs } from "dayjs";
import { ResourceKey } from "i18next";

import { BaseEntity } from "./default";

export interface ConsultantInfoTypes {
  title: ResourceKey;
  info: string;
  isPrivate?: boolean;
}

export interface BaseUserProfileActions {
  label: ResourceKey;
  icon: ReactElement;
  action: () => void;
}

export interface UserHistoryFields extends Omit<BaseUserProfileActions, "action"> {
  action?: () => void;
  actionText: string;
  separated?: boolean;
}

export interface DropdownListConfig extends BaseEntity {
  name: string;
  hasDivider?: boolean;
  className?: string;
  typographyClassName?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  add?: () => void;
  remove?: () => void;
}

export interface MakeAnOfferFormTypes {
  onsiteRate: number;
  remoteRate: number;
  startDate: Dayjs;
  initialDuration: number;
  onsiteHoursPerWeek: number;
  remoteHoursPerWeek: number;
}

export interface ShareConsultant {
  manager: string;
}

export interface MakeAnOfferBody extends Omit<MakeAnOfferFormTypes, "startDate"> {
  consultantId: string;
  startDate: number;
}

export enum SidebarActionsModalType {
  MAKE_AN_OFFER = "makeAnOffer",
  SCHEDULE_INTERVIEW = "scheduleInterview",
  SEND_QUESTIONS = "sendQuestions",
  SHARE_CONSULTANT = "shareConsultant",
}
