import { FC } from "react";

import { Box } from "@mui/material";
import { t } from "i18next";

import { AvatarCell, DeletePopup, EmptyState, ModalContainer, TableActions } from "..";
import { useChangePage } from "../../hooks/useChangePage";
import { useDeleteItem } from "../../hooks/useDeleteItem";
import { useDeleteUser } from "../../hooks/users/useDeleteUser";
import { useManagers } from "../../hooks/users/useManagers";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { UserBaseTypes, UserRoles } from "../../types/users";

interface Props {
  handleChangeMode: (data: UserBaseTypes) => () => void;
}

const ManagersTable: FC<Props> = ({ handleChangeMode }) => {
  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();
  const { isLoading, usersData } = useManagers(page, perPage, handleChangePage);

  const { deleteUser, isLoading: isDeleteLoading } = useDeleteUser(UserRoles.manager);
  const { itemToDelete, handleCloseModal, handleDeleteIconClick, handleDeleteItem } = useDeleteItem(deleteUser);

  const tableBuilder: Column<UserBaseTypes> = {
    columns: [
      {
        dataKey: "name",
        label: t("users:table.name"),
        dataRender: data => <AvatarCell firstName={data.firstName} lastName={data.lastName} />,
        sortable: false,
      },
      {
        dataKey: "email",
        label: t("users:table.email"),
        dataRender: data => data.email,
        sortable: true,
      },
      {
        dataKey: "actions",
        label: undefined,
        dataRender: data => (
          <TableActions deleteClick={handleDeleteIconClick(data.id)} editClick={handleChangeMode(data)} />
        ),
        sortable: false,
      },
    ],
  };

  if (isLoading || !usersData || isDeleteLoading) {
    return <Loading />;
  }

  if (!usersData.totalElements) {
    return <EmptyState text={t("emptyState:texts.managers")} />;
  }

  return (
    <Box>
      <CustomTable
        tableBuilder={tableBuilder}
        data={usersData.data}
        page={page}
        perPage={perPage}
        totalElements={usersData.totalElements}
        showPagination
        showRowsPerPage
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
      />
      <ModalContainer
        isOpened={!!itemToDelete}
        title={t("deletePopup:title", { label: t("managers:manager") })}
        closeModal={handleCloseModal}
        size={"md"}
      >
        <DeletePopup
          label={t("managers:manager")}
          handleDeleteAction={handleDeleteItem}
          cancelDeleteAction={handleCloseModal}
        />
      </ModalContainer>
    </Box>
  );
};

export default ManagersTable;
