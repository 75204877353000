import { TEXT_REGEX } from "../../../constants";
import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { SublistBuilder } from "../../../types/sublist";

export const addSublistConfig: FormConstructor<SublistBuilder> = {
  fields: [
    {
      name: "name",
      label: "sublists:sublistName",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_REGEX,
          label: "sublists:errors.invalidSublistName",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "consultants",
      label: "main:addConsultantsToRequirement.consultants",
      type: FieldType.selectMultiple,
      defaultValue: [],
      options: [],
    },
  ],
};
