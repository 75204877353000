import { useContext } from "react";

import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import skillsService from "../../services/skills";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";
import { MixpanelEvents } from "../../types/mixpanel";

interface ReturnType {
  createSkill: UseMutateFunction<void, ErrorContent, string, unknown>;
  isLoading: boolean;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useCreateSkill = (): ReturnType => {
  const { mixpanelTrack } = useContext(MixpanelContext);
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { isLoading, mutate: createSkill } = useMutation(skillsService.createSkill, {
    mutationKey: QUERY_STRINGS.CREATE_SKILL,
    onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
    onSuccess: () => {
      mixpanelTrack(MixpanelEvents.CREATE_SKILL);
      queryClient.invalidateQueries(QUERY_STRINGS.GET_SKILLS);
    },
  });

  return { createSkill, isLoading };
};
