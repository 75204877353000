import { FC, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, ModalContainer, ScheduleInterviewForm, UpcomingInterviewsTable } from "../components";
import { useEditInterview } from "../hooks/interviews/useEditInterview";
import { useInterviewFormBuilder } from "../hooks/interviews/useInterviewFormBuilder";
import Loading from "../layouts/Loading";
import { EditInterviewTypes, ScheduleInterviewResponse } from "../types/interviews";

const UpcomingInterviews: FC = () => {
  const { t } = useTranslation();
  const { editInterview, isLoading: isEditInterviewLoading } = useEditInterview();
  const { editInterviewFormBuilderFields, handleAddCurrentInterview, currentInterview } = useInterviewFormBuilder();

  const [editMode, setEditMode] = useState(false);

  const handleEditForm = (data: EditInterviewTypes<Dayjs>) => {
    const interviewDate = dayjs(data.interviewDate).valueOf();
    const timeFrom = dayjs(data.timeFrom).valueOf();
    const timeTo = dayjs(data.timeTo).valueOf();

    editInterview({
      interviewId: currentInterview!.id,
      data: { ...data, interviewDate, timeFrom, timeTo },
    });
  };

  const handleChangeMode = (data: ScheduleInterviewResponse) => () => {
    setEditMode(true);
    handleAddCurrentInterview(data);
  };

  const handleCloseEditMode = () => setEditMode(false);

  if (isEditInterviewLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("upcomingInterviews:description")} />
        <title>
          {t("upcomingInterviews:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <CardContentSection
        title={t("upcomingInterviews:title")}
        table={<UpcomingInterviewsTable handleChangeMode={handleChangeMode} />}
      />

      <ModalContainer
        isOpened={editMode}
        title={t("upcomingInterviews:title")}
        closeModal={handleCloseEditMode}
        size={"xs"}
      >
        <ScheduleInterviewForm
          closeModal={handleCloseEditMode}
          btnLabel={t("userProfile:actions.scheduleAnInterview.submitBtn")}
          formConfig={{ ...editInterviewFormBuilderFields, fields: editInterviewFormBuilderFields }}
          handleSubmitForm={handleEditForm}
        />
      </ModalContainer>
    </>
  );
};

export default UpcomingInterviews;
