import { useContext } from "react";

import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import requirementsService from "../../services/requirements";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { ErrorContent } from "../../types/default";
import { MixpanelEvents } from "../../types/mixpanel";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;
const currentUserSelector = (state: UserState) => state;

export const useCreateJobRequirement = () => {
  const { mixpanelTrack } = useContext(MixpanelContext);
  const addSnackBar = useSnackbar(addSnackbarSelector);
  const currentUser = useUserStore(currentUserSelector);

  const queryClient = useQueryClient();

  const { mutate: createJobRequirement, isLoading } = useMutation(requirementsService.createSuperadminJobRequirement, {
    mutationKey: QUERY_STRINGS.CREATE_REQUIREMENT,
    onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
    onSuccess: () => {
      mixpanelTrack(MixpanelEvents.CREATE_REQUIREMENT, {
        user: `${currentUser.firstName} ${currentUser.lastName}`,
        email: currentUser.email,
        role: currentUser.role,
      });

      queryClient.invalidateQueries(QUERY_STRINGS.GET_REQUIREMENTS);
    },
  });

  return { createJobRequirement, isLoading };
};
