import { useQuery, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import usersService from "../../services/users";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent, PaginatedData } from "../../types/default";
import { UserBaseTypes } from "../../types/users";

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useManagers = (page?: number, perPage?: number, handleChangePage?: (newPage: number) => void) => {
  const addSnackBar = useSnackbar(addSnackBarSelector);
  const queryClient = useQueryClient();

  const { isLoading, data: usersData } = useQuery(
    [QUERY_STRINGS.GET_MANAGERS, page, perPage],
    () => usersService.getManagers(page, perPage),
    {
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
      onSuccess: (data: PaginatedData<UserBaseTypes>) => {
        queryClient.setQueryData(QUERY_STRINGS.GET_MANAGERS, data.data);
        !data.data.length && data.totalElements && page && handleChangePage && handleChangePage(page - 1);
      },
    }
  );

  return { isLoading, usersData };
};
