import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translations/en/translation.json";

export const resources = {
  en,
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV === "development",
});

i18n.changeLanguage("en");
