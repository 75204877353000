import { useContext } from "react";

import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import sublistService from "../../services/sublists";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";
import { MixpanelEvents } from "../../types/mixpanel";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useRemoveConsultantFromSublist = () => {
  const { mixpanelTrack } = useContext(MixpanelContext);
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const {
    data,
    mutate: removeConsultantFromSublist,
    isLoading,
  } = useMutation(sublistService.removeConsultantFromSublist, {
    mutationKey: QUERY_STRINGS.REMOVE_CONSULTANT_FROM_SUBMIST,
    onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
    onSuccess: () => {
      mixpanelTrack(MixpanelEvents.USER_REMOVED_FROM_SUBLIST);
      queryClient.invalidateQueries([QUERY_STRINGS.GET_CONSULTANTS_BY_SUBLIST]);
      queryClient.invalidateQueries([QUERY_STRINGS.GET_SUBLISTS]);
      queryClient.invalidateQueries([QUERY_STRINGS.GET_CONSULTANT_BY_ID]);
    },
  });

  return { removeConsultantFromSublist, isLoading, data };
};
