export default {
  API_URL: process.env.REACT_APP_API_URL,
  FIREBASE_KEY: process.env.REACT_APP_FIREBASE_KEY,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  STREAM_KEY: process.env.REACT_APP_STREAM_KEY,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_MESSAGING_SENDER: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  MIXPANEL_KEY: process.env.REACT_APP_MIXPANEL_KEY,
};
