import { FC } from "react";

import AdapterDayjs from "@date-io/dayjs";
import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker as MUITimePicker } from "@mui/x-date-pickers";
import { ResourceKey, t } from "i18next";

import { BaseFormFieldProps } from "../../types/default";

const TimePicker: FC<BaseFormFieldProps> = ({ label, formFieldProps }) => {
  const { ...rest } = formFieldProps.field;
  const { error } = formFieldProps.fieldState;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUITimePicker
        {...rest}
        renderInput={props => (
          <TextField
            {...props}
            fullWidth
            helperText={error && error.message}
            error={Boolean(error && error.message)}
            margin="normal"
          />
        )}
        ampm
        label={t(label as ResourceKey)}
      />
    </LocalizationProvider>
  );
};

export default TimePicker;
