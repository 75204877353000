import { FC, useContext } from "react";

import { Box, Button, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { AuthContext } from "../../contexts/AuthContext";
import { EmailType } from "../../types/authentication";
import FormField from "../FormFields/FormField";
import { forgottenPasswordConfig } from "../forms/builders/forgottenPasswordBuilder";

const useStyles = makeStyles()(theme => ({
  form: {
    width: "328px",
  },
  loginButton: {
    marginTop: theme.spacing(4.25),
    marginBottom: theme.spacing(2),
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
const ForgottenPasswordForm: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const methods = useForm<EmailType>({ mode: "onChange" });

  const { resetPassword } = useContext(AuthContext);

  const handleButtonSubmit = (inputs: EmailType) => resetPassword(inputs.email);

  return (
    <FormProvider {...methods}>
      <Box component="form" noValidate className={classes.form} onSubmit={methods.handleSubmit(handleButtonSubmit)}>
        {forgottenPasswordConfig.fields.map(field => (
          <Box key={field.name} className={classes.checkboxContainer}>
            <FormField {...field} />
          </Box>
        ))}

        <Button type="submit" fullWidth variant="contained" color={"primary"} className={classes.loginButton}>
          <Typography variant={"button"}>{t("forgottenPassword:sendBtn")}</Typography>
        </Button>
      </Box>
    </FormProvider>
  );
};

export default ForgottenPasswordForm;
