import { FC } from "react";

import { useTranslation } from "react-i18next";

import { AuthContent, ForgottenPasswordForm } from "../components";

const ForgottenPassword: FC = () => {
  const { t } = useTranslation();

  return (
    <AuthContent text={t("forgottenPassword:title")}>
      <ForgottenPasswordForm />
    </AuthContent>
  );
};

export default ForgottenPassword;
