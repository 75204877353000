import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import requirementsService from "../../services/requirements";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";
import { UpdateRequirementStatus } from "../../types/requirements";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

interface ReturnType {
  isLoading: boolean;
  changeRequirementStatus: UseMutateFunction<void, ErrorContent, UpdateRequirementStatus, unknown>;
}

export const useChangeRequirementStatus = (): ReturnType => {
  const addSnackBar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { isLoading, mutate: changeRequirementStatus } = useMutation(requirementsService.changeRequirementStatus, {
    mutationKey: QUERY_STRINGS.CHANGE_REQUIREMENT_STATUS,
    onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_REQUIREMENTS),
  });

  return { isLoading, changeRequirementStatus };
};
