import { FC } from "react";

import { Alert, Snackbar as SnackbarMUI } from "@mui/material";

import { SnackbarState, useSnackbar } from "../store/useSnackbar";

const snackbarSelector = (state: SnackbarState) => state.data;
const clearSnackbarSelector = (state: SnackbarState) => state.clearSnackbar;

const Snackbar: FC = () => {
  const snackbar = useSnackbar(snackbarSelector);
  const clearSnackbar = useSnackbar(clearSnackbarSelector);

  const handleClose = () => clearSnackbar();

  return (
    <SnackbarMUI
      open={Boolean(snackbar.text)}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {snackbar.text ? (
        <Alert onClose={handleClose} severity={snackbar.type} variant={"filled"}>
          {snackbar.text}
        </Alert>
      ) : undefined}
    </SnackbarMUI>
  );
};

export default Snackbar;
