import { EMAIL_REGEX, TEXT_REGEX } from "../../../constants";
import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { UserBaseTypes } from "../../../types/users";

export const addSuperadminBuilder: FormConstructor<UserBaseTypes> = {
  fields: [
    {
      name: "firstName",
      label: "users:form.firstName",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_REGEX,
          label: "users:errors.invalid",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "lastName",
      label: "users:form.lastName",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_REGEX,
          label: "users:errors.invalid",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "email",
      label: "users:form.email",
      validationOptions: {
        required: true,
        pattern: {
          pattern: EMAIL_REGEX,
          label: "users:errors.invalidEmail",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
  ],
};
