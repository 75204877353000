import { UserWithRole } from "../types/users";
import { createStore } from "./storeUtils";

export interface UserState extends UserWithRole {
  updateUserInfo: (info: UserWithRole) => void;
  resetUserInfo: () => void;
  login: () => void;
  isLoggedIn: boolean;
}

const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  role: null,
  email: "",
  isLoggedIn: false,
  getStreamId: "",
};

export const useUserStore = createStore<UserState>("user", set => ({
  ...initialState,
  updateUserInfo: userInfo =>
    set(state => ({
      ...state,
      ...userInfo,
    })),
  resetUserInfo: () => set(() => initialState),
  login: () => set(state => ({ ...state, isLoggedIn: true })),
}));
