import { FC } from "react";

import { ListOutlined } from "@mui/icons-material";
import { CircularProgress, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DropdownMenuItem } from "..";
import { FLAGGED_ID } from "../../constants";
import { useFlagCv } from "../../hooks/flaggedCvs/useFlagCv";
import { useUnflagCv } from "../../hooks/flaggedCvs/useUnflagCv";
import { useAddConsultantToSublist } from "../../hooks/sublists/useAddConsultantToSublist";
import { useRemoveConsultantFromSublist } from "../../hooks/sublists/useRemoveConsultantFromSublist";
import { useSublists } from "../../hooks/sublists/useSublists";
import { DropdownListConfig } from "../../types/rightSidebar";
import { ConsultantsData } from "../../types/users";

interface Props {
  anchorEl: HTMLElement | null;
  close: () => void;
  consultantData: ConsultantsData;
}

const DROPDOWN_FIRST_ELEMENT_ID = "initial";

const DropdownMenu: FC<Props> = ({ anchorEl, close, consultantData }) => {
  const { t } = useTranslation();

  const { sublistsData, isLoading } = useSublists();
  const { addConsultantToSublist, isLoading: isAddConsultantLoading } = useAddConsultantToSublist();
  const { removeConsultantFromSublist, isLoading: isRemoveConsultantLoading } = useRemoveConsultantFromSublist();
  const { flagConsultantCv, isLoading: isFlagCvsLoading } = useFlagCv();
  const { unflagConsultantCv, isLoading: isUnflagCvsLoading } = useUnflagCv();

  const handleAddToSublist = (sublistId: string) => {
    addConsultantToSublist({ consultantId: consultantData.id, sublistId });
    close();
  };

  const handleRemoveFromSublist = (sublistId: string) => {
    removeConsultantFromSublist({ consultantId: consultantData.id, sublistId });
    close();
  };

  const handleFlagCv = () => {
    flagConsultantCv(consultantData.id);
    close();
  };

  const handleUnflagCv = () => {
    unflagConsultantCv(consultantData.id);
    close();
  };

  if (
    isLoading ||
    !sublistsData ||
    isAddConsultantLoading ||
    isRemoveConsultantLoading ||
    isFlagCvsLoading ||
    isUnflagCvsLoading
  ) {
    return <CircularProgress />;
  }
  const sublists: DropdownListConfig[] = sublistsData.map(el => ({
    id: el.id,
    name: el.name,
    add: () => handleAddToSublist(el.id),
    remove: () => handleRemoveFromSublist(el.id),
  }));

  const dropDownConfig: DropdownListConfig[] = [
    {
      id: DROPDOWN_FIRST_ELEMENT_ID,
      name: t("userProfile:dropdownLabels.actions"),
      hasDivider: true,
      endIcon: <ListOutlined />,
    },
    {
      id: FLAGGED_ID,
      name: t("userProfile:dropdownLabels.flagCv"),
      add: handleFlagCv,
      remove: handleUnflagCv,
    },
    ...sublists,
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={close}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {dropDownConfig.map(item => (
        <DropdownMenuItem item={item} key={item.id} consultantData={consultantData} />
      ))}
    </Menu>
  );
};

export default DropdownMenu;
