import { FC } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DeletePopup, EmptyState, ModalContainer, TableActions } from "..";
import { useDeleteSkill } from "../../hooks/skills/useDeleteSkill";
import { useSkills } from "../../hooks/skills/useSkills";
import { useChangePage } from "../../hooks/useChangePage";
import { useDeleteItem } from "../../hooks/useDeleteItem";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { SelectOptionsType } from "../../types/default";

interface Props {
  handleChangeMode: (data: SelectOptionsType) => () => void;
}

const SkillsTable: FC<Props> = ({ handleChangeMode }) => {
  const { t } = useTranslation();

  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();
  const { isLoading: isDeletingSkill, deleteSkill } = useDeleteSkill();
  const { itemToDelete, handleCloseModal, handleDeleteIconClick, handleDeleteItem } = useDeleteItem(deleteSkill);
  const { isLoading, skillsData } = useSkills(page, perPage, handleChangePage);

  const tableBuilder: Column<SelectOptionsType> = {
    columns: [
      {
        dataKey: "name",
        label: t("companies:table.name"),
        dataRender: data => data.name,
        sortable: false,
      },

      {
        dataKey: "actions",
        dataRender: data => (
          <TableActions deleteClick={handleDeleteIconClick(data.id)} editClick={handleChangeMode(data)} />
        ),
        sortable: false,
      },
    ],
  };

  if (isLoading || isDeletingSkill || !skillsData) {
    return <Loading />;
  }

  if (skillsData && !skillsData.data.length) {
    return <EmptyState text={t("emptyState:texts.skills")} />;
  }

  return (
    <Box>
      <CustomTable
        tableBuilder={tableBuilder}
        data={skillsData.data}
        page={page}
        perPage={perPage}
        totalElements={skillsData.totalElements}
        showPagination
        showRowsPerPage
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
      />
      <ModalContainer
        isOpened={!!itemToDelete}
        title={t("deletePopup:title", { label: t("skills:skill") })}
        closeModal={handleCloseModal}
        size={"md"}
      >
        <DeletePopup
          label={t("skills:skill")}
          handleDeleteAction={handleDeleteItem}
          cancelDeleteAction={handleCloseModal}
        />
      </ModalContainer>
    </Box>
  );
};

export default SkillsTable;
