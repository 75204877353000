import kommlinkApi, { BackendError } from "../lib/axios";
import { PaginatedData } from "../types/default";
import { UpcomingCVsData } from "../types/upcomingCVs";

const getUpcomingCVs = async (page: number, perPage: number): Promise<PaginatedData<UpcomingCVsData>> => {
  try {
    const { data } = await kommlinkApi.get<PaginatedData<UpcomingCVsData>>("/superadmin/upcoming-cvs", {
      params: {
        page,
        perPage,
      },
    });

    return data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const sendUpcomingCV = async (file: FormData): Promise<void> => {
  try {
    await kommlinkApi.post("/manager/upcoming-cvs", file);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteUpcomingCV = async (id: string): Promise<void> => {
  try {
    await kommlinkApi.delete(`/superadmin/upcoming-cvs/${id}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const upcomingCVsService = {
  getUpcomingCVs,
  sendUpcomingCV,
  deleteUpcomingCV,
};

export default upcomingCVsService;
