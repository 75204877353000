import { FirebaseError } from "firebase/app";
import { Auth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User } from "firebase/auth";

import { getFirebaseMessage } from "../functions";
import kommlinkApi, { BackendError } from "../lib/axios";
import { LoginTypesWithAuth, ResetPasswordTypes } from "../types/authentication";
import { UserReturnType } from "../types/users";

const firebaseSignIn = async ({ data, auth }: LoginTypesWithAuth): Promise<void> => {
  try {
    const firebaseResponse = await signInWithEmailAndPassword(auth, data.email, data.password);
    const accessToken = await firebaseResponse.user.getIdToken();

    kommlinkApi.defaults.headers.common["Authorization"] = accessToken;
  } catch (err: unknown) {
    const error = err as FirebaseError;

    throw new Error(getFirebaseMessage(error.code));
  }
};

const refreshToken = async (user: User): Promise<string> => {
  try {
    const token = await user.getIdToken();

    kommlinkApi.defaults.headers.common["Authorization"] = token;

    return token;
  } catch (err: unknown) {
    const error = err as FirebaseError;

    throw new Error(getFirebaseMessage(error.code));
  }
};

const firebaseLogout = async (auth: Auth): Promise<void> => {
  try {
    await signOut(auth);

    delete kommlinkApi.defaults.headers.common["Authorization"];
  } catch (err: unknown) {
    const error = err as FirebaseError;

    throw new Error(getFirebaseMessage(error.code));
  }
};

const firebaseForgotPassword = async ({ email, auth }: ResetPasswordTypes): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err: unknown) {
    const error = err as FirebaseError;

    throw new Error(getFirebaseMessage(error.code));
  }
};

const getUserInfo = async (): Promise<UserReturnType> => {
  try {
    const response = await kommlinkApi.get<UserReturnType>("/users/me");

    return response.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const authenticationService = {
  firebaseSignIn,
  refreshToken,
  firebaseLogout,
  firebaseForgotPassword,
  getUserInfo,
};

export default authenticationService;
