import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";

import { DeletePopup } from ".";
import { ActionTypes, FormConstructor } from "../types/default";
import FormConsumer from "./forms/FormConsumer";
import ModalContainer from "./ModalContainer";

interface Props<T> {
  modalType: ActionTypes | null;
  formConfig: FormConstructor<T>;
  handleSubmitForm: (data: T) => void;
  handleCloseModal: () => void;
  handleDeleteSublist: () => void;
}

function SublistModal<T>({ formConfig, handleSubmitForm, modalType, handleCloseModal, handleDeleteSublist }: Props<T>) {
  const { t } = useTranslation();

  return (
    <ModalContainer
      isOpened={!!modalType}
      title={modalType && t(`sublists:${modalType.toLowerCase()}` as ResourceKey)}
      closeModal={handleCloseModal}
      size={"md"}
    >
      {modalType !== ActionTypes.DELETE ? (
        <FormConsumer<T>
          closeModal={handleCloseModal}
          btnLabel={modalType === ActionTypes.EDIT ? t("formFields:buttons.done") : t("sublists:addNewBtn")}
          formConfig={formConfig}
          handleSubmitForm={handleSubmitForm}
        />
      ) : (
        <DeletePopup label={"Sublist"} cancelDeleteAction={handleCloseModal} handleDeleteAction={handleDeleteSublist} />
      )}
    </ModalContainer>
  );
}

export default SublistModal;
