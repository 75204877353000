import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FileUploadButton, ImageDropzone } from "react-file-utils";
import { useTranslation } from "react-i18next";
import { ChatAutoComplete, SendButton, useMessageInputContext, UploadsPreview } from "stream-chat-react";

import "./CustomMessageInput.scss";

const CustomMessageInput = () => {
  const { handleSubmit, uploadNewFiles } = useMessageInputContext();
  const { t } = useTranslation();

  return (
    <Box>
      <Box marginX={3}>
        <UploadsPreview />
      </Box>
      <ImageDropzone>
        <Box>
          <ChatAutoComplete placeholder={t("messages:typeMessagePlaceholder")} />
          <FileUploadButton handleFiles={uploadNewFiles}>
            <Add />
          </FileUploadButton>
          <SendButton sendMessage={handleSubmit} />
        </Box>
      </ImageDropzone>
    </Box>
  );
};

export default CustomMessageInput;
