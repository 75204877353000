import { FC } from "react";

import { TablePagination } from "@mui/material";

import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, OVERVIEW_DEFAULT_PER_PAGE } from "../constants";

interface Props {
  page?: number;
  totalElements?: number;
  handleChangePage: (page: number) => void;
  perPage?: number;
  showRowsPerPage?: boolean;
  handleChangePerPage?: (perPage: number) => void;
}

const Pagination: FC<Props> = ({
  page,
  perPage,
  totalElements,
  showRowsPerPage,
  handleChangePage,
  handleChangePerPage,
}) => {
  const onChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) =>
    handleChangePage(page);

  const onChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleChangePerPage && handleChangePerPage(parseInt(event.target.value, 10));

  return (
    <TablePagination
      component="div"
      count={totalElements ?? OVERVIEW_DEFAULT_PER_PAGE}
      page={page ?? DEFAULT_PAGE}
      onPageChange={onChangePage}
      rowsPerPage={perPage ?? DEFAULT_ROWS_PER_PAGE}
      rowsPerPageOptions={showRowsPerPage ? [5, 10, 25, 50] : []}
      onRowsPerPageChange={onChangePerPage}
    />
  );
};

export default Pagination;
