import { FC, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, InputAdornment, TextField as MUITextField } from "@mui/material";
import { ResourceKey } from "i18next";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BaseFormFieldProps } from "../../types/default";
import { FieldType } from "../../types/login";

interface Props extends BaseFormFieldProps {
  type: string;
  multiline?: boolean;
  disabledField?: boolean;
  hasEndAdornment?: boolean;
  endAdornment?: string;
  isFreeText?: boolean;
}

const TextField: FC<Props> = ({
  type,
  label,
  multiline,
  formFieldProps,
  disabledField,
  hasEndAdornment,
  endAdornment,
  isFreeText,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { setValue } = useFormContext();
  const toggleShowPassword = () => setShowPassword(prev => !prev);
  const passwordFieldType = showPassword ? FieldType.text : FieldType.password;

  const { value, name, ...rest } = formFieldProps.field;
  const { error } = formFieldProps.fieldState;

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isFreeText) {
      return;
    }

    if (event.key === "Enter") {
      event.preventDefault();

      setValue(name, value + "\n");
    }
  };

  return (
    <MUITextField
      {...rest}
      error={Boolean(error && error.message)}
      margin="normal"
      multiline={multiline}
      minRows={multiline ? 2 : 1}
      disabled={disabledField}
      fullWidth
      id={name}
      helperText={error && error.message}
      type={type === FieldType.password ? passwordFieldType : FieldType.text}
      InputProps={
        hasEndAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  {type === FieldType.password ? (
                    <Box onClick={toggleShowPassword} display={"flex"}>
                      {showPassword ? (
                        <VisibilityOff color={"primary"} cursor={"pointer"} />
                      ) : (
                        <Visibility color={"primary"} cursor={"pointer"} />
                      )}
                    </Box>
                  ) : (
                    t(endAdornment as ResourceKey)
                  )}
                </InputAdornment>
              ),
            }
          : undefined
      }
      label={t(label as ResourceKey)}
      name={name}
      value={value}
      autoComplete={name}
      onKeyDown={isFreeText ? onKeyDown : undefined}
    />
  );
};
export default TextField;
