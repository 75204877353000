import { Box, Grid } from "@mui/material";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { FormField, FormSubmitButton } from "..";
import { FormConstructor } from "../../types/default";

interface Props<TType> {
  closeModal: () => void;
  btnLabel: string;
  formConfig: FormConstructor<TType>;
  btnGapLarge?: boolean;
  handleSubmitForm: (data: TType) => void;
}

const TIME_FROM = "timeFrom";
const TIME_TO = "timeTo";

function ScheduleInterviewForm<TType extends FieldValues>({
  closeModal,
  btnLabel,
  formConfig,
  btnGapLarge,
  handleSubmitForm,
}: Props<TType>) {
  const methods = useForm<TType>({ mode: "onSubmit" });

  const handleSubmitButton: SubmitHandler<TType> = data => {
    handleSubmitForm(data as TType);
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" noValidate onSubmit={methods.handleSubmit(handleSubmitButton)} paddingBottom={2}>
        <Grid container columnSpacing={2} paddingBottom={2}>
          {formConfig.fields.map(field => (
            <Grid item key={field.label} xs={field.name === TIME_FROM || field.name === TIME_TO ? 6 : 12}>
              <FormField {...field} name={field.name as string} />
            </Grid>
          ))}
        </Grid>

        <FormSubmitButton label={btnLabel} btnGapLarge={btnGapLarge} />
      </Box>
    </FormProvider>
  );
}

export default ScheduleInterviewForm;
