import { FC } from "react";

import { Box, FormControlLabel, Radio, RadioGroup as MUIRadioGroup, Typography } from "@mui/material";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";

import { BaseFormFieldProps, SelectOptionsType } from "../../types/default";

interface Props extends BaseFormFieldProps {
  options: SelectOptionsType[];
}

const RadioGroup: FC<Props> = ({ formFieldProps, options, label }) => {
  const { t } = useTranslation();
  const { value, name, ...rest } = formFieldProps.field;

  return (
    <Box marginTop={2} marginBottom={1}>
      <Typography marginBottom={2.5} variant="subtitle1" color="textSecondary">
        {t(label as ResourceKey)}
      </Typography>
      <MUIRadioGroup {...rest} name={name} value={value}>
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.name}
            control={<Radio />}
            label={<Typography variant="subtitle1">{option.name}</Typography>}
          />
        ))}
      </MUIRadioGroup>
    </Box>
  );
};

export default RadioGroup;
