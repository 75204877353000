import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import upcomingInterviewsService from "../../services/interviews";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useEditInterview = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { mutate: editInterview, isLoading } = useMutation(upcomingInterviewsService.editInterview, {
    mutationKey: QUERY_STRINGS.EDIT_INTERVIEW,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
  });

  return { editInterview, isLoading };
};
