import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import usersService from "../../services/users";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent, PaginatedData } from "../../types/default";
import { UserBaseTypes } from "../../types/users";

interface ReturnType {
  isLoading: boolean;
  usersData?: PaginatedData<UserBaseTypes>;
}

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useSuperadmins = (
  page: number,
  perPage: number,
  handleChangePage?: (newPage: number) => void
): ReturnType => {
  const addSnackBar = useSnackbar(addSnackBarSelector);

  const { isLoading, data: usersData } = useQuery(
    [QUERY_STRINGS.GET_SUPERADMINS, page, perPage],
    () => usersService.getSuperadmins(page, perPage),
    {
      keepPreviousData: true,
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
      onSuccess: data => !data.data.length && data.totalElements && handleChangePage && handleChangePage(page - 1),
    }
  );

  return {
    isLoading,
    usersData,
  };
};
