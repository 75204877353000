import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import upcomingInterviewsService from "../../services/interviews";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteInterview = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { mutate: deleteInterview, isLoading } = useMutation(upcomingInterviewsService.deleteInterview, {
    mutationKey: QUERY_STRINGS.DELETE_INTERVIEW,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries([QUERY_STRINGS.GET_INTERVIEWS]),
  });

  return { deleteInterview, isLoading };
};
