import { createStore } from "./storeUtils";

export type ConsultantsState = {
  selectedConsultants: string[];
  lastSelectedConsultant: string | null;
  addSelected: (selected: string[]) => void;
  resetSelected: () => void;
  addLastSelectedConsultant: (consultantId: string) => void;
  resetLastSelectedConsultant: () => void;
};

const initialState = {
  selectedConsultants: [],
  lastSelectedConsultant: null,
};

export const useConsultantsStore = createStore<ConsultantsState>("consultants", set => ({
  ...initialState,
  addSelected: (data: string[]) => set(state => ({ ...state, selectedConsultants: data })),
  resetSelected: () => set(state => ({ ...state, selectedConsultants: [] })),
  addLastSelectedConsultant: (consultantId: string) => set(() => ({ lastSelectedConsultant: consultantId })),
  resetLastSelectedConsultant: () => set(() => ({ lastSelectedConsultant: null })),
}));
