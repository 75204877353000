import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { PATHS, QUERY_STRINGS } from "../../constants";
import sublistService from "../../services/sublists";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteSublist = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: deleteSublist, isLoading } = useMutation(sublistService.deleteSublist, {
    mutationKey: QUERY_STRINGS.DELETE_SUBLIST,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_STRINGS.GET_SUBLISTS]);
      navigate(PATHS.root);
    },
  });

  return { deleteSublist, isLoading };
};
