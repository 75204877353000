import { FC } from "react";

import { DateSeparator, DateSeparatorProps } from "stream-chat-react";

import { formatDate } from "../../functions";

const CustomDateSeparator: FC<React.PropsWithChildren<DateSeparatorProps>> = ({ date }) => {
  return <DateSeparator date={date} formatDate={formatDate} position={"center"} />;
};

export default CustomDateSeparator;
