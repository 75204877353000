import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { ConsultantsState, useConsultantsStore } from "../../store/useConsultantsStore";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { RateConsultant, RateConsultantResponse } from "../../types/consultants";
import { ErrorContent } from "../../types/default";

interface ReturnType {
  rateConsultant: UseMutateFunction<RateConsultantResponse, ErrorContent, RateConsultant, unknown>;
  isLoading: boolean;
  rateResponse?: RateConsultantResponse;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;
const lastSelectedConsultantSelector = (state: ConsultantsState) => ({
  lastSelectedConsultant: state.lastSelectedConsultant,
  addLastSelectedConsultant: state.addLastSelectedConsultant,
});

export const useRateConsultant = (): ReturnType => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const { lastSelectedConsultant, addLastSelectedConsultant } = useConsultantsStore(lastSelectedConsultantSelector);
  const queryClient = useQueryClient();

  const {
    mutate: rateConsultant,
    isLoading,
    data: rateResponse,
  } = useMutation(consultantsService.rateConsultant, {
    mutationKey: QUERY_STRINGS.RATE_CONSULTANT,
    onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
    onSuccess: (data: RateConsultantResponse) => {
      lastSelectedConsultant === data.userId && addLastSelectedConsultant(data.userId);

      queryClient.invalidateQueries(QUERY_STRINGS.GET_CONSULTANTS);
    },
  });

  return { rateConsultant, isLoading, rateResponse };
};
