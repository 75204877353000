import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import sublistService from "../../services/sublists";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useCreateSublist = () => {
  const queryClient = useQueryClient();
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { mutate: createSublist, isLoading } = useMutation(sublistService.createSublist, {
    mutationKey: QUERY_STRINGS.CREATE_SUBLIST,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_SUBLISTS),
  });

  return { createSublist, isLoading };
};
