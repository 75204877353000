import { TEXT_AND_NUMBERS_REGEX } from "../../../constants";
import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { InitialMessageTypes } from "../../../types/notifications";

export const addMessageConfig: FormConstructor<InitialMessageTypes> = {
  fields: [
    {
      name: "reason",
      label: "main:popoverContent.reason",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_AND_NUMBERS_REGEX,
          label: "main:popoverContent.errors.invalidReason",
        },
      },
      type: FieldType.text,
      defaultValue: "",
      multiline: true,
    },
    {
      name: "message",
      label: "main:popoverContent.message",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_AND_NUMBERS_REGEX,
          label: "main:popoverContent.errors.invalidMessage",
        },
      },
      type: FieldType.text,
      defaultValue: "",
      multiline: true,
    },
  ],
};
