import { BaseEntity } from "./default";

export interface RateConsultant extends BaseEntity {
  rating: number;
}

export interface RateConsultantResponse {
  userId: string;
  averageRating: number;
}

export enum ConsultantsSortValue {
  STARS = "stars",
}

export interface FirstMessageToConsultant {
  reason: string;
  message: string;
}
