import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import notificationsService from "../../services/notifications";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useSendRegistrationToken = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { mutate: sendRegistrationToken, isLoading } = useMutation(notificationsService.sendRegistrationToken, {
    mutationKey: QUERY_STRINGS.SEND_REGISTRATION_TOKEN,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
  });

  return { sendRegistrationToken, isLoading };
};
