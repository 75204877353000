import { FC } from "react";

import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getCommitmentDate, getCommitmentDateAsMilliseconds } from "../../functions";
import { useDeleteJobRequirement } from "../../hooks/requirements/useDeleteJobRequirement";
import { useRequirementFieldOptions } from "../../hooks/requirements/useRequirementFieldOptions";
import { useRequirementsDataFetch } from "../../hooks/useRequirementsDataFetch";
import { ActionTypes, FieldOptionNames, FormFieldsConstructor, SelectOptionsType } from "../../types/default";
import { CommitmentOptions } from "../../types/main";
import { ConsultantsToRequirement, Requirement, RequirementData } from "../../types/requirements";
import DeletePopup from "../DeletePopup";
import { addConsultantsToRequirementBuilder } from "../forms/builders/addConsultantsToRequirementBuilder";
import { formConfig } from "../forms/builders/newRequirementBuilder";
import FormConsumer from "../forms/FormConsumer";

interface Props {
  modalType: ActionTypes | null;
  handleCloseModal: () => void;
  team: RequirementData;
}

const RequirementOptions: FC<Props> = ({ modalType, handleCloseModal, team }) => {
  const { t } = useTranslation();
  const { commitmentOptions, optionsMonths } = useRequirementFieldOptions();
  const { title, managerId, skillDetails, commitmentTimestamp } = team;

  const { skillsData, managersData, usersData, addConsultantsToRequirement, editJobRequirement, isLoading } =
    useRequirementsDataFetch();

  const { deleteRequirement, isLoading: isDeleteRequirementLoading } = useDeleteJobRequirement();

  const handleSubmitConsultantsToRequirement = (data: ConsultantsToRequirement) => {
    const consultantIds = data.consultants.map(consultant => consultant.id);
    addConsultantsToRequirement({ requirementId: team.id, consultantIds });
    handleCloseModal();
  };

  const handleSubmitEditRequirement = (data: Requirement) => {
    const commitmentDate = getCommitmentDateAsMilliseconds(Number(data.futureCommitment));
    const skillIds = data.skillIds.map(skill => skill.id);

    editJobRequirement({
      body: { title: data.title, managerId: data.managerId, commitmentDate, skillIds },
      id: team.id,
    });
  };

  const handleDeleteRequirement = () => {
    deleteRequirement(team.id);
    handleCloseModal();
  };

  const managersDataMapped: SelectOptionsType[] | undefined = managersData
    ? managersData.map(manager => ({
        id: manager.id,
        name: `${manager.firstName} ${manager.lastName}`,
      }))
    : [];

  if (isLoading || !usersData || isDeleteRequirementLoading) {
    return <CircularProgress />;
  }

  const consultantsOptions = usersData.data.map(consultant => ({
    id: consultant.id,
    name: `${consultant.firstName} ${consultant.lastName}`,
  }));

  const addConsultantsToRequirementWithOptions = addConsultantsToRequirementBuilder.fields.map(field => ({
    ...field,
    options: consultantsOptions ?? [],
  }));

  const getOptions = (field: FormFieldsConstructor<Requirement>): SelectOptionsType[] | undefined => {
    switch (field.name) {
      case FieldOptionNames.SKILL_IDS:
        return skillsData;
      case FieldOptionNames.MANAGER:
        return managersDataMapped;
      case FieldOptionNames.COMMITMENT:
        return commitmentOptions;
      case FieldOptionNames.FUTURE_COMMITMENT:
        return optionsMonths;
      default:
        return field.options;
    }
  };

  const editRequirementValues = {
    title,
    managerId,
    commitment: getCommitmentDate(commitmentTimestamp) ? CommitmentOptions.future : CommitmentOptions.immediately,
    futureCommitment: getCommitmentDate(commitmentTimestamp)!,
    skillIds: skillDetails,
  };

  const editRequirementFormFields = formConfig.fields.map(field => ({
    ...field,
    options: getOptions(field),
    defaultValue: editRequirementValues[field.name],
  }));

  return (
    <>
      {modalType === ActionTypes.ADD && (
        <FormConsumer<ConsultantsToRequirement>
          closeModal={handleCloseModal}
          btnLabel={t("main:addConsultantsToRequirement.btnSubmit")}
          formConfig={{ ...addConsultantsToRequirementBuilder, fields: addConsultantsToRequirementWithOptions }}
          handleSubmitForm={handleSubmitConsultantsToRequirement}
        />
      )}

      {modalType === ActionTypes.EDIT && (
        <FormConsumer
          closeModal={handleCloseModal}
          btnLabel={t("main:addConsultantsToRequirement.btnSubmit")}
          formConfig={{ ...formConfig, fields: editRequirementFormFields }}
          handleSubmitForm={handleSubmitEditRequirement}
        />
      )}

      {modalType === ActionTypes.DELETE && (
        <DeletePopup
          label={t("main:requirementCard.requirement")}
          cancelDeleteAction={handleCloseModal}
          handleDeleteAction={handleDeleteRequirement}
        />
      )}
    </>
  );
};

export default RequirementOptions;
