import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import flaggedCvsService from "../../services/flaggedCvs";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useFlaggedCvs = () => {
  const addSnackBar = useSnackbar(addSnackbarSelector);
  const { data: flaggedCvs, isLoading } = useQuery(QUERY_STRINGS.GET_FLAGGED_CVS, flaggedCvsService.getFlaggedCvs, {
    onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
  });

  return { flaggedCvs, isLoading };
};
