import { OrderType, SortOrder } from "./default";

export enum DateTypes {
  DATE = "date",
  TIME = "time",
}

export enum CommitmentOptions {
  immediately = "Immediately",
  future = "In the future",
}

export enum ConsultantsFilters {
  projectOrder = "projectOrder",
  skill = "skill",
  availability = "availability",
  interviewed = "interviewed",
  questionaireAnswered = "questionaireAnswered",
}

export interface FilterOptions {
  projectOrder?: string;
  skill?: string;
  availability?: string;
  interviewed?: boolean;
  questionaireAnswered?: boolean;
  order?: OrderType;
  sortBy?: string;
  recentlyAdded?: boolean;
}

export interface HomeScreenFilters {
  order?: SortOrder;
  sortBy?: string;
  currentRequirementId?: string;
  recentlyAdded?: boolean;
}
