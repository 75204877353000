import { useState } from "react";

import { UseMutateFunction } from "react-query";

import { ErrorContent } from "../types/default";

export const useDeleteItem = (deleteFunc: UseMutateFunction<void, ErrorContent, string, unknown>) => {
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const handleCloseModal = () => setItemToDelete(null);

  const handleDeleteIconClick = (id: string) => () => setItemToDelete(id);

  const handleDeleteItem = () => {
    itemToDelete && deleteFunc(itemToDelete);
    handleCloseModal();
  };

  return { itemToDelete, handleCloseModal, handleDeleteIconClick, handleDeleteItem };
};
