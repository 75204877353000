import { User } from "firebase/auth";
import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import authenticationService from "../../services/authentication";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { ErrorContent } from "../../types/default";

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

interface ReturnTypes {
  isLoading: boolean;
  refreshToken: (user: User) => void;
}

const loginSelector = (state: UserState) => state.login;
const isLoggedSelector = (state: UserState) => state.isLoggedIn;

export const useRefreshToken = (): ReturnTypes => {
  const addSnackBar = useSnackbar(addSnackBarSelector);
  const login = useUserStore(loginSelector);
  const isLoggedIn = useUserStore(isLoggedSelector);

  const { mutate: refreshToken, isLoading } = useMutation((user: User) => authenticationService.refreshToken(user), {
    mutationKey: QUERY_STRINGS.REFRESH_TOKEN,
    onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
    onSuccess: () => {
      if (!isLoggedIn) {
        login();
      }
    },
  });

  return { refreshToken, isLoading };
};
