import { useCallback, useState } from "react";

import { OrderType, SortOrder } from "../types/default";

interface ReturnType<T> {
  order: OrderType;
  sortBy: keyof T;
  handleSortClick: (name: keyof T) => void;
}

function useSort<TType>(initialOrder: OrderType, initialSortValue: keyof TType): ReturnType<TType> {
  const [order, setOrder] = useState<OrderType>(initialOrder);
  const [sortBy, setSortBy] = useState(initialSortValue);

  const handleSortClick = useCallback(
    (sortBy: keyof TType) => {
      setOrder(order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
      setSortBy(sortBy);
    },
    [order, sortBy]
  );
  return { order, sortBy, handleSortClick };
}

export default useSort;
