import dayjs from "dayjs";

import { EMAIL_REGEX, NUMBER_REGEX, DESCRIPTION_REGEX, TEXT_REGEX } from "../../../constants";
import { FieldOptionTypes, FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { RegisterConsultantFormTypes } from "../../../types/users";

export const addConsultantBuilder: FormConstructor<RegisterConsultantFormTypes> = {
  fields: [
    {
      name: "firstName",
      label: "users:form.firstName",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_REGEX,
          label: "users:errors.invalid",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "lastName",
      label: "users:form.lastName",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_REGEX,
          label: "users:errors.invalid",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "email",
      label: "users:form.email",
      validationOptions: {
        required: true,
        pattern: {
          pattern: EMAIL_REGEX,
          label: "users:errors.invalidEmail",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "isMarked",
      label: "users:form.moveToTop",
      type: FieldType.checkbox,
      defaultValue: "",
    },
    {
      name: "managerDetails",
      label: "users:form.managers",
      validationOptions: {
        required: true,
      },
      type: FieldType.selectMultiple,
      defaultValue: [],
      options: [],
    },
    {
      name: "jobRequirementsDetails",
      label: "users:form.requirements",
      type: FieldType.selectMultiple,
      defaultValue: [],
      options: [],
    },
    {
      name: "skillDetails",
      label: "users:form.skills",
      validationOptions: {
        required: true,
      },
      type: FieldType.selectMultiple,
      defaultValue: [],
      options: [],
    },
    {
      name: "interviewAvailability",
      label: "users:form.interviewAvailability",
      type: FieldType.date,
      defaultValue: dayjs(),
      validationOptions: {
        required: true,
        validateDate: {
          date: dayjs(),
          label: "users:errors.invalidValue",
        },
      },
      disablePastDate: true,
    },
    {
      name: "jobAvailability",
      label: "users:form.availability",
      type: FieldType.date,
      defaultValue: dayjs(),
      validationOptions: {
        required: true,
        validateDate: {
          date: dayjs(),
          label: "users:errors.invalidValue",
        },
      },
      disablePastDate: true,
    },
    {
      name: "dailyRate",
      label: "users:form.dailyRate",
      validationOptions: {
        pattern: {
          pattern: NUMBER_REGEX,
          label: "users:errors.invalidValue",
        },
      },
      type: FieldType.number,
      defaultValue: "",
      hasEndAdornment: true,
      endAdornment: "users:form.euroPerDay",
    },
    {
      name: "description",
      label: "users:form.description",
      validationOptions: {
        pattern: {
          pattern: DESCRIPTION_REGEX,
          label: "users:errors.invalidDescription",
        },
      },
      type: FieldType.text,
      multiline: true,
      defaultValue: "",
      isFreeText: true,
    },
    {
      name: "consultantCv",
      label: "users:form.uploadCV",
      type: FieldType.file,
      defaultValue: "",
      fileType: FieldOptionTypes.PDF,
    },
    {
      name: "profileImage",
      label: "users:form.uploadImage",
      type: FieldType.file,
      defaultValue: "",
      fileType: FieldOptionTypes.IMAGE,
    },
  ],
};
