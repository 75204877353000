import { FC, useEffect } from "react";

import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import shallow from "zustand/shallow";

import { CardContentSection, ConsultantsTable } from "../components";
import { QUERY_STRINGS } from "../constants";
import { useConsultantsBySublist } from "../hooks/consultants/useConsultantsBySublist";
import { useChangePage } from "../hooks/useChangePage";
import Loading from "../layouts/Loading";
import { UserState, useUserStore } from "../store/useUserStore";
import { UserRoles } from "../types/users";

const userRoleSelector = (state: UserState) => ({
  userRole: state.role,
});

const Sublist: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { userRole } = useUserStore(userRoleSelector, shallow);

  const { sublistId } = useParams<{ sublistId: string }>();

  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();
  const { isLoading, usersData } = useConsultantsBySublist(sublistId, page, perPage);

  useEffect(() => {
    return () => {
      queryClient.removeQueries([QUERY_STRINGS.GET_CONSULTANTS_BY_SUBLIST, page, perPage, sublistId]);
    };
  }, [sublistId]);

  if (isLoading || !usersData) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("sublists:description", { sublistName: usersData.name })} />
        <title>
          {t("sublists:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <Box paddingTop={userRole === UserRoles.superadmin ? 3 : 0}>
        <CardContentSection
          title={userRole === UserRoles.superadmin ? `${usersData.name} - ${usersData.owner.name}` : usersData.name}
          table={
            <ConsultantsTable
              usersData={usersData.consultants.data}
              page={page}
              perPage={perPage}
              handleChangePage={handleChangePage}
              handleChangePerPage={handleChangePerPage}
              totalElements={usersData.consultants.totalElements}
              showPagination
              showRowsPerPage
            />
          }
          hasAvailabilityData
        />
      </Box>
    </>
  );
};

export default Sublist;
