import kommlinkApi, { BackendError } from "../lib/axios";
import { PaginatedData } from "../types/default";
import { EditInterviewTypes, ScheduleInterviewResponse, ScheduleInterviewTypes } from "../types/interviews";

const getUpcomingInterviews = async (
  page: number,
  perPage: number
): Promise<PaginatedData<ScheduleInterviewResponse>> => {
  try {
    const res = await kommlinkApi.get<PaginatedData<ScheduleInterviewResponse>>("/upcoming-interviews", {
      params: {
        page,
        perPage,
      },
    });
    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const scheduleInterview = async ({
  consultantId,
  data,
}: {
  consultantId: string;
  data: ScheduleInterviewTypes<number>;
}) => {
  try {
    await kommlinkApi.post<void>("/manager/mails/interviews", {
      ...data,
      meetingURL: data.meetingURL || undefined,
      consultantId,
    });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editInterview = async ({ interviewId, data }: { interviewId: string; data: EditInterviewTypes<number> }) => {
  try {
    await kommlinkApi.put<void>(`/superadmin/upcoming-interviews/${interviewId}`, data);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteInterview = async (interviewId: string) => {
  try {
    await kommlinkApi.delete<void>(`/superadmin/upcoming-interviews/${interviewId}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const interviewsService = {
  scheduleInterview,
  deleteInterview,
  editInterview,
  getUpcomingInterviews,
};

export default interviewsService;
