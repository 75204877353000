import kommlinkApi, { BackendError } from "../lib/axios";
import { CompaniesData, EditCompanyData } from "../types/companies";
import { PaginatedData } from "../types/default";

const createCompany = async (name: string): Promise<void> => {
  try {
    await kommlinkApi.post<string>("/superadmin/companies", { name });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getAllCompanies = async (page?: number, perPage?: number): Promise<PaginatedData<CompaniesData>> => {
  try {
    const res = await kommlinkApi.get<PaginatedData<CompaniesData>>("/superadmin/companies", {
      params: {
        page,
        perPage,
      },
    });
    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteCompany = async (companyId: string): Promise<void> => {
  try {
    await kommlinkApi.delete<void>(`/superadmin/companies/${companyId}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editCompany = async ({ companyId, name }: EditCompanyData): Promise<void> => {
  try {
    await kommlinkApi.put<void>(`/superadmin/companies/${companyId}`, { name });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const companyService = {
  createCompany,
  getAllCompanies,
  deleteCompany,
  editCompany,
};

export default companyService;
