import { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  content: {
    paddingRight: theme.spacing(4),
    overflow: "auto",
    height: "400px",
    "&::-webkit-scrollbar": {
      width: theme.spacing(1.25),
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

interface Props {
  hasMaxHeight?: boolean;
  hasFullCvButton?: boolean;
  handleFullCvClick?: () => void;
  consultantDescription?: string;
}

const ConsultantInfoModalContent: FC<Props> = ({
  hasMaxHeight,
  hasFullCvButton,
  handleFullCvClick,
  consultantDescription,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box overflow="scroll" height={"100%"}>
      <Box overflow="scroll" height={"100%"}>
        <Typography variant={"subtitle1"} marginBottom={1}>
          {t("main:popoverContent.title")}
        </Typography>
        <Typography
          variant={"subtitle2"}
          style={{ whiteSpace: "pre-line" }}
          fontWeight={400}
          maxHeight={hasMaxHeight ? "200px" : undefined}
          className={hasFullCvButton ? undefined : classes.content}
        >
          {consultantDescription ? consultantDescription : t("main:popoverContent.noDescription")}
        </Typography>
      </Box>

      {hasFullCvButton && (
        <Box textAlign={"right"} marginTop={2}>
          <Button variant="contained" onClick={handleFullCvClick}>
            <Typography variant={"button"}>{t("main:popoverContent.btnText")}</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ConsultantInfoModalContent;
