import { createContext, FC } from "react";

import mixpanel, { Dict } from "mixpanel-browser";

import config from "../lib/config";

export type MixpanelContextState = {
  mixpanelTrack: (event: string, properties?: Dict) => void;
  mixpanelIdentify: (id: string) => void;
  mixpanelRegister: (props: Dict) => void;
  mixpanelSetCurrentPerson: (name: string, email: string, properties?: Dict) => void;
  mixpanelLogout: () => void;
};

export const MixpanelContext = createContext<MixpanelContextState>(null!);

const MixpanelContextProvider: FC = ({ children }) => {
  mixpanel.init(config.MIXPANEL_KEY!, { debug: true });

  const mixpanelTrack = (event: string, properties?: Dict) => mixpanel.track(event, properties);

  const mixpanelIdentify = (id: string) => mixpanel.identify(id);

  const mixpanelRegister = (props: Dict) => mixpanel.register_once(props);

  const mixpanelSetCurrentPerson = (name: string, email: string, properties?: Dict) =>
    mixpanel.people.set_once({
      $name: name,
      $email: email,
      ...properties,
    });

  const mixpanelLogout = () => mixpanel.reset();

  const contextValue = {
    mixpanelTrack,
    mixpanelIdentify,
    mixpanelRegister,
    mixpanelSetCurrentPerson,
    mixpanelLogout,
  };

  return <MixpanelContext.Provider value={contextValue}>{children}</MixpanelContext.Provider>;
};

export default MixpanelContextProvider;
