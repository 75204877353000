import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import skillsService from "../../services/skills";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent, PaginatedData, SelectOptionsType } from "../../types/default";

interface ReturnType {
  skillsData?: PaginatedData<SelectOptionsType>;
  isLoading: boolean;
}

const snackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useSkills = (
  page?: number,
  perPage?: number,
  handleChangePage?: (newPage: number) => void
): ReturnType => {
  const addSnackbar = useSnackbar(snackbarSelector);
  const { data: skillsData, isLoading } = useQuery(
    [QUERY_STRINGS.GET_SKILLS, page, perPage],
    () => skillsService.getSkills(page, perPage),
    {
      keepPreviousData: true,
      onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
      onSuccess: data =>
        !data.data.length && data.totalElements && page && handleChangePage && handleChangePage(page - 1),
    }
  );

  return { skillsData, isLoading };
};
