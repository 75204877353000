import { FC, useContext, useEffect, useState } from "react";

import { Box } from "@mui/material";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import shallow from "zustand/shallow";

import { CardContentSection, CardHeader, ConsultantsTable, Filters } from "../components";
import { addConsultantBuilder } from "../components/forms/builders/addConsultantBuilder";
import { MASTER_USER_LIST, STRING_FIELD_DEFAULT_VALUE } from "../constants";
import { AuthContext } from "../contexts/AuthContext";
import { useChangePage } from "../hooks/useChangePage";
import { useConsultantsScreenDataFetch } from "../hooks/useConsultantsScreenDataFetch";
import Loading from "../layouts/Loading";
import { ConsultantsState, useConsultantsStore } from "../store/useConsultantsStore";
import { UserState, useUserStore } from "../store/useUserStore";
import { FieldOptionNames, FieldOptionTypes, FormFieldsConstructor } from "../types/default";
import { FieldType } from "../types/login";
import { FilterOptions } from "../types/main";
import { ConsultantsData, RegisterConsultantFormTypes, UserRoles } from "../types/users";

const consultantsDataSelector = (state: ConsultantsState) => ({
  resetSelected: state.resetSelected,
});

const userRoleSelector = (state: UserState) => ({
  userRole: state.role,
});

const Consultants: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const requirementId = location.state as string;

  const [filterOptions, setFilterOptions] = useState<FilterOptions | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);
  const [currentConsultantData, setCurrentConsultantData] = useState<null | ConsultantsData>(null);

  const { sublistId } = useParams<{ sublistId: string }>();
  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();

  const { resetSelected } = useConsultantsStore(consultantsDataSelector, shallow);
  const { userRole } = useUserStore(userRoleSelector, shallow);

  const { registerUser, isLoadingInComponent: isLoading } = useContext(AuthContext);

  const {
    editConsultant,
    isLoading: isHookDataLoading,
    getOptions,
    requirementsData,
    skillsData,
    usersData,
  } = useConsultantsScreenDataFetch(false, page, perPage, handleChangePage, filterOptions);

  const handleSubmitForm = (data: RegisterConsultantFormTypes) => {
    const { skillDetails, managerDetails, jobRequirementsDetails, jobAvailability, interviewAvailability, ...rest } =
      data;
    const skillIds = skillDetails.map(skill => skill.id);
    const managerIds = managerDetails.map(manager => manager.id);
    const jobRequirementsIds = jobRequirementsDetails.map(requirement => requirement.id);
    const availabilityMilliseconds = dayjs(jobAvailability).valueOf();
    const interviewAvailabilityMilliseconds = dayjs(interviewAvailability).valueOf();

    editMode && currentConsultantData
      ? editConsultant({
          ...rest,
          companyIds: [],
          skillIds,
          managerIds,
          jobRequirementsIds,
          jobAvailability: availabilityMilliseconds,
          interviewAvailability: interviewAvailabilityMilliseconds,
          id: currentConsultantData.id,
        })
      : registerUser({
          ...rest,
          role: UserRoles.consultant,
          jobAvailability: availabilityMilliseconds,
          interviewAvailability: interviewAvailabilityMilliseconds,
          companyIds: [],
          skillIds,
          managerIds,
          jobRequirementsIds,
        });
  };

  const handleChangeMode = (data: ConsultantsData) => () => {
    const currentConsultantAvailability = dayjs(data.jobAvailability);
    const currentConsultantInterviewAvailability = dayjs(data.interviewAvailability);

    setEditMode(true);
    setCurrentConsultantData({
      ...data,
      jobAvailability: currentConsultantAvailability,
      interviewAvailability: currentConsultantInterviewAvailability,
    });
  };

  const handleCloseEditMode = () => setEditMode(false);

  useEffect(() => {
    if (requirementId) {
      setFilterOptions({ ...filterOptions, projectOrder: requirementId });
    }

    return () => {
      resetSelected();
    };
  }, [requirementId]);

  if (isLoading || isHookDataLoading) {
    return <Loading />;
  }

  const getDefaultValues = (field: FormFieldsConstructor<RegisterConsultantFormTypes>) => {
    if (currentConsultantData) {
      if (field.name === FieldOptionNames.JOB_REQUIREMENTS) {
        return currentConsultantData[FieldOptionNames.JOB_REQUIREMENTS].filter(
          requirement => requirement.name !== MASTER_USER_LIST
        );
      }

      if (field.name === FieldOptionNames.CONSULTANT_CV) {
        return currentConsultantData.recentlyCVs;
      }

      if (!currentConsultantData[field.name] && (field.type === FieldType.text || field.type === FieldType.number)) {
        return STRING_FIELD_DEFAULT_VALUE;
      }

      return currentConsultantData[field.name];
    }

    return field.defaultValue;
  };

  const updatedFields = addConsultantBuilder.fields.map(field => ({
    ...field,
    options: getOptions(field),
  }));

  const editConsultantConfigFields = addConsultantBuilder.fields
    .filter(field => field.name !== FieldOptionNames.PROFILE_IMAGE)
    .map(field => ({
      ...field,
      defaultValue: getDefaultValues(field),
      options: getOptions(field),
      disabledField: field.name === FieldOptionNames.EMAIL,
      showPreviousUploadedFile: field.type === FieldOptionTypes.FILE,
    }));

  return (
    <>
      <Helmet>
        <meta name="description" content={t("companies:description")} />
        <title>
          {t("consultants:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      {userRole === UserRoles.superadmin && (
        <CardHeader
          title={t("consultants:title")}
          btnLabel={t("consultants:addNewBtn")}
          modalTitle={editMode ? t("consultants:edit") : t("consultants:addNew")}
          formConfig={{ ...addConsultantBuilder, fields: editMode ? editConsultantConfigFields : updatedFields }}
          modalBtnTitle={editMode ? t("formFields:buttons.done") : t("consultants:addNewBtn")}
          size={"md"}
          isEditMode={editMode}
          handleSubmitForm={handleSubmitForm}
          closeEditMode={handleCloseEditMode}
        />
      )}
      <Box paddingTop={userRole === UserRoles.superadmin ? 3 : 0}>
        <CardContentSection
          title={userRole !== UserRoles.superadmin ? t("consultants:title") : undefined}
          table={
            <ConsultantsTable
              filterOptions={filterOptions}
              handleChangeMode={handleChangeMode}
              usersData={usersData?.data}
              totalElements={usersData?.totalElements}
              page={page}
              perPage={perPage}
              handleChangePage={handleChangePage}
              handleChangePerPage={handleChangePerPage}
              hasOptions
              showPagination
              showRowsPerPage
            />
          }
          hasAvailabilityData
        >
          {!sublistId ? (
            <Filters
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              requirementsData={requirementsData}
              skillsData={skillsData?.data}
            />
          ) : null}
        </CardContentSection>
      </Box>
    </>
  );
};

export default Consultants;
