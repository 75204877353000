import kommlinkApi, { BackendError } from "../lib/axios";
import { BaseEntity, PaginatedData } from "../types/default";
import { RegisterUserTypes, UserBaseTypes, UserRoles } from "../types/users";
import authenticationService from "./authentication";
import filesService from "./files";

const getManagers = async (page?: number, perPage?: number): Promise<PaginatedData<UserBaseTypes>> => {
  try {
    const res = await kommlinkApi.get<PaginatedData<UserBaseTypes>>("/managers", {
      params: {
        page,
        perPage,
      },
    });

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getSuperadmins = async (page: number, perPage: number): Promise<PaginatedData<UserBaseTypes>> => {
  try {
    const res = await kommlinkApi.get<PaginatedData<UserBaseTypes>>("/superadmin/superadmins", {
      params: {
        page,
        perPage,
      },
    });

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const createUser = async (data: RegisterUserTypes): Promise<void> => {
  try {
    const { auth, consultantCv, profileImage, ...rest } = data;

    const imageResponse = profileImage && (await filesService.uploadFile(profileImage));

    const imageId = imageResponse || null;

    const user = await kommlinkApi.post<BaseEntity>("/superadmin/users", {
      ...rest,
      dailyRate: Number(rest.dailyRate),
      imageId,
    });

    if (data.role === UserRoles.consultant && consultantCv && typeof consultantCv !== "string") {
      await filesService.uploadConsultantCv(consultantCv, user.data.id);
    }

    await authenticationService.firebaseForgotPassword({ email: rest.email, auth });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editManager = async (data: UserBaseTypes): Promise<void> => {
  try {
    await kommlinkApi.patch<void>(`/superadmin/managers/${data.id}`, data);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteManager = async (id: string) => {
  try {
    await kommlinkApi.delete<void>(`/superadmin/managers/${id}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editSuperadmin = async (data: UserBaseTypes): Promise<void> => {
  try {
    await kommlinkApi.patch<void>(`/superadmin/superadmins/${data.id}`, data);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteSuperadmin = async (id: string) => {
  try {
    await kommlinkApi.delete<void>(`/superadmin/superadmins/${id}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const usersService = {
  createUser,
  getSuperadmins,
  getManagers,
  editManager,
  editSuperadmin,
  deleteManager,
  deleteSuperadmin,
};

export default usersService;
