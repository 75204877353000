import { FC } from "react";

import { Close } from "@mui/icons-material";
import { Box, Breakpoint, Dialog, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface Props {
  title: string;
  isOpened: boolean;
  closeModal: () => void;
  size: Breakpoint;
}

const useStyles = makeStyles()(theme => ({
  innerContainer: {
    padding: theme.spacing(3, 4, 3, 4),
  },
  contentContainer: {
    padding: theme.spacing(1, 7, 2, 7),
  },
}));

const ModalContainer: FC<Props> = ({ title, isOpened, closeModal, children, size }) => {
  const { classes } = useStyles();

  return (
    <Dialog open={isOpened} maxWidth={size}>
      <Box display={"flex"} justifyContent={"space-between"} className={classes.innerContainer}>
        <Typography variant={"h3"} color={"textPrimary"}>
          {title}
        </Typography>

        <Close onClick={closeModal} color={"primary"} cursor={"pointer"} />
      </Box>

      <Box className={classes.contentContainer}>{children}</Box>
    </Dialog>
  );
};

export default ModalContainer;
