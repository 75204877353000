import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import requirementsService from "../../services/requirements";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";
import { AddConsultantsToRequirementData } from "../../types/requirements";

interface ReturnType {
  addConsultantsToRequirement: UseMutateFunction<void, ErrorContent, AddConsultantsToRequirementData, unknown>;
  isLoading: boolean;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useAddConsultantsToRequirement = (): ReturnType => {
  const addSnackBar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: addConsultantsToRequirement, isLoading } = useMutation(
    requirementsService.addConsultantsToRequirement,
    {
      mutationKey: QUERY_STRINGS.ADD_CONSULTANT_TO_REQUIREMENT,
      onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
      onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_REQUIREMENTS),
    }
  );

  return { addConsultantsToRequirement, isLoading };
};
