import { FC, useContext, useState } from "react";

import { MoreVert, PageviewOutlined } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { t } from "i18next";
import { makeStyles } from "tss-react/mui";

import { FullCvDialog } from "..";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import { MixpanelEvents } from "../../types/mixpanel";

const useStyles = makeStyles()(theme => ({
  moreIcon: {
    color: theme.palette.dark.main,
    marginRight: theme.spacing(2),
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  },
  menuList: {
    padding: theme.spacing(0),
    border: "none",
  },
}));

interface Props {
  consultantCV?: string;
}

const ChannelOptions: FC<Props> = ({ consultantCV }) => {
  const { classes } = useStyles();
  const { mixpanelTrack } = useContext(MixpanelContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fullCvOpen, setFullCvOpen] = useState(false);

  const handleMoreOptionsClick = () => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => setAnchorEl(null);

  const handleOpenFullCv = () => {
    //Dont want to delete it for now
    // setFullCvOpen(true);
    mixpanelTrack(MixpanelEvents.FULL_CV_VIEW);
    window.open(consultantCV);
    handleCloseOptions();
  };

  const handleCloseFullCv = () => setFullCvOpen(false);

  return (
    <>
      <IconButton className={classes.moreIcon} onClick={handleMoreOptionsClick()}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ list: classes.menuList }}
      >
        <MenuItem className={classes.menuItem} onClick={handleOpenFullCv}>
          <Typography variant={"subtitle1"} color={"textPrimary"}>
            {t("messages:viewCv")}
          </Typography>
          <PageviewOutlined />
        </MenuItem>
      </Menu>

      {consultantCV && (
        <FullCvDialog fullCvOpen={fullCvOpen} handleCloseFullCv={handleCloseFullCv} filePath={consultantCV} />
      )}
    </>
  );
};

export default ChannelOptions;
