import { FC, useState } from "react";

import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, CardHeader, SentQuestionsTable } from "../components";
import { createQuestionsBuilder } from "../components/forms/builders/createQuestionsBuilder";
import { useCreateQuestionaire } from "../hooks/questions/useCreateQuestionaire";
import { useEditQuestionaire } from "../hooks/questions/useEditQuestionaire";
import { useQuestionsOptions } from "../hooks/questions/useQuestionOptions";
import Loading from "../layouts/Loading";
import { UserState, useUserStore } from "../store/useUserStore";
import { QuestionsBase, QuestionsBody, QuestionsData } from "../types/questions";
import { UserRoles } from "../types/users";

const userRoleSelector = (state: UserState) => state.role;

const SentQuestions: FC = () => {
  const { t } = useTranslation();

  const userRole = useUserStore(userRoleSelector);
  const { createQuestionaire, isLoading: isCreatingQuestionaireLoading } = useCreateQuestionaire();
  const { editQuestionaire, isLoading: isEditQuestionaireLoading } = useEditQuestionaire();
  const {
    isLoading,
    createQuestionsBuilderFields,
    editQuestionsBuilderFields,
    currentQuestionaire,
    handleSetCurrentQuestionaire,
  } = useQuestionsOptions();

  const [editMode, setEditMode] = useState(false);

  const handleSubmitForm = (data: QuestionsBody) =>
    createQuestionaire({
      ...data,
      date: dayjs(data.date).valueOf(),
    });

  const handleSubmitEditForm = (data: QuestionsBase) =>
    currentQuestionaire &&
    editQuestionaire({
      id: currentQuestionaire.questionnaireId,
      data: {
        ...data,
        date: dayjs(data.date).valueOf(),
      },
    });

  const handleChangeMode = (data: QuestionsData) => () => {
    setEditMode(true);
    handleSetCurrentQuestionaire(data);
  };

  const handleCloseEditMode = () => {
    setEditMode(false);
    handleSetCurrentQuestionaire(null);
  };

  if (isCreatingQuestionaireLoading || isLoading || isEditQuestionaireLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("sentQuestionsPage:description")} />
        <title>
          {t("sentQuestionsPage:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      {userRole === UserRoles.superadmin && (
        <CardHeader
          title={t("sentQuestionsPage:title")}
          btnLabel={t("sentQuestionsPage:addQuestions")}
          modalTitle={editMode ? t("sentQuestionsPage:editQuestions") : t("sentQuestionsPage:addQuestions")}
          formConfig={{
            ...createQuestionsBuilder,
            fields: editMode ? editQuestionsBuilderFields : createQuestionsBuilderFields,
          }}
          modalBtnTitle={editMode ? t("formFields:buttons.done") : t("sentQuestionsPage:submitBtn")}
          size={"md"}
          handleSubmitForm={editMode ? handleSubmitEditForm : handleSubmitForm}
          isEditMode={editMode}
          closeEditMode={handleCloseEditMode}
        />
      )}
      <CardContentSection
        title={userRole !== UserRoles.superadmin ? t("sentQuestionsPage:title") : undefined}
        table={<SentQuestionsTable handleChangeMode={handleChangeMode} />}
      />
    </>
  );
};

export default SentQuestions;
