import { Box } from "@mui/material";
import { FieldValues, FormProvider, Path, SubmitHandler, useForm } from "react-hook-form";

import { FormField, FormSubmitButton } from "..";
import { UserState, useUserStore } from "../../store/useUserStore";
import { FormConstructor } from "../../types/default";
import { FieldType } from "../../types/login";
import { UserRoles } from "../../types/users";

interface Props<TType> {
  closeModal: () => void;
  btnLabel: string;
  formConfig: FormConstructor<TType>;
  btnGapLarge?: boolean;
  handleSubmitForm: (data: TType) => void;
}

const userRoleSelector = (state: UserState) => state.role;

function FormConsumer<TType extends FieldValues>({
  closeModal,
  btnLabel,
  formConfig,
  btnGapLarge,
  handleSubmitForm,
}: Props<TType>) {
  const userRole = useUserStore(userRoleSelector);
  const methods = useForm<TType>({ mode: "onSubmit" });

  const watchedValue = formConfig.global && methods.watch<Path<TType>>(formConfig.global.watchedField);

  const handleSubmitButton: SubmitHandler<TType> = data => {
    handleSubmitForm(data as TType);
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" noValidate onSubmit={methods.handleSubmit(handleSubmitButton)}>
        {formConfig.fields.map(field =>
          userRole === UserRoles.manager && field.isPrivate
            ? null
            : (!field.isWatched || (formConfig.global && watchedValue === formConfig.global.watchedFieldValue)) && (
                <Box key={field.label} marginTop={field.type === FieldType.radio ? 4.5 : 2} marginBottom={1}>
                  <FormField {...field} name={field.name as string} />
                </Box>
              )
        )}

        <FormSubmitButton label={btnLabel} btnGapLarge={btnGapLarge} />
      </Box>
    </FormProvider>
  );
}

export default FormConsumer;
