import axios from "axios";
import { getAuth } from "firebase/auth";

import { app } from "../firebase";
import authenticationService from "../services/authentication";
import config from "./config";

export interface BackendError {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

const kommlinkApi = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

kommlinkApi.interceptors.request.use(
  async req => {
    const user = getAuth(app).currentUser;

    if (user && req.headers) {
      const token = await authenticationService.refreshToken(user);

      req.headers["Authorization"] = token;
    }
    return req;
  },
  err => {
    throw new Error(err.response.data.message);
  }
);

kommlinkApi.interceptors.response.use(
  res => res,
  err => {
    throw new Error(err.response.data.message);
  }
);

export default kommlinkApi;
