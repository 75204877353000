import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import notificationsService from "../../services/notifications";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useMarkViewedNotifications = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { mutate: markViewedNotification, isLoading } = useMutation(notificationsService.markViewedNotifications, {
    mutationKey: QUERY_STRINGS.MARK_VIEWED_NOTIFICATION,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_STRINGS.GET_NOTIFICATIONS]);
      queryClient.invalidateQueries([QUERY_STRINGS.UNREAD_NOTIFICATIONS]);
    },
  });

  return { markViewedNotification, isLoading };
};
