import { FC } from "react";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { getAuth } from "firebase/auth";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { Snackbar } from "./components";
import AuthContextProvider from "./contexts/AuthContext";
import ChatContextProvider from "./contexts/ChatContext";
import FirebaseContextProvider from "./contexts/FirebaseContext";
import MixpanelContextProvider from "./contexts/MixpanelContext";
import { app } from "./firebase";
import "./i18n.ts";
import Router from "./Router";
import { theme } from "./theme";

const App: FC = () => {
  const auth = getAuth(app);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  return (
    <BrowserRouter>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient} contextSharing={true}>
            <FirebaseContextProvider>
              <MixpanelContextProvider>
                <AuthContextProvider auth={auth}>
                  <ChatContextProvider>
                    <div className="App">
                      <CssBaseline />
                      <Router />
                    </div>
                    <Snackbar />
                  </ChatContextProvider>
                </AuthContextProvider>
              </MixpanelContextProvider>
            </FirebaseContextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </BrowserRouter>
  );
};

export default App;
