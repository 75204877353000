import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import usersService from "../../services/users";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";
import { UserRoles } from "../../types/users";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteUser = (user: UserRoles) => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: deleteUser, isLoading } = useMutation(
    user === UserRoles.manager ? usersService.deleteManager : usersService.deleteSuperadmin,
    {
      mutationKey: QUERY_STRINGS.DELETE_USER,
      onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_STRINGS.GET_SUPERADMINS);
        queryClient.invalidateQueries(QUERY_STRINGS.GET_MANAGERS);
      },
    }
  );

  return { deleteUser, isLoading };
};
