import { FC } from "react";

import { Autocomplete, FormControl, FormHelperText, ListItem, TextField } from "@mui/material";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { BaseFormFieldProps, SelectOptionsType } from "../../types/default";

interface Props extends BaseFormFieldProps {
  options: SelectOptionsType[];
}

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const AutocompleteField: FC<Props> = ({ options, label, formFieldProps }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { value, ref, onChange, ...rest } = formFieldProps.field;
  const { error } = formFieldProps.fieldState;

  return (
    <FormControl fullWidth className={classes.container}>
      <Autocomplete
        {...rest}
        multiple
        ref={ref}
        onChange={(_, data) => onChange(data)}
        value={value ?? []}
        options={options}
        filterSelectedOptions
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option: SelectOptionsType, value: SelectOptionsType) => option.id === value.id}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            {option.name}
          </ListItem>
        )}
        renderInput={params => (
          <TextField {...params} label={t(label as ResourceKey)} error={Boolean(error && error?.message)} />
        )}
      />
      {error && error.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default AutocompleteField;
