import { FC, Fragment, useContext, useState } from "react";

import { Logout, Notifications } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { Notification } from "../components";
import { AuthContext } from "../contexts/AuthContext";
import { useNotifications } from "../hooks/notifications/useNotifications";
import { useUnreadNotifications } from "../hooks/notifications/useUnreadNotifications";

const useStyles = makeStyles()(theme => ({
  appBar: {
    boxShadow: theme.shadows[1],
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    height: theme.spacing(10),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.75),
  },
  logo: {
    width: "164px",
    height: "33px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
  },
  iconButton: {
    padding: theme.spacing(1.25),
  },
  searchBox: {
    position: "relative",
    display: "flex",
  },
  searchIconWrapper: {
    height: "100%",
    position: "absolute",
    right: theme.spacing(0),
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchInput: {
    color: "inherit",
    "& .MuiInputBase-input": {
      paddingLeft: theme.spacing(3),
      paddingBlock: theme.spacing(0),
      cursor: "pointer",
      transition: theme.transitions.create("width"),
      width: theme.spacing(0),
      "&:focus": {
        width: "180px",
      },
    },
  },
  companySelect: {
    marginRight: theme.spacing(5),
  },
  menuList: {
    padding: theme.spacing(0),
    border: "none",
  },
  logoutItem: {
    gap: theme.spacing(1),
  },
}));

const Navbar: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();
  const { classes } = useStyles();

  const { logout } = useContext(AuthContext);

  const { unreadNotifications } = useUnreadNotifications();
  const { notifications, fetchNextPage, hasNextPage } = useNotifications();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogoutClick = () => {
    logout();
    handleClose();
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopperAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => setPopperAnchorEl(null);

  const handleLoadMoreClick = () => fetchNextPage();

  return (
    <AppBar color="secondary" position="sticky" className={classes.appBar}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Box display={"flex"}>
          <Box component="img" src="/assets/logo.svg" alt={t("header:logoAltText")} className={classes.logo} />
        </Box>

        <Box>
          {/* Not needed for now */}
          {/* <IconButton color="inherit" className={classes.iconButton} disableFocusRipple disableRipple>
            <Box className={classes.searchBox}>
              <Box className={classes.searchInput} component={InputBase} placeholder={t("header:searchField")} />
              <Box className={classes.searchIconWrapper}>
                <SearchIcon />
              </Box>
            </Box>
          </IconButton> */}

          <IconButton
            color="inherit"
            className={classes.iconButton}
            disableFocusRipple
            disableRipple
            onClick={handleNotificationClick}
          >
            <Badge color="error" badgeContent={unreadNotifications}>
              <Notifications />
            </Badge>
          </IconButton>

          <IconButton
            color="primary"
            className={classes.iconButton}
            disableFocusRipple
            disableRipple
            onClick={handleClick}
          >
            <Avatar alt="A" color="primary" className={classes.avatar} />
          </IconButton>
        </Box>
      </Toolbar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ list: classes.menuList }}
      >
        <MenuItem onClick={handleLogoutClick} className={classes.logoutItem}>
          <Logout />
          <Typography variant={"subtitle2"}>{t("logout:title")}</Typography>
        </MenuItem>
      </Menu>

      <Popover
        open={Boolean(popperAnchorEl)}
        anchorEl={popperAnchorEl}
        onClose={handleCloseNotification}
        disablePortal
        disableEnforceFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {notifications?.pages.map(page =>
            page.totalElements ? (
              page.data.map(notification => (
                <Fragment key={notification.notificationId}>
                  <Notification notification={notification} />
                  <Divider variant="middle" component="li" />
                </Fragment>
              ))
            ) : (
              <Box padding={2} key={page.currentPage}>
                {t("notifications:noNotifications")}
              </Box>
            )
          )}

          {hasNextPage && (
            <Typography textAlign={"center"} onClick={handleLoadMoreClick}>
              {t("notifications:showMore")}
            </Typography>
          )}
        </List>
      </Popover>
    </AppBar>
  );
};

export default Navbar;
