import { Path } from "react-hook-form";

import { TEXT_REGEX } from "../../../constants";
import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { CommitmentOptions } from "../../../types/main";
import { Requirement } from "../../../types/requirements";

export const formConfig: FormConstructor<Requirement> = {
  global: {
    watchedField: "commitment" as Path<Requirement>,
    watchedFieldValue: CommitmentOptions.future,
  },
  fields: [
    {
      name: "title",
      label: "main:newRequirement.jobTitle",
      validationOptions: {
        required: true,
        pattern: {
          pattern: TEXT_REGEX,
          label: "main:newRequirement.errors.invalidJobTitle",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "managerId",
      label: "main:newRequirement.manager",
      validationOptions: {
        required: true,
      },
      type: FieldType.select,
      isFullWidth: true,
      defaultValue: "",
      options: [],
      isPrivate: true,
    },
    {
      name: "skillIds",
      label: "main:newRequirement.skills",
      validationOptions: {
        required: true,
      },
      type: FieldType.selectMultiple,
      defaultValue: [],
      options: [],
    },
    {
      name: "commitment",
      label: "main:newRequirement.selectCommitment.title",
      validationOptions: {
        required: true,
      },
      type: FieldType.radio,
      defaultValue: "Immediately",
      options: [],
    },
    {
      name: "futureCommitment",
      label: "main:newRequirement.selectCommitment.needAfter",
      validationOptions: {
        required: true,
      },
      type: FieldType.select,
      isWatched: true,
      defaultValue: "",
      options: [],
    },
  ],
};
