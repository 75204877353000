import { ChangeEvent, FC } from "react";

import { Checkbox } from "@mui/material";
import shallow from "zustand/shallow";

import { ConsultantsState, useConsultantsStore } from "../../store/useConsultantsStore";
import { ConsultantsData } from "../../types/users";

interface Props {
  data: ConsultantsData;
  selected: boolean;
  consultantsData?: ConsultantsData[];
}

const consultantsDataSelector = (state: ConsultantsState) => ({
  selectedConsultants: state.selectedConsultants,
  addSelectedConsultants: state.addSelected,
  addLastSelectedConsultant: state.addLastSelectedConsultant,
  resetLastSelectedConsultant: state.resetLastSelectedConsultant,
});

const ConsultantCheckbox: FC<Props> = ({ selected, data, consultantsData }) => {
  const { addLastSelectedConsultant, addSelectedConsultants, resetLastSelectedConsultant, selectedConsultants } =
    useConsultantsStore(consultantsDataSelector, shallow);

  const handleSelectRow = (data: ConsultantsData) => {
    addSelectedConsultants([...selectedConsultants, data.id]);
    addLastSelectedConsultant(data.id);
  };

  const handleUnselectRow = (data: ConsultantsData) => {
    const newSelected = selectedConsultants.filter(el => el !== data.id);
    addSelectedConsultants(newSelected);

    if (newSelected.length) {
      const found = consultantsData?.find(el => el.id === newSelected[newSelected.length - 1]);
      addLastSelectedConsultant(found!.id);
      return;
    }
    resetLastSelectedConsultant();
  };

  const handleSelected = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      handleSelectRow(data);
      return;
    }

    handleUnselectRow(data);
  };

  return <Checkbox color="primary" checked={selected} onChange={handleSelected} />;
};

export default ConsultantCheckbox;
