import { FC } from "react";

import dayjs, { Dayjs } from "dayjs";
import { ResourceKey } from "i18next";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AutocompleteField,
  CheckboxField,
  CustomDatePicker,
  FileField,
  RadioGroup,
  SelectField,
  TextField,
  TimePicker,
} from "..";
import { DATEPICKER_FORMAT } from "../../constants";
import {
  CustomValidationOptions,
  FormConstructorTypes,
  FormFieldProps,
  FormFieldValidations,
} from "../../types/default";
import { FieldType } from "../../types/login";

const FormField: FC<FormConstructorTypes> = ({
  label,
  name,
  validationOptions,
  type,
  options,
  defaultValue,
  isFullWidth,
  multiline,
  disabledField,
  hasEndAdornment,
  endAdornment,
  showPreviousUploadedFile,
  disablePastDate,
  fileType,
  isFreeText,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const getValidationOptions = (object?: CustomValidationOptions) => {
    const returnedObject: RegisterOptions = {};

    if (object) {
      for (const [key, value] of Object.entries(object)) {
        if (key === FormFieldValidations.required) {
          Object.assign(returnedObject, {
            required: `${t(label as ResourceKey)} ${t("formFields:errors.required")}`,
          });
        }
        if (key === FormFieldValidations.pattern) {
          Object.assign(returnedObject, {
            pattern: {
              value: value.pattern,
              message: t(value.label as ResourceKey),
            },
          });
        }
        if (key === FormFieldValidations.validate) {
          Object.assign(returnedObject, {
            validate: (fieldValue: string) =>
              fieldValue.trim().length < value.minLength ? t(value.label as ResourceKey) : undefined,
          });
        }
        if (key === FormFieldValidations.validateDate) {
          Object.assign(returnedObject, {
            validate: (fieldValue: Dayjs) =>
              !dayjs(fieldValue).isValid() || dayjs(fieldValue).isBefore(dayjs().format(DATEPICKER_FORMAT))
                ? t(value.label as ResourceKey)
                : undefined,
          });
        }
      }
    }

    return returnedObject;
  };

  getValidationOptions(validationOptions);

  const getFieldType = ({ field, fieldState }: FormFieldProps): JSX.Element => {
    const formFieldProps = { field, fieldState };

    switch (type) {
      case FieldType.checkbox:
        return <CheckboxField label={label} formFieldProps={formFieldProps} />;

      case FieldType.text:
      case FieldType.number:
      case FieldType.password:
        return (
          <TextField
            label={label}
            formFieldProps={formFieldProps}
            type={type}
            multiline={multiline}
            disabledField={disabledField}
            hasEndAdornment={hasEndAdornment}
            endAdornment={endAdornment}
            isFreeText={isFreeText}
          />
        );

      case FieldType.select:
        return (
          <SelectField
            label={label}
            formFieldProps={formFieldProps}
            options={options || []}
            isFullWidth={isFullWidth}
            disabledField={disabledField}
          />
        );

      case FieldType.selectMultiple:
        return <AutocompleteField label={label} formFieldProps={formFieldProps} options={options || []} />;
      case FieldType.radio:
        return <RadioGroup label={label} formFieldProps={formFieldProps} options={options || []} />;
      case FieldType.date:
        return <CustomDatePicker label={label} formFieldProps={formFieldProps} disablePastDate={disablePastDate} />;
      case FieldType.time:
        return <TimePicker label={label} formFieldProps={formFieldProps} />;
      case FieldType.file:
        return (
          <FileField
            label={label}
            formFieldProps={formFieldProps}
            showPreviousUploadedFile={showPreviousUploadedFile}
            fileType={fileType}
          />
        );

      default:
        return (
          <TextField
            label={label}
            formFieldProps={formFieldProps}
            type={type}
            multiline={multiline}
            disabledField={disabledField}
            hasEndAdornment={hasEndAdornment}
            endAdornment={endAdornment}
          />
        );
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={getValidationOptions(validationOptions)}
      render={getFieldType}
    />
  );
};

export default FormField;
