import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import companyService from "../../services/companies";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { CompaniesData } from "../../types/companies";
import { ErrorContent, PaginatedData } from "../../types/default";
import { UserRoles } from "../../types/users";

interface ReturnType {
  isLoading: boolean;
  companiesData?: PaginatedData<CompaniesData>;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;
const currentUserDataSelector = (state: UserState) => ({
  userRole: state.role,
});

export const useAllCompanies = (
  page?: number,
  perPage?: number,
  handleChangePage?: (newPage: number) => void
): ReturnType => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const { userRole } = useUserStore(currentUserDataSelector);

  const { isLoading, data: companiesData } = useQuery(
    [QUERY_STRINGS.GET_COMPANIES, page, perPage],
    () => companyService.getAllCompanies(page, perPage),
    {
      enabled: userRole === UserRoles.superadmin,
      keepPreviousData: true,
      onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
      onSuccess: data =>
        !data.data.length && data.totalElements && page && handleChangePage && handleChangePage(page - 1),
    }
  );

  return { isLoading, companiesData };
};
