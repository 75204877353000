import { FC } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { FilterMenu } from "..";
import { UserState, useUserStore } from "../../store/useUserStore";
import { FieldBaseTypes, PaginatedData, SelectConfig, SelectOptionsType } from "../../types/default";
import { ConsultantsFilters, FilterOptions } from "../../types/main";
import { RequirementData } from "../../types/requirements";
import { UserRoles } from "../../types/users";

interface Props {
  filterOptions?: FilterOptions;
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptions | undefined>>;
  requirementsData?: PaginatedData<RequirementData>;
  skillsData?: SelectOptionsType[];
}

const userRoleSelector = (state: UserState) => state.role;

const Filters: FC<Props> = ({ filterOptions, setFilterOptions, requirementsData, skillsData }) => {
  const { t } = useTranslation();
  const userRole = useUserStore(userRoleSelector);

  const requirementsDataMapped = requirementsData?.data.map(requirement => ({
    id: requirement.id,
    name: userRole === UserRoles.superadmin ? `${requirement.title} - ${requirement.managerName}` : requirement.title,
  }));

  const updateStringFilterValue = (key: string) => (event: SelectChangeEvent<string>) =>
    setFilterOptions({ ...filterOptions, [key]: event.target.value });

  const updateCheckboxFilterValue = (key: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
    setFilterOptions({ ...filterOptions, [key]: checked });

  const checkBoxConfig: FieldBaseTypes[] = [
    { name: ConsultantsFilters.interviewed, label: t("consultants:filters.interviewedConsultants") },
    { name: ConsultantsFilters.questionaireAnswered, label: t("consultants:filters.answeredQuestionaire") },
  ];

  const selectOptionsConfig: SelectConfig[] = [
    {
      label: t("consultants:filters.order"),
      value: filterOptions?.projectOrder || "",
      defaultValue: "",
      name: ConsultantsFilters.projectOrder,
      options: requirementsDataMapped ?? [],
    },
    {
      label: t("consultants:filters.skill.title"),
      value: filterOptions?.skill || "",
      defaultValue: "",
      name: ConsultantsFilters.skill,
      options: skillsData ?? [],
    },
    {
      label: t("consultants:filters.availability.title"),
      value: filterOptions?.availability || "",
      defaultValue: "",
      name: ConsultantsFilters.availability,
      options: [
        { id: "1", name: t("consultants:filters.availability.list.oneMonth") },
        { id: "3", name: t("consultants:filters.availability.list.threeMonths") },
        { id: "6", name: t("consultants:filters.availability.list.sixMonths") },
      ],
    },
  ];
  return (
    <>
      <Grid container justifyContent={"space-between"} spacing={2}>
        {selectOptionsConfig.map((option, index) => (
          <Grid item xs={index === 0 ? 5 : 3} key={option.name}>
            <FormControl fullWidth>
              <InputLabel>{option.label}</InputLabel>
              <Select value={option.value} label={option.label} onChange={updateStringFilterValue(option.name)}>
                {option.options.map(el => (
                  <MenuItem value={el.id} key={el.id}>
                    <Typography variant={"subtitle1"} color={"textPrimary"}>
                      {el.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))}

        <Grid item xs={1}>
          <FilterMenu filterOptions={filterOptions} setFilterOptions={setFilterOptions} />
        </Grid>
      </Grid>

      <Grid container gap={1} paddingTop={2}>
        {checkBoxConfig.map(checkbox => (
          <Grid item key={checkbox.name}>
            <FormControlLabel
              control={<Checkbox name={checkbox.name} onChange={updateCheckboxFilterValue(checkbox.name)} />}
              label={
                <Typography variant={"subtitle1"} color={"textSecondary"}>
                  {checkbox.label}
                </Typography>
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Filters;
