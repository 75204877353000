import { FC } from "react";

import { Add, Check, ListOutlined } from "@mui/icons-material";
import { MenuItem, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { FLAGGED_ID } from "../../constants";
import { DropdownListConfig } from "../../types/rightSidebar";
import { ConsultantsData } from "../../types/users";

const useStyles = makeStyles()(theme => ({
  firstMenuItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  dropdownTitle: {
    paddingRight: theme.spacing(5),
  },
  createSublist: {
    paddingLeft: theme.spacing(2.5),
  },
}));

interface Props {
  item: DropdownListConfig;
  consultantData: ConsultantsData;
}

const DropdownMenuItem: FC<Props> = ({ item, consultantData }) => {
  const { classes } = useStyles();
  const selectedConsultant = consultantData.sublistDetails.find(el => el.id === item.id);

  return (
    <MenuItem
      key={item.name}
      onClick={selectedConsultant || (consultantData.isFlagged && item.id === FLAGGED_ID) ? item.remove : item.add}
      divider={item.hasDivider}
      className={classes.firstMenuItem}
      selected={!!selectedConsultant || (consultantData.isFlagged && item.id === FLAGGED_ID)}
    >
      {item.startIcon && <Add />}
      <Typography variant={"subtitle1"} color={"textPrimary"} className={classes.dropdownTitle}>
        {item.name}
      </Typography>
      {item.endIcon && <ListOutlined />}
      {(!!selectedConsultant || (consultantData.isFlagged && item.id === FLAGGED_ID)) && (
        <Check fontSize={"medium"} color={"action"} />
      )}
    </MenuItem>
  );
};

export default DropdownMenuItem;
