export enum MixpanelEvents {
  LOGGED_IN = "Logged in",
  FLAG_CV = "Flag CV",
  UNFLAG_CV = "Unflag CV",
  CREATE_REQUIREMENT = "Create requirement",
  CREATE_MANAGER_JOB_REQUIREMENT = "Create manager requirement",
  EDIT_REQUIREMENT = "Edit requirement",
  CREATE_SKILL = "Create skill",
  USER_ADDED_TO_SUBLIST = "User added to sublist",
  USER_REMOVED_FROM_SUBLIST = "User removed from sublist",
  FULL_CV_VIEW = "Full CV view",
  ACTIVATE_JOB_ORDER = "Activate job order",
}
