export enum FieldType {
  text = "text",
  password = "password",
  checkbox = "checkbox",
  select = "select",
  selectMultiple = "selectMultiple",
  radio = "radio",
  date = "date",
  time = "time",
  number = "number",
  file = "file",
}
