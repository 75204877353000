import { FC, useContext } from "react";

import { Box, Button, Link as MaterialLink, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { FormField } from "..";
import { PATHS } from "../../constants";
import { AuthContext } from "../../contexts/AuthContext";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import { LoginTypes } from "../../types/authentication";
import { FieldType } from "../../types/login";
import { loginFormConfig } from "../forms/builders/loginBuilder";

const useStyles = makeStyles()(theme => ({
  form: {
    width: "328px",
  },
  loginButton: {
    marginTop: theme.spacing(4.25),
    marginBottom: theme.spacing(2),
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const LoginForm: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const methods = useForm<LoginTypes>({ mode: "onChange" });

  const { login } = useContext(AuthContext);
  const { requestPermissions } = useContext(FirebaseContext);

  const handleButtonLogin = (inputs: LoginTypes) => {
    login(inputs);
    requestPermissions();
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" noValidate className={classes.form} onSubmit={methods.handleSubmit(handleButtonLogin)}>
        {loginFormConfig.fields.map(field => (
          <Box key={field.name} className={classes.checkboxContainer}>
            <FormField {...field} />
            {field.type === FieldType.checkbox && (
              <MaterialLink component={Link} to={PATHS.forgottenPassword} underline="none">
                <Typography variant={"caption"} fontSize={"12px"}>
                  {t("login:form.forgottenPassword")}
                </Typography>
              </MaterialLink>
            )}
          </Box>
        ))}

        <Button type="submit" fullWidth variant="contained" color={"primary"} className={classes.loginButton}>
          <Typography variant={"button"}>{t("login:title")}</Typography>
        </Button>
      </Box>
    </FormProvider>
  );
};

export default LoginForm;
