import { FC } from "react";

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { BaseFormFieldProps, SelectOptionsType } from "../../types/default";

interface Props extends BaseFormFieldProps {
  options: SelectOptionsType[];
  isFullWidth?: boolean;
  disabledField?: boolean;
}

const useStyles = makeStyles<{ isFullWidth?: boolean }>()((theme, { isFullWidth }) => ({
  container: {
    width: isFullWidth ? "100%" : "50%",
  },
}));

const SelectField: FC<Props> = ({ formFieldProps, options, label, isFullWidth, disabledField }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ isFullWidth });

  const { value, name, ...rest } = formFieldProps.field;
  const { error } = formFieldProps.fieldState;

  return (
    <FormControl fullWidth={isFullWidth} margin="normal" className={classes.container}>
      <InputLabel>{t(label as ResourceKey)}</InputLabel>
      <Select
        {...rest}
        fullWidth
        error={Boolean(error && error.message)}
        name={name}
        label={t(label as ResourceKey)}
        value={value ?? []}
        disabled={disabledField}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && error.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;
