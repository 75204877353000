import { FC } from "react";

import { Link } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

import { DeletePopup, EmptyState, ModalContainer, TableActions } from "..";
import { useDeleteUpcomingCV } from "../../hooks/upcomingCVs/useDeleteUpcomingCV";
import { useUpcomingCVs } from "../../hooks/upcomingCVs/useUpcomingCVs";
import { useChangePage } from "../../hooks/useChangePage";
import { useDeleteItem } from "../../hooks/useDeleteItem";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { UpcomingCVsData } from "../../types/upcomingCVs";

const UpcomingCVsTable: FC = () => {
  const { t } = useTranslation();

  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();
  const { isLoading: isDeletingUpcomingCV, deleteUpcomingCV } = useDeleteUpcomingCV();
  const { itemToDelete, handleCloseModal, handleDeleteIconClick, handleDeleteItem } = useDeleteItem(deleteUpcomingCV);
  const { upcomingCVsData, isLoading } = useUpcomingCVs(page, perPage, handleChangePage);

  const tableBuilder: Column<UpcomingCVsData> = {
    columns: [
      {
        dataKey: "manager",
        label: t("upcomingCVs:fields.managerName"),
        dataRender: data => data.manager.name,
        sortable: false,
        private: true,
      },
      {
        dataKey: "fileName",
        label: t("upcomingCVs:fields.fileName"),
        dataRender: data => data.fileName,
        sortable: false,
      },
      {
        dataKey: "filePath",
        label: t("upcomingCVs:fields.filePath"),
        dataRender: data => (
          <Link underline={"none"} href={data.url} target={"_blank"} rel={"noopener noreferrer"}>
            {t("upcomingCVs:viewCV")}
          </Link>
        ),
        sortable: false,
      },
      {
        dataKey: "actions",
        label: undefined,
        dataRender: data => <TableActions deleteClick={handleDeleteIconClick(data.id)} />,
        sortable: false,
        private: true,
      },
    ],
  };

  if (isLoading || !upcomingCVsData || isDeletingUpcomingCV) {
    return <Loading />;
  }

  if (!upcomingCVsData.totalElements) {
    return <EmptyState text={t("emptyState:texts.upcomingCVs")} />;
  }

  return (
    <Box>
      <CustomTable
        tableBuilder={tableBuilder}
        data={upcomingCVsData.data}
        page={page}
        perPage={perPage}
        totalElements={upcomingCVsData.totalElements}
        showPagination
        showRowsPerPage
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
      />
      <ModalContainer
        isOpened={!!itemToDelete}
        title={t("deletePopup:title", { label: t("upcomingCVs:upcomingCV") })}
        closeModal={handleCloseModal}
        size={"md"}
      >
        <DeletePopup
          label={t("upcomingCVs:upcomingCV")}
          handleDeleteAction={handleDeleteItem}
          cancelDeleteAction={handleCloseModal}
        />
      </ModalContainer>
    </Box>
  );
};

export default UpcomingCVsTable;
