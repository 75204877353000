import { ReactNode, useState } from "react";

import { Add } from "@mui/icons-material";
import { Breakpoint, Button, Grid, Typography } from "@mui/material";

import { ModalContainer } from "..";
import { FormConstructor } from "../../types/default";
import FormConsumer from "../forms/FormConsumer";

interface Props<T> {
  title?: string;
  btnLabel?: string;
  modalTitle: string;
  formConfig: FormConstructor<T>;
  modalBtnTitle: string;
  btnGapLarge?: boolean;
  size: Breakpoint;
  handleSubmitForm: (data: T) => void;
  isEditMode?: boolean;
  closeEditMode?: () => void;
  children?: ReactNode;
  showRequestScreen?: boolean;
}

function CardHeader<T>({
  title,
  btnLabel,
  modalTitle,
  formConfig,
  modalBtnTitle,
  btnGapLarge,
  size,
  handleSubmitForm,
  isEditMode,
  closeEditMode,
  children,
}: Props<T>) {
  const [openModal, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);

  const handleCloseModal = () => {
    closeEditMode && closeEditMode();
    setModalOpen(false);
  };

  return (
    <Grid container>
      <Grid item xs={6} display={"flex"} alignItems={"center"}>
        {title && (
          <Typography variant={"h2"} color={"textPrimary"}>
            {title}
          </Typography>
        )}
      </Grid>

      <Grid item xs={6} textAlign={"end"} display={"flex"} justifyContent={"flex-end"} gap={1}>
        {children}
        {btnLabel && (
          <Button variant={"contained"} color={"primary"} startIcon={<Add />} onClick={handleOpenModal}>
            <Typography variant={"button"}>{btnLabel}</Typography>
          </Button>
        )}
      </Grid>

      <ModalContainer isOpened={openModal || !!isEditMode} title={modalTitle} closeModal={handleCloseModal} size={size}>
        <FormConsumer<T>
          closeModal={handleCloseModal}
          btnLabel={modalBtnTitle}
          formConfig={formConfig}
          btnGapLarge={btnGapLarge}
          handleSubmitForm={handleSubmitForm}
        />
      </ModalContainer>
    </Grid>
  );
}

export default CardHeader;
