import kommlinkApi, { BackendError } from "../lib/axios";
import { PaginatedData } from "../types/default";
import { QuestionsBase, QuestionsBody, QuestionsData } from "../types/questions";
import filesService from "./files";

const createQuestionaire = async (data: QuestionsBody): Promise<void> => {
  try {
    const { consultant, date, sentQuestions, answers } = data;
    const sentFileId = await filesService.uploadFile(sentQuestions);

    const answeredFileId = answers && (await filesService.uploadFile(answers));

    await kommlinkApi.post<void>("/superadmin/questionnaire", {
      consultantId: consultant,
      date,
      sentFileId,
      answeredFileId: answeredFileId ? answeredFileId : undefined,
    });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editQuestionaire = async ({ id, data }: { id: string; data: QuestionsBase }): Promise<void> => {
  try {
    const { date, answers } = data;

    const answeredFileId = await filesService.uploadFile(answers);

    await kommlinkApi.put<void>("/superadmin/questionnaire", {
      date,
      answeredFileId: answeredFileId ? answeredFileId : undefined,
      questionnaireId: id,
    });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getQuestionaires = async (page: number, perPage: number): Promise<PaginatedData<QuestionsData>> => {
  try {
    const { data } = await kommlinkApi.get<PaginatedData<QuestionsData>>("/users/me/questionnaire", {
      params: {
        page,
        perPage,
      },
    });

    return data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const questionsService = {
  createQuestionaire,
  editQuestionaire,
  getQuestionaires,
};

export default questionsService;
