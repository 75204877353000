import { FC, useState } from "react";

import { Box, Typography } from "@mui/material";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { FullCvDialog } from "..";
import { ReactComponent as AnsweredQuestionaire } from "../../assets/answered-questionaire.svg";
import { ReactComponent as CvIcon } from "../../assets/cv-icon.svg";
import { ReactComponent as InterviewedConsultant } from "../../assets/interviewed-consultant.svg";
import { ReactComponent as SendQuestions } from "../../assets/send-questions.svg";
import { PATHS } from "../../constants";
import { UserHistoryFields } from "../../types/rightSidebar";
import { ConsultantsData } from "../../types/users";

const useStyles = makeStyles()(theme => ({
  fieldContainer: {
    display: "flex",
    justifyContent: "start",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primaryWithOpacity.light,
    width: "100%",
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(0.75),
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primaryWithOpacity.dark,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2.5),
  },
  viewBtn: {
    cursor: "pointer",
  },
}));

interface Props {
  consultant: ConsultantsData;
}

const handleIsInterview = (value: boolean) => {
  if (value) {
    return "upcomingInterviews:interviewedValues.yes";
  }
  return "upcomingInterviews:interviewedValues.no";
};

const History: FC<Props> = ({ consultant }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [fullCvOpen, setFullCvOpen] = useState(false);

  const handleOpenFullCv = () => {
    //Dont want to delete it for now
    setFullCvOpen(true);
  };

  const handleRedirectToQuestions = () => navigate(PATHS.sentQuestions);

  const handleCloseFullCv = () => setFullCvOpen(false);

  const historyFieldsConfig: UserHistoryFields[] = [
    {
      label: "userProfile:history.buttons.answeredQuestionaire",
      icon: <AnsweredQuestionaire />,
      action: handleRedirectToQuestions,
      actionText: "userProfile:history.view",
    },
    {
      label: "userProfile:history.buttons.consultantCv",
      icon: <CvIcon />,
      action: handleOpenFullCv,
      actionText: "userProfile:history.view",
    },
    {
      label: "userProfile:history.buttons.interviewedConsultant",
      icon: <InterviewedConsultant />,
      actionText: handleIsInterview(consultant.isInterviewed),
    },
    {
      label: "userProfile:history.buttons.sentQuestionsBtn",
      icon: <SendQuestions />,
      action: handleRedirectToQuestions,
      actionText: "userProfile:history.view",
      separated: true,
    },
  ];

  return (
    <>
      <Typography variant={"subtitle1"} color={"textSecondary"} marginBottom={2}>
        {t("userProfile:history.title")}
      </Typography>

      {historyFieldsConfig.map((field, index) => (
        <Box key={index}>
          {field.separated && (
            <Typography variant={"subtitle1"} color={"textSecondary"} marginBottom={2} marginTop={4}>
              {t("userProfile:history.sentQuestions")}
            </Typography>
          )}

          <Box className={classes.fieldContainer}>
            <Box className={classes.iconContainer}>{field.icon}</Box>

            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
              <Typography variant={"subtitle1"} color={"textSecondary"}>
                {t(field.label as ResourceKey)}
              </Typography>

              <Box>
                <Typography
                  variant={"button"}
                  color={"primary"}
                  className={field.action && classes.viewBtn}
                  onClick={field.action}
                >
                  {t(field.actionText as ResourceKey)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
      <FullCvDialog fullCvOpen={fullCvOpen} handleCloseFullCv={handleCloseFullCv} filePath={consultant.recentlyCVs} />
    </>
  );
};

export default History;
