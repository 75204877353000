import { TOKEN } from "../constants";

const addWebStorage = (local = false) => {
  if (local) {
    localStorage.setItem(TOKEN, "remembered");
  }

  sessionStorage.setItem(TOKEN, "temporary");
};

const removeWebStorage = () => {
  localStorage.removeItem(TOKEN);
  sessionStorage.removeItem(TOKEN);
};

const webStorageExists = (): string | null => sessionStorage.getItem(TOKEN) || localStorage.getItem(TOKEN);

export { addWebStorage, removeWebStorage, webStorageExists };
