import { useTranslation } from "react-i18next";

import { SelectOptionsType } from "../../types/default";
import { RequirementCommitmentMonths } from "../../types/requirements";

export const useRequirementFieldOptions = () => {
  const { t } = useTranslation();

  const commitmentOptions: SelectOptionsType[] = [
    { id: "1", name: t("main:newRequirement.selectCommitment.options.immediately") },
    { id: "2", name: t("main:newRequirement.selectCommitment.options.future") },
  ];
  const optionsMonths: SelectOptionsType[] = [
    { id: RequirementCommitmentMonths.ONE, name: t("main:newRequirement.selectCommitment.commitmentMonths.oneMonth") },
    { id: RequirementCommitmentMonths.TWO, name: t("main:newRequirement.selectCommitment.commitmentMonths.twoMonths") },
    {
      id: RequirementCommitmentMonths.THREE,
      name: t("main:newRequirement.selectCommitment.commitmentMonths.threeMonths"),
    },
  ];

  return { commitmentOptions, optionsMonths };
};
