import { FC } from "react";

import { Box, CircularProgress } from "@mui/material";

const Loading: FC = () => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
