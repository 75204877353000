import { FC, useState } from "react";

import { Box, ClickAwayListener, Popper } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import { AvatarCell, ConsultantOverviewPopperContent, EmptyState } from "..";
import { DATE_FORMAT, DEFAULT_PAGE, DEFAULT_PER_PAGE, PATHS } from "../../constants";
import { getAvailabilityBorderColor } from "../../functions";
import useSort from "../../hooks/useSort";
import CustomTable, { Column } from "../../layouts/CustomTable";
import { ConsultantsState, useConsultantsStore } from "../../store/useConsultantsStore";
import { UserState, useUserStore } from "../../store/useUserStore";
import { PaginatedData, SortOrder } from "../../types/default";
import { ConsultantsData, UserRoles } from "../../types/users";

const consultantsDataSelector = (state: ConsultantsState) => ({
  addSelectedConsultant: state.addSelected,
  addLastSelectedConsultant: state.addLastSelectedConsultant,
});

const userRoleSelector = (state: UserState) => state.role;

interface Props {
  consultantsData?: PaginatedData<ConsultantsData>;
}

const OverviewTable: FC<Props> = ({ consultantsData }) => {
  const { order, sortBy, handleSortClick } = useSort<ConsultantsData>(SortOrder.ASC, "dailyRate");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { addLastSelectedConsultant, addSelectedConsultant } = useConsultantsStore(consultantsDataSelector, shallow);

  const role = useUserStore(userRoleSelector);

  const [anchorEl, setAnchorEl] = useState<null | undefined | HTMLElement>(null);
  const [currentConsultantData, setCurrentConsultantData] = useState<null | ConsultantsData>(null);

  const isSuperAdmin = role === UserRoles.superadmin;

  const handleRowHover = (data: ConsultantsData) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    setCurrentConsultantData(data);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setCurrentConsultantData(null);
  };

  const tableBuilder: Column<ConsultantsData> = {
    rowStyles: data => getAvailabilityBorderColor(Number(data.interviewAvailability)),
    rowClickFn: data => () => {
      navigate(PATHS.consultants);
      addSelectedConsultant([data.id]);
      addLastSelectedConsultant(data.id);
    },
    columns: [
      {
        dataKey: "name",
        label: t("main:tableHeaders.name"),
        dataRender: data => (
          <AvatarCell
            imageUrl={data.imageUrl}
            firstName={data.firstName}
            lastName={data.lastName}
            onHover={handleRowHover(data)}
          />
        ),
        sortable: false,
      },
      {
        dataKey: "manager",
        label: isSuperAdmin ? t("main:tableHeaders.manager") : undefined,
        dataRender: data => isSuperAdmin && data.managerDetails.map(manager => manager.name).toString(),
        sortable: false,
      },
      {
        dataKey: "dailyRate",
        label: t("main:tableHeaders.dailyRate"),
        dataRender: data => data.dailyRate,
        sortable: true,
      },
      {
        dataKey: "jobAvailability",
        label: t("main:tableHeaders.availability"),
        dataRender: data => dayjs(data.jobAvailability).format(DATE_FORMAT),
        sortable: true,
      },
    ],
  };

  if (!consultantsData?.totalElements) {
    return <EmptyState text={t("main:consultantsEmptyState")} />;
  }

  return (
    <Box>
      <CustomTable
        tableBuilder={tableBuilder}
        data={consultantsData.data}
        page={DEFAULT_PAGE}
        perPage={DEFAULT_PER_PAGE}
        totalElements={10}
        order={order}
        sortBy={sortBy}
        handleSortClick={handleSortClick}
        showPagination={false}
      />
      {currentConsultantData && (
        <ClickAwayListener onClickAway={handleClosePopover}>
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="top-start"
            onMouseLeave={handleClosePopover}
            disablePortal={false}
            sx={{ zIndex: 2 }}
          >
            <ConsultantOverviewPopperContent data={currentConsultantData} />
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default OverviewTable;
