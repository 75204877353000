import { FC, useState } from "react";

import { Box, Chip, ListItem, ListItemText, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { useMarkViewedNotifications } from "../hooks/notifications/useMarkViewedNotifications";
import { NotificationContent } from "../types/notifications";

const useStyles = makeStyles()(theme => ({
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

interface Props {
  notification: NotificationContent;
}

const Notification: FC<Props> = ({ notification }) => {
  const { notificationId, isViewed, message, senderName, receivedDate } = notification;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { markViewedNotification } = useMarkViewedNotifications();

  const [isNotificationViewed, setNotificationViewed] = useState(isViewed);

  const handleMarkNotificationViewed = () => {
    if (!isNotificationViewed) {
      setNotificationViewed(true);
      markViewedNotification(notificationId);
    }
  };

  const notificationTime = dayjs(receivedDate);
  const currentTime = dayjs();

  const getReceivedDate = () => {
    const months = currentTime.diff(notificationTime, "months");
    const days = currentTime.diff(notificationTime, "days");
    const hours = currentTime.diff(notificationTime, "hours");
    const minutes = currentTime.diff(notificationTime, "minutes");

    if (months) {
      return t("notifications:receivedTime.months", { months });
    }

    if (days) {
      return t("notifications:receivedTime.days", { days });
    }

    if (hours) {
      return t("notifications:receivedTime.hours", { hours });
    }

    return t("notifications:receivedTime.minutes", { minutes });
  };

  return (
    <>
      <ListItem onClick={handleMarkNotificationViewed}>
        <ListItemText
          primary={
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Box display={"flex"} gap={1}>
                {senderName}
                {!isNotificationViewed && (
                  <Chip label={t("notifications:labelNew")} size={"small"} className={classes.chip} />
                )}
              </Box>
              <Typography variant={"caption"} color={"textSecondary"} fontWeight={300} marginLeft={5}>
                {getReceivedDate()}
              </Typography>
            </Box>
          }
          primaryTypographyProps={{ variant: "subtitle1", color: "textPrimary", fontWeight: 600 }}
          secondary={message}
          secondaryTypographyProps={{
            variant: "caption",
            color: !isNotificationViewed ? "textPrimary" : "textSecondary",
            fontWeight: !isNotificationViewed ? 500 : 300,
            paddingTop: 0.5,
          }}
        />
      </ListItem>
    </>
  );
};

export default Notification;
