import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useSendQuestionaire = () => {
  const { t } = useTranslation();
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { mutate: sendQuestionaire, isLoading } = useMutation(consultantsService.sendQuestionaire, {
    mutationKey: QUERY_STRINGS.SEND_QUESTIONAIRE,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => addSnackbar({ type: "success", text: t("userProfile:actions.sendQuestions.questionsSent") }),
  });

  return { sendQuestionaire, isLoading };
};
