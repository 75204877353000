import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useEditConsultant = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: editConsultant, isLoading } = useMutation(consultantsService.editConsultant, {
    mutationKey: QUERY_STRINGS.EDIT_CONSULTANT,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_CONSULTANTS),
  });

  return { editConsultant, isLoading };
};
