import { FC } from "react";

import { Close } from "@mui/icons-material";
import { Box, Dialog } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface Props {
  fullCvOpen: boolean;
  handleCloseFullCv: () => void;
  filePath: string;
}

const useStyles = makeStyles()(theme => ({
  fullCvHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 1, 0.5, 0.5),
  },
  iframeContainer: {
    height: "70vh",
    width: "100%",
  },
}));

const FullCvDialog: FC<Props> = ({ fullCvOpen, handleCloseFullCv, filePath }) => {
  const { classes } = useStyles();

  return (
    <>
      {filePath && (
        <Dialog open={fullCvOpen} maxWidth={"xl"}>
          <Box className={classes.fullCvHeader}>
            <Close onClick={handleCloseFullCv} color={"primary"} cursor={"pointer"} />
          </Box>

          <Box component={"iframe"} src={filePath} className={classes.iframeContainer} />
        </Dialog>
      )}
    </>
  );
};

export default FullCvDialog;
