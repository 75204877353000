import { FC, useState } from "react";

import { Cancel, FilterList } from "@mui/icons-material";
import { Badge, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { ConsultantsSortValue } from "../../types/consultants";
import { OrderType, SortOrder } from "../../types/default";
import { FilterOptions } from "../../types/main";

interface Props {
  filterOptions?: FilterOptions;
  setFilterOptions: (option?: FilterOptions) => void;
}

interface MenuOptionConfig {
  label: string;
  disabled: boolean;
  children?: React.ReactNode;
  isSelected?: boolean;
  clickFn?: () => void;
}

const useStyles = makeStyles()(theme => ({
  filterBox: {
    height: "100%",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#979797",
    padding: theme.spacing(0.75),
    borderRadius: theme.spacing(0.5),
    ":hover": {
      borderColor: theme.palette.dark.darker,
    },
  },
  clearIcon: {
    marginLeft: theme.spacing(14),
    color: theme.palette.dark.main,
  },
  firstMenuItem: {
    borderBottomWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.dark.light,
  },
}));

const FilterMenu: FC<Props> = ({ filterOptions, setFilterOptions }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const activeFilters = filterOptions ? Object.values(filterOptions).filter(el => typeof el === "string").length : null;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClearOptions = () => () => {
    if (filterOptions && Object.values(filterOptions).length) {
      setFilterOptions(undefined);
      handleClose();
    }
  };

  const handleSortClick = (order: OrderType) => () => {
    setFilterOptions({ ...filterOptions, sortBy: ConsultantsSortValue.STARS.toString(), order });
    handleClose();
  };

  const menuOptionsConfig: MenuOptionConfig[] = [
    {
      label: t("consultants:filters.sorting.sortByStars"),
      disabled: true,
    },
    {
      label: t("consultants:filters.sorting.highToLow"),
      disabled: false,
      clickFn: handleSortClick(SortOrder.DESC),
      isSelected: filterOptions?.sortBy === ConsultantsSortValue.STARS && filterOptions.order === SortOrder.DESC,
    },
    {
      label: t("consultants:filters.sorting.lowToHigh"),
      disabled: false,
      clickFn: handleSortClick(SortOrder.ASC),
      isSelected: filterOptions?.sortBy === ConsultantsSortValue.STARS && filterOptions.order === SortOrder.ASC,
    },
    {
      label: t("consultants:filters.sorting.clear"),
      disabled: false,
      clickFn: handleClearOptions(),
      children: <Cancel className={classes.clearIcon} />,
    },
  ];

  return (
    <>
      <IconButton className={classes.filterBox} onClick={handleClick}>
        <Badge badgeContent={activeFilters} color={"error"}>
          <FilterList fontSize={"large"} color={"primary"} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptionsConfig.map((option, index) => (
          <MenuItem
            onClick={option.clickFn}
            disabled={option.disabled}
            selected={option.isSelected}
            key={option.label}
            className={index === 0 ? classes.firstMenuItem : undefined}
          >
            <Typography variant={"subtitle1"} color={"textPrimary"}>
              {option.label}
            </Typography>
            {option.children}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilterMenu;
