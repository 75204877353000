import { FC, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import {
  CardContentSection,
  CardHeader,
  ConsultantLists,
  ModalContainer,
  OverviewTable,
  RequirementRequest,
} from "../components";
import { formConfig } from "../components/forms/builders/newRequirementBuilder";
import { skillConfig } from "../components/forms/builders/skillBuilder";
import NewSkillButton from "../components/Main/NewSkillButton";
import { getCommitmentDateAsMilliseconds } from "../functions";
import { useRequirementFieldOptions } from "../hooks/requirements/useRequirementFieldOptions";
import { useHomeScreenDataFetch } from "../hooks/useHomeScreenDataFetch";
import Loading from "../layouts/Loading";
import { UsersState, useUsersStore } from "../store/useUsersStore";
import { UserState, useUserStore } from "../store/useUserStore";
import { ConsultantsSortValue } from "../types/consultants";
import {
  BaseEntityName,
  CardButtonTypes,
  CardTypes,
  FieldOptionNames,
  FormFieldsConstructor,
  SelectOptionsType,
  SortOrder,
} from "../types/default";
import { HomeScreenFilters } from "../types/main";
import { ManagerCreateRequirement, Requirement } from "../types/requirements";
import { UserRoles } from "../types/users";

const usersSelector = (state: UsersState) => state.resetUsersData;
const userSelector = (state: UserState) => ({
  userRole: state.role,
});

const Home: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userRole } = useUserStore(userSelector, shallow);
  const resetUsersData = useUsersStore(usersSelector);
  const { commitmentOptions, optionsMonths } = useRequirementFieldOptions();

  const [homeScreenFilters, setHomeScreenFilters] = useState<HomeScreenFilters | null>({ recentlyAdded: true });

  const [showRequestScreen, setShowRequestScreen] = useState(false);
  const [requirementData, setRequirementData] = useState<ManagerCreateRequirement | null>(null);

  const { isLoading, createSkill, skillsData, createJobRequirement, managersData, usersData } = useHomeScreenDataFetch(
    homeScreenFilters?.order,
    homeScreenFilters?.sortBy,
    homeScreenFilters?.currentRequirementId,
    homeScreenFilters?.recentlyAdded
  );

  const managersDataMapped: SelectOptionsType[] | undefined = managersData?.data.map(manager => ({
    id: manager.id,
    name: `${manager.firstName} ${manager.lastName}`,
  }));

  useEffect(() => {
    return () => {
      resetUsersData();
    };
  }, []);

  const handleOpenModal = () => setShowRequestScreen(true);

  const handleCloseModal = () => setShowRequestScreen(false);

  const handleSubmitForm = (data: Requirement) => {
    const commitmentDate = getCommitmentDateAsMilliseconds(Number(data.futureCommitment));
    const skillIds = data.skillIds.map(skill => skill.id);

    if (userRole === UserRoles.manager) {
      setRequirementData({ title: data.title, commitmentDate, skillIds });
      handleOpenModal();
      return;
    }

    createJobRequirement({ title: data.title, managerId: data.managerId, commitmentDate, skillIds });
  };

  const handleChangeOrder = (newOrder?: SortOrder | null, requirementId?: string) => () => {
    if (newOrder && requirementId) {
      setHomeScreenFilters({
        currentRequirementId: requirementId,
        order: newOrder,
        sortBy: ConsultantsSortValue.STARS,
      });

      return;
    }

    requirementId && setHomeScreenFilters({ currentRequirementId: requirementId, recentlyAdded: true });
  };

  const handleClickButton = (path: string, requirementId?: string) => path && navigate(path, { state: requirementId });

  const handleSubmitSkillForm = ({ name }: BaseEntityName) => createSkill(name);

  const cardButton: CardButtonTypes = {
    label: t("main:requirementCard.view"),
    action: handleClickButton,
  };

  const getContentTitle = (): string => {
    if (homeScreenFilters?.order === SortOrder.DESC) {
      return t("main:topFiveConsultants");
    }

    return t("main:recentlyAddedConsultants");
  };

  const getOptions = (field: FormFieldsConstructor<Requirement>): SelectOptionsType[] | undefined => {
    switch (field.name) {
      case FieldOptionNames.SKILL_IDS:
        return skillsData;
      case FieldOptionNames.MANAGER:
        return managersDataMapped;
      case FieldOptionNames.COMMITMENT:
        return commitmentOptions;
      case FieldOptionNames.FUTURE_COMMITMENT:
        return optionsMonths;
      default:
        return field.options;
    }
  };

  const addSkillFieldsWithData = formConfig.fields.map(field => ({
    ...field,
    options: getOptions(field),
  }));

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("main:description")} />
        <title>
          {t("sidebar:overview")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <CardHeader
        btnLabel={t("main:addRequirement")}
        modalTitle={t("main:newRequirement.header")}
        formConfig={{ ...formConfig, fields: addSkillFieldsWithData }}
        modalBtnTitle={t(
          userRole?.toString() === UserRoles.manager ? "main:newRequirement.next" : "main:newRequirement.btnText"
        )}
        btnGapLarge
        size={"lg"}
        handleSubmitForm={handleSubmitForm}
      >
        <NewSkillButton
          btnLabel={t("main:newSkill.addSkill")}
          modalTitle={t("main:newSkill.title")}
          formConfig={skillConfig}
          modalBtnTitle={t("main:newSkill.btnText")}
          size={"md"}
          handleSubmitForm={handleSubmitSkillForm}
        />
      </CardHeader>

      <ModalContainer
        isOpened={showRequestScreen}
        title={t("main:newRequirement.header")}
        closeModal={handleCloseModal}
        size={"lg"}
      >
        <RequirementRequest
          btnLabel={t("main:newRequirement.submit")}
          requirementData={requirementData}
          closeModal={handleCloseModal}
        />
      </ModalContainer>

      <ConsultantLists
        hasRequirements
        cardType={CardTypes.consultants}
        cardButton={cardButton}
        handleChangeOrder={handleChangeOrder}
        hasOptions={userRole === UserRoles.superadmin}
      />

      <CardContentSection
        title={getContentTitle()}
        table={<OverviewTable consultantsData={usersData} />}
        hasAvailabilityData={userRole?.toString() === UserRoles.manager}
      />
    </>
  );
};

export default Home;
