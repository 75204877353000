import { FC, MouseEvent } from "react";

import { Box, Avatar, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { getInitials } from "../../functions";

const useStyles = makeStyles()(theme => ({
  avatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2.5),
    backgroundColor: theme.palette.primary.main,
    fontWeight: 500,
    letterSpacing: "0.15px",
  },
}));

interface Props {
  firstName: string;
  lastName: string;
  imageUrl?: string;
  onHover?: (e: MouseEvent<HTMLDivElement>) => void;
}

const AvatarCell: FC<Props> = ({ firstName, lastName, onHover, imageUrl }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.avatarContainer} onMouseOver={onHover}>
      <Avatar alt="avatar" className={classes.avatar} src={imageUrl}>
        {getInitials(firstName, lastName)}
      </Avatar>
      <Typography variant={"subtitle1"} color={"textSecondary"}>
        {firstName} {lastName}
      </Typography>
    </Box>
  );
};

export default AvatarCell;
