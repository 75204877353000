import { DEFAULT_PAGE, OVERVIEW_DEFAULT_PER_PAGE } from "../constants";
import { SortOrder } from "../types/default";
import { useConsultants } from "./consultants/useConsultants";
import { useCreateJobRequirement } from "./requirements/useCreateJobRequirement";
import { useCreateSkill } from "./skills/useCreateSkill";
import { useSkills } from "./skills/useSkills";
import { useManagers } from "./users/useManagers";

export const useHomeScreenDataFetch = (
  order?: SortOrder | null,
  sortBy?: string | null,
  requirementId?: string | null,
  recentlyAdded?: boolean | null
) => {
  const { createSkill, isLoading: isCreatingSkill } = useCreateSkill();

  const { skillsData, isLoading: isSkillsLoading } = useSkills();

  const { usersData, isLoading: isUsersLoading } = useConsultants(DEFAULT_PAGE, OVERVIEW_DEFAULT_PER_PAGE, undefined, {
    order: order ?? undefined,
    sortBy: sortBy ?? undefined,
    projectOrder: requirementId ?? undefined,
    recentlyAdded: recentlyAdded ?? undefined,
  });
  const { usersData: managersData, isLoading: isLoadingManagers } = useManagers();

  const { createJobRequirement, isLoading: isCreatingRequirement } = useCreateJobRequirement();

  const isLoading = isCreatingSkill || isSkillsLoading || isLoadingManagers || isCreatingRequirement || isUsersLoading;

  return { isLoading, createSkill, skillsData: skillsData?.data, managersData, createJobRequirement, usersData };
};
