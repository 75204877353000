import { createContext, FC, useEffect } from "react";

import { Auth } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import { PATHS } from "../constants";
import { useForgotPassword } from "../hooks/authentication/useForgotPassword";
import { useLogout } from "../hooks/authentication/useLogout";
import { useRefreshToken } from "../hooks/authentication/useRefreshToken";
import { useSignIn } from "../hooks/authentication/useSignin";
import { useUserInfo } from "../hooks/authentication/useUserInfo";
import { useCreateUser } from "../hooks/users/useCreateUser";
import { addWebStorage, removeWebStorage, webStorageExists } from "../lib/webStorage";
import { UserState, useUserStore } from "../store/useUserStore";
import { LoginTypes } from "../types/authentication";
import { RegisterUserTypesWithRole } from "../types/users";

export type AuthContextState = {
  login: ({ email, password, rememberMe }: LoginTypes) => void;
  logout: () => void;
  resetPassword: (email: string) => void;
  registerUser: (data: RegisterUserTypesWithRole) => void;
  isLoading: boolean;
  isSuccess: boolean;
  isLoadingInComponent: boolean;
};

export const AuthContext = createContext<AuthContextState>(null!);

interface Props {
  auth: Auth;
}

const userSelector = (state: UserState) => ({
  resetUser: state.resetUserInfo,
  userRole: state.role,
});

const AuthContextProvider: FC<Props> = ({ children, auth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleLogin, isSuccess, isLoading: isSigningIn } = useSignIn();
  const { refreshToken, isLoading: isRefreshing } = useRefreshToken();
  const { handleResetPassword, isLoading: isResetingPassword } = useForgotPassword();
  const { handleLogout } = useLogout();
  const { isLoading: loadingUserInfo } = useUserInfo();
  const { createUser, isLoading: creatingUser } = useCreateUser();

  const { resetUser, userRole } = useUserStore(userSelector, shallow);

  const login = (data: LoginTypes) => {
    const { rememberMe } = data;

    if (rememberMe) {
      addWebStorage(true);
    }

    addWebStorage();

    handleLogin({ data, auth });
  };

  const logout = () => {
    removeWebStorage();

    handleLogout(auth);
  };

  const resetPassword = (email: string) => handleResetPassword({ email, auth });

  const registerUser = (data: RegisterUserTypesWithRole) => createUser({ ...data, auth });

  const isLoading = isSigningIn || isRefreshing || isResetingPassword || loadingUserInfo || !userRole;

  const isLoadingInComponent = creatingUser;

  useEffect(() => {
    if (!webStorageExists() && !auth.currentUser) {
      navigate(PATHS.login, { state: { from: location }, replace: true });

      return;
    }

    const unlisten = auth.onAuthStateChanged(user => {
      if (!user) {
        resetUser();

        navigate(PATHS.login, { state: { from: location }, replace: true });
        return;
      }

      refreshToken(user);
    });

    return () => {
      unlisten();

      if (!webStorageExists()) {
        resetUser();
        auth.signOut();
      }
    };
  }, []);

  const contextValue = {
    login,
    logout,
    resetPassword,
    registerUser,
    isLoading,
    isSuccess,
    isLoadingInComponent,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
