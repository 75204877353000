import { FC } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { useInterviewFormBuilder } from "../../hooks/interviews/useInterviewFormBuilder";
import { useScheduleInterview } from "../../hooks/interviews/useScheduleInteview";
import Loading from "../../layouts/Loading";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { ScheduleInterviewTypes } from "../../types/interviews";
import { UserRoles } from "../../types/users";
import { scheduleInterviewBuilder } from "../forms/builders/scheduleInterviewBuilder";
import ScheduleInterviewForm from "../forms/ScheduleInterviewForm";

interface Props {
  closeModal: () => void;
  consultantId: string;
}

const userRoleSelector = (state: UserState) => state.role;
const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

const ScheduleInterview: FC<Props> = ({ closeModal, consultantId }) => {
  const { t } = useTranslation();
  const { scheduleInterview, isLoading } = useScheduleInterview();
  const { builderFieldsWithInterviewTypes } = useInterviewFormBuilder();
  const userRole = useUserStore(userRoleSelector);
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const handleSubmitInterview = (data: ScheduleInterviewTypes<Dayjs>) => {
    if (userRole === UserRoles.manager) {
      const interviewDate = dayjs(data.interviewDate).valueOf();
      const timeFrom = dayjs(data.timeFrom).valueOf();
      const timeTo = dayjs(data.timeTo).valueOf();

      scheduleInterview({ data: { ...data, interviewDate, timeFrom, timeTo }, consultantId });
      return;
    }

    addSnackbar({ type: "warning", text: t("userProfile:actions.sendQuestions.errors.notAllowedScheduleInterview") });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ScheduleInterviewForm
      closeModal={closeModal}
      btnLabel={t("userProfile:actions.scheduleAnInterview.submitBtn")}
      formConfig={{ ...scheduleInterviewBuilder, fields: builderFieldsWithInterviewTypes }}
      handleSubmitForm={handleSubmitInterview}
    />
  );
};

export default ScheduleInterview;
