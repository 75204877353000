import { FC, useState } from "react";

import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { NestedSublistItem, NestedSublistItemType } from "../../types/sidebar";
import { SublistOptionTypes } from "../../types/sublist";

const useStyles = makeStyles()(theme => ({
  nestedListItem: {
    paddingLeft: theme.spacing(5),
  },
  shareIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.25),
  },
  listItemColor: {
    color: theme.palette.textLight.main,
  },
  addIconColor: {
    color: theme.palette.textLight.dark,
  },
  moreIconColor: {
    color: theme.palette.icon.main,
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  },
  menuList: {
    padding: theme.spacing(0),
    border: "none",
  },
}));

interface Props {
  sublist: NestedSublistItem;
}

const SublistItem: FC<Props> = ({ sublist }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { sublistId } = useParams<{ sublistId: string }>();

  const handleCloseOptions = () => setAnchorEl(null);

  const handleMoreOptionsClick = () => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const dropDownMenuOptions: SublistOptionTypes[] = [
    {
      label: t("main:requirementCard.actions.edit"),
      icon: <Edit color={"primary"} />,
      action: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event && event.stopPropagation();
        sublist.editAction && sublist.editAction();
        setAnchorEl(null);
      },
    },
    {
      label: t("main:requirementCard.actions.delete"),
      icon: <Delete color={"primary"} />,
      action: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event && event.stopPropagation();
        sublist.deleteAction && sublist.deleteAction();
        setAnchorEl(null);
      },
    },
  ];

  return (
    <ListItemButton className={classes.nestedListItem} onClick={sublist.action} selected={sublistId === sublist.id}>
      {sublist.type === NestedSublistItemType.add && (
        <ListItemIcon>
          <Add className={classes.addIconColor} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography variant={"subtitle2"} className={classes.listItemColor}>
            {sublist.name}
          </Typography>
        }
      />

      {sublist.type === NestedSublistItemType.default && (
        <>
          <IconButton onClick={handleMoreOptionsClick()}>
            <MoreVert className={classes.moreIconColor} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseOptions}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            classes={{ list: classes.menuList }}
          >
            {dropDownMenuOptions.map(item => (
              <MenuItem key={item.label} className={classes.menuItem} onClick={item.action}>
                <Typography variant={"subtitle1"} color={"textPrimary"}>
                  {item.label}
                </Typography>
                {item.icon}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </ListItemButton>
  );
};

export default SublistItem;
