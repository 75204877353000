import kommlinkApi, { BackendError } from "../lib/axios";
import { FlaggedCvs } from "../types/flaggedCvs";

const getFlaggedCvs = async (): Promise<FlaggedCvs[]> => {
  try {
    const { data } = await kommlinkApi.get<FlaggedCvs[]>("/users/me/consultants/flagged");

    return data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const flagConsultantCv = async (consultantId: string) => {
  try {
    await kommlinkApi.post<void>(`/users/me/flag/${consultantId}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const unflagConsultantCv = async (consultantId: string) => {
  try {
    await kommlinkApi.delete<void>(`/users/me/unflag/${consultantId}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const flaggedCvsService = {
  getFlaggedCvs,
  flagConsultantCv,
  unflagConsultantCv,
};

export default flaggedCvsService;
