import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { ConsultantsToRequirement } from "../../../types/requirements";

export const addConsultantsToRequirementBuilder: FormConstructor<ConsultantsToRequirement> = {
  fields: [
    {
      name: "consultants",
      label: "main:addConsultantsToRequirement.consultants",
      validationOptions: {
        required: true,
      },
      type: FieldType.selectMultiple,
      defaultValue: [],
      options: [],
    },
  ],
};
