import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import requirementsService from "../../services/requirements";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent, PaginatedData } from "../../types/default";
import { RequirementData } from "../../types/requirements";

interface ReturnType {
  data?: PaginatedData<RequirementData>;
  isLoading: boolean;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useRequirements = (
  page: number,
  handleChangePage?: (newPage: number) => void,
  perPage?: number,
  masterUserList?: boolean
): ReturnType => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { data, isLoading } = useQuery(
    [QUERY_STRINGS.GET_REQUIREMENTS, page, perPage],
    () => requirementsService.getCurrentUserRequirements(page, perPage, masterUserList),
    {
      keepPreviousData: true,
      onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
      onSuccess: data => !data.data.length && data.totalElements && handleChangePage && handleChangePage(page - 1),
    }
  );

  return { data, isLoading };
};
