import { FC } from "react";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Trans } from "react-i18next";

import { ReactComponent as EmptyStateImage } from "../assets/empty-state.svg";

interface Props {
  text: string;
}

const EmptyState: FC<Props> = ({ text }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} paddingTop={6.5}>
      <EmptyStateImage />
      <Typography variant={"h3"} paddingTop={5} paddingBottom={1}>
        <Trans i18nKey={"emptyState:heading"}>{{ text }}</Trans>
      </Typography>
      <Typography variant={"subtitle1"} color={"textSecondary"}>
        <Trans i18nKey={"emptyState:subheading"}>{{ text }}</Trans>
      </Typography>
    </Box>
  );
};

export default EmptyState;
