import { FC, useState } from "react";

import { ArrowDropDown } from "@mui/icons-material";
import { Avatar, Box, Button, CircularProgress, IconButton, Rating, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { DropdownMenu, ModalContainer } from ".";
import { ReactComponent as SendMessage } from "../assets/send-message.svg";
import { getInitials } from "../functions";
import { useRateConsultant } from "../hooks/consultants/useRateConsultant";
import { useSendFirstMessage } from "../hooks/messages/useSendFirstMessage";
import { InitialMessageTypes } from "../types/notifications";
import { ConsultantsData } from "../types/users";
import { addMessageConfig } from "./forms/builders/addMessageConfig";
import FormConsumer from "./forms/FormConsumer";

const useStyles = makeStyles<{ large?: boolean }>()((theme, { large }) => ({
  avatar: {
    width: large ? theme.spacing(9) : theme.spacing(7),
    height: large ? theme.spacing(9) : theme.spacing(7),
  },
  dropdownButton: {
    display: "flex",
    gap: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

interface Props {
  large?: boolean;
  data: ConsultantsData;
}

interface ActionIconColors {
  sendMessage: boolean;
  updateCv: boolean;
  updateJobAvailability: boolean;
  updateInterviewAvailability: boolean;
}

const ConsultantInfoModalHeader: FC<Props> = ({ children, large, data }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ large });

  const { rateConsultant, rateResponse, isLoading } = useRateConsultant();
  const { sendFirstMessage, isLoading: isSendingMessage } = useSendFirstMessage();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setModalOpen] = useState(false);

  //TODO Remove when properties are added on BE
  const [actionIconColors, setActionIconColors] = useState<ActionIconColors>({
    sendMessage: false,
    updateCv: false,
    updateInterviewAvailability: false,
    updateJobAvailability: false,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  if (isSendingMessage) {
    return <CircularProgress />;
  }

  const handleSubmitMessage = (messageData: InitialMessageTypes) => {
    sendFirstMessage({ data: messageData, consultantId: data.id });
    //Remove when properties are added on BE
    setActionIconColors({ ...actionIconColors, sendMessage: true });
  };

  const handleRatingChange = (event: React.SyntheticEvent<Element, Event>, value: number | null) => {
    rateConsultant({ id: data.id, rating: value ? value : Math.round(data.averageRating) });
    handleClose();
  };

  //Not needed for now
  // const handleSendNotification = (type?: NotificationType) => {
  //   //TODO Update notification message
  //   type && sendNotification(type);
  // };

  const notificationButtons = [
    {
      icon: <SendMessage />,
      action: handleOpenModal,
      notificationSent: actionIconColors.sendMessage,
    },
  ];
  //Not needed for now
  // {
  //   icon: <UpdateCv />,
  //   action: () => {
  //     handleSendNotification(NotificationType.UPDATE_CV);
  //     //Remove when properties are added on BE
  //     setActionIconColors({ ...actionIconColors, updateCv: true });
  //   },
  //   notificationSent: actionIconColors.updateCv,
  // },
  // {
  //   icon: <UpdateJobAvailability />,
  //   action: () => {
  //     handleSendNotification(NotificationType.UPDATE_JOB_AVAILABILITY);
  //     //Remove when properties are added on BE
  //     setActionIconColors({ ...actionIconColors, updateJobAvailability: true });
  //   },
  //   notificationSent: actionIconColors.updateJobAvailability,
  // },
  // {
  //   icon: <UpdateInterviewAvailability />,
  //   action: () => {
  //     handleSendNotification(NotificationType.UPDATE_INTERVIEW_AVAILABILITY);
  //     //Remove when properties are added on BE
  //     setActionIconColors({ ...actionIconColors, updateInterviewAvailability: true });
  //   },
  //   notificationSent: actionIconColors.updateInterviewAvailability,
  // },

  return (
    <Box display={"flex"} alignItems={"center"} gap={2}>
      <Avatar alt="avatar" className={classes.avatar} src={data.imageUrl}>
        {getInitials(data.firstName, data.lastName)}
      </Avatar>

      <Box>
        <Box display={"flex"}>
          <Button className={classes.dropdownButton} onClick={handleClick}>
            <Typography variant={"h3"} fontWeight={600}>
              {data.firstName} {data.lastName}
            </Typography>
            <ArrowDropDown />
          </Button>

          {notificationButtons.map((button, index) => (
            <IconButton
              onClick={button.action}
              key={index}
              color={button.notificationSent ? "default" : "primary"}
              disabled={button.notificationSent}
            >
              {button.icon}
            </IconButton>
          ))}

          <DropdownMenu anchorEl={anchorEl} close={handleClose} consultantData={data} />
        </Box>

        <Box paddingLeft={1}>
          {isLoading ? (
            <CircularProgress size={25} />
          ) : (
            <Rating
              value={rateResponse?.averageRating ?? data.averageRating}
              onChange={handleRatingChange}
              precision={0.5}
            />
          )}
        </Box>
      </Box>

      {children}

      <ModalContainer
        isOpened={openModal}
        title={t("main:popoverContent.sendMessage", {
          firstName: data.firstName,
          lastName: data.lastName,
        })}
        closeModal={handleCloseModal}
        size={"lg"}
      >
        <FormConsumer
          closeModal={handleCloseModal}
          btnLabel={t("main:popoverContent.sendBtn")}
          formConfig={addMessageConfig}
          handleSubmitForm={handleSubmitMessage}
        />
      </ModalContainer>
    </Box>
  );
};

export default ConsultantInfoModalHeader;
