import { FC } from "react";

import { Navigate, Outlet, useRoutes } from "react-router-dom";

import { PATHS } from "./constants";
import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import Companies from "./pages/Companies";
import Consultants from "./pages/Consultants";
import ForgottenPassword from "./pages/ForgottenPassword";
import Home from "./pages/Home";
import JobOrder from "./pages/JobOrder";
import LoginPage from "./pages/LoginPage";
import Managers from "./pages/Managers";
import Messages from "./pages/Messages";
import SentQuestions from "./pages/SentQuestions";
import SingleConsultant from "./pages/SingleConsultant";
import Skills from "./pages/Skills";
import Sublist from "./pages/Sublist";
import Superadmins from "./pages/Superadmins";
import UpcomingCVs from "./pages/UpcomingCVs";
import UpcomingInterviews from "./pages/UpcomingInterviews";
import { UserState, useUserStore } from "./store/useUserStore";
import { UserRoles } from "./types/users";

const userRoleSelector = (state: UserState) => state.role;

const Router: FC = () => {
  const currentUser = useUserStore(userRoleSelector);

  const ProtectedRoute: FC = () => {
    if (currentUser === UserRoles.superadmin) {
      return <Outlet />;
    }

    return <Navigate to={PATHS.root} />;
  };

  const routes = useRoutes([
    {
      path: PATHS.root,
      element: <DashboardLayout />,
      children: [
        {
          element: <Home />,
          index: true,
        },
        {
          path: PATHS.consultants,
          children: [
            {
              element: <Consultants />,
              index: true,
            },
            {
              path: PATHS.consultantId,
              element: <SingleConsultant />,
            },
          ],
        },
        {
          path: PATHS.upcomingInterviews,
          element: <UpcomingInterviews />,
        },
        {
          path: PATHS.sentQuestions,
          element: <SentQuestions />,
        },
        {
          path: PATHS.jobs,
          element: <JobOrder />,
        },
        {
          path: PATHS.messages,
          element: <Messages />,
        },
        {
          path: `${PATHS.sublist}/${PATHS.sublistId}`,
          element: <Sublist />,
        },
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: PATHS.companies,
              element: <Companies />,
            },
            {
              path: PATHS.superadmins,
              element: <Superadmins />,
            },
            {
              path: PATHS.managers,
              element: <Managers />,
            },
            {
              path: PATHS.skills,
              element: <Skills />,
            },
            {
              path: PATHS.upcomingCVs,
              element: <UpcomingCVs />,
            },
          ],
        },
      ],
    },
    {
      element: <AuthLayout />,
      children: [
        {
          path: PATHS.login,
          element: <LoginPage />,
        },
        {
          path: PATHS.forgottenPassword,
          element: <ForgottenPassword />,
        },
      ],
    },

    {
      path: PATHS.login,
      element: <LoginPage />,
    },

    {
      path: "*",
      element: <div>Not found</div>,
    },
  ]);

  return routes;
};

export default Router;
