import { DEFAULT_PER_PAGE } from "../constants";
import kommlinkApi, { BackendError } from "../lib/axios";
import { PaginatedData } from "../types/default";
import { NotificationContent, NotificationType } from "../types/notifications";

const sendNotification = async (type: NotificationType): Promise<void> => {
  try {
    await kommlinkApi.post<void>("/notification/", { type });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const sendRegistrationToken = async (notificationToken: string): Promise<void> => {
  try {
    await kommlinkApi.post<void>("/notifications/notification-token", { notificationToken });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const markViewedNotifications = async (notificationId: string): Promise<void> => {
  try {
    await kommlinkApi.post<void>(`/notifications/${notificationId}/viewed`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getNotifications = async (page: number): Promise<PaginatedData<NotificationContent>> => {
  try {
    const { data } = await kommlinkApi.get<PaginatedData<NotificationContent>>("/notifications", {
      params: {
        page,
        perPage: DEFAULT_PER_PAGE,
      },
    });

    return data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getUnreadNotifications = async (): Promise<number> => {
  try {
    const { data } = await kommlinkApi.get<number>("/notifications/unread/count");
    return data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const notificationsService = {
  sendNotification,
  sendRegistrationToken,
  markViewedNotifications,
  getNotifications,
  getUnreadNotifications,
};

export default notificationsService;
