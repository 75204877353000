import { FC, useState } from "react";

import AdapterDayjs from "@date-io/dayjs";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { TIME_FORMAT } from "../constants";
import { useCreateManagerJobRequirement } from "../hooks/requirements/useCreateManagerJobRequirement";
import Loading from "../layouts/Loading";
import { DateTypes } from "../types/main";
import { ManagerCreateRequirement } from "../types/requirements";

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.primaryWithOpacity.main,
    padding: theme.spacing(2, 2, 3, 2),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(4),
    display: "flex",
    gap: theme.spacing(2),
  },
  errorIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(3.3),
    height: theme.spacing(3.3),
  },
  subtitle: {
    color: theme.palette.dark.dark,
  },
}));

interface Props {
  btnLabel: string;
  requirementData: ManagerCreateRequirement | null;
  closeModal: () => void;
}

const DATE_PICKER_FORMAT = "YYYY-MM-DD";

const RequirementRequest: FC<Props> = ({ btnLabel, requirementData, closeModal }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { createManagerJobRequirement, isLoading } = useCreateManagerJobRequirement();

  const [dateFields, setDateFields] = useState<{ date: Dayjs; time: Dayjs }>({
    date: dayjs(),
    time: dayjs(),
  });

  const handleAddDateFields = (type: DateTypes) => (value: string | null) => {
    if (value) {
      type === DateTypes.DATE
        ? setDateFields({ ...dateFields, date: dayjs(value) })
        : setDateFields({ ...dateFields, time: dayjs(value) });
    }
  };

  const handleRequestRequirementForm = () => {
    if (requirementData) {
      const dateWithAddedTime =
        dayjs(dateFields.date).format(DATE_PICKER_FORMAT) + " " + dayjs(dateFields.time).format(TIME_FORMAT);

      createManagerJobRequirement({ ...requirementData, meetingDate: dayjs(dateWithAddedTime).valueOf() });
    }

    closeModal();
  };

  if (isLoading) {
    <Loading />;
  }

  return (
    <Box>
      <Box className={classes.container}>
        <ErrorOutline className={classes.errorIcon} />

        <Typography variant={"subtitle1"} color={"textPrimary"} textAlign={"left"} whiteSpace={"pre-line"}>
          {t("main:newRequirement.requirementRequest.title")}
        </Typography>
      </Box>

      <Box display={"flex"} gap={5} marginTop={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dateFields.date}
            onChange={handleAddDateFields(DateTypes.DATE)}
            renderInput={props => <TextField {...props} fullWidth />}
            disablePast
            label={t("main:newRequirement.requirementRequest.pickDate")}
          />
          <TimePicker
            value={dateFields.time}
            onChange={handleAddDateFields(DateTypes.TIME)}
            renderInput={props => <TextField {...props} fullWidth />}
            ampm
          />
        </LocalizationProvider>
      </Box>

      <Box display={"flex"} gap={1.2} marginTop={2} className={classes.subtitle}>
        <ErrorOutline />
        <Typography variant={"subtitle2"} fontWeight={400}>
          {t("main:newRequirement.requirementRequest.subtitle")}
        </Typography>
      </Box>

      <Box paddingTop={6} display={"flex"} justifyContent={"flex-end"}>
        <Button variant={"contained"} color={"primary"} onClick={handleRequestRequirementForm}>
          <Typography variant={"button"}>{btnLabel}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default RequirementRequest;
