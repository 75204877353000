import { useState } from "react";

import dayjs from "dayjs";

import { createQuestionsBuilder } from "../../components/forms/builders/createQuestionsBuilder";
import { UserState, useUserStore } from "../../store/useUserStore";
import { FormFieldsConstructor } from "../../types/default";
import { QuestionsBody, QuestionsData } from "../../types/questions";
import { UserRoles } from "../../types/users";
import { useConsultants } from "../consultants/useConsultants";

const userRoleSelector = (state: UserState) => state.role;
const CONSULTANT = "consultant";
const SENT_QUESTIONS = "sentQuestions";
const DATE = "date";

export const useQuestionsOptions = () => {
  const userRole = useUserStore(userRoleSelector);
  const { usersData: consultantsData, isLoading: isConsultantsLoading } = useConsultants();

  const [currentQuestionaire, setCurrentQuestionaire] = useState<QuestionsData | null>(null);

  const handleSetCurrentQuestionaire = (data: QuestionsData | null) => setCurrentQuestionaire(data);

  const getOptions = (field: FormFieldsConstructor<QuestionsBody>) => {
    if (userRole === UserRoles.superadmin && consultantsData) {
      if (field.name === CONSULTANT) {
        return consultantsData.data.map(consultant => ({
          id: consultant.id,
          name: `${consultant.firstName} ${consultant.lastName}`,
        }));
      }
    }

    return field.options;
  };

  const getDefaultValues = (field: FormFieldsConstructor<QuestionsBody>) => {
    if (field.name === CONSULTANT) {
      return currentQuestionaire?.consultantDetails.id;
    }
    if (field.name === DATE) {
      return dayjs(currentQuestionaire?.date);
    }

    return field.defaultValue;
  };

  const isLoading = isConsultantsLoading && userRole === UserRoles.superadmin;

  const createQuestionsBuilderFields = createQuestionsBuilder.fields.map(field => ({
    ...field,
    options: getOptions(field),
  }));

  const editQuestionsBuilderFields = createQuestionsBuilder.fields
    .filter(field => field.name !== SENT_QUESTIONS)
    .map(field => ({
      ...field,
      options: getOptions(field),
      disabledField: field.name === CONSULTANT,
      validationOptions: {
        required: true,
      },
      defaultValue: getDefaultValues(field),
    }));

  return {
    isLoading,
    createQuestionsBuilderFields,
    editQuestionsBuilderFields,
    handleSetCurrentQuestionaire,
    currentQuestionaire,
  };
};
