import { useContext } from "react";

import { UseMutateFunction, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { PATHS, QUERY_STRINGS } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import authenticationService from "../../services/authentication";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { LoginTypesWithAuth } from "../../types/authentication";
import { ErrorContent } from "../../types/default";
import { MixpanelEvents } from "../../types/mixpanel";

type ReturnType = {
  handleLogin: UseMutateFunction<void, ErrorContent, LoginTypesWithAuth, unknown>;
  isLoading: boolean;
  isSuccess: boolean;
};

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;
const setLoggedInSelector = (state: UserState) => state.login;

export const useSignIn = (): ReturnType => {
  const { mixpanelTrack } = useContext(MixpanelContext);
  const addSnackBar = useSnackbar(addSnackbarSelector);
  const setLoggedIn = useUserStore(setLoggedInSelector);
  const navigate = useNavigate();

  const {
    isSuccess,
    isLoading,
    mutate: handleLogin,
  } = useMutation(({ data, auth }: LoginTypesWithAuth) => authenticationService.firebaseSignIn({ data, auth }), {
    mutationKey: QUERY_STRINGS.USER_SIGNIN,
    onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
    onSuccess: (_, data) => {
      setLoggedIn();
      mixpanelTrack(MixpanelEvents.LOGGED_IN, { email: data.data.email });
      navigate(PATHS.root);
    },
  });

  return {
    isLoading,
    isSuccess,
    handleLogin,
  };
};
