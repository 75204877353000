import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { ShareConsultant } from "../../../types/rightSidebar";

export const shareConsultantBuilder: FormConstructor<ShareConsultant> = {
  fields: [
    {
      name: "manager",
      label: "userProfile:actions.shareConsultant.manager",
      validationOptions: {
        required: true,
      },
      type: FieldType.select,
      defaultValue: "",
      options: [],
      isFullWidth: true,
    },
  ],
};
