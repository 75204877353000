import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import upcomingInterviewsService from "../../services/interviews";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useScheduleInterview = () => {
  const { t } = useTranslation();
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { mutate: scheduleInterview, isLoading } = useMutation(upcomingInterviewsService.scheduleInterview, {
    mutationKey: QUERY_STRINGS.SCHEDULE_INTERVIEW,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () =>
      addSnackbar({ type: "success", text: t("userProfile:actions.scheduleAnInterview.scheduledInterview") }),
  });

  return { scheduleInterview, isLoading };
};
