import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import requirementsService from "../../services/requirements";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteJobRequirement = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { mutate: deleteRequirement, isLoading } = useMutation(requirementsService.deleteJobRequirement, {
    mutationKey: QUERY_STRINGS.DELETE_REQUIREMENT,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries([QUERY_STRINGS.GET_REQUIREMENTS]),
  });

  return { deleteRequirement, isLoading };
};
