import { BaseEntityName, FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";

export const skillConfig: FormConstructor<BaseEntityName> = {
  fields: [
    {
      name: "name",
      label: "main:newSkill.skill",
      validationOptions: {
        required: true,
        validate: {
          minLength: 2,
          label: "main:newSkill.invalidSkill",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
  ],
};
