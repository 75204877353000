import { Auth } from "firebase/auth";
import { UseMutateFunction, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { PATHS, QUERY_STRINGS } from "../../constants";
import authenticationService from "../../services/authentication";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

type ReturnType = {
  handleResetPassword: UseMutateFunction<void, ErrorContent, { email: string; auth: Auth }, unknown>;
  isLoading: boolean;
};

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useForgotPassword = (): ReturnType => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const navigate = useNavigate();

  const { isLoading, mutate: handleResetPassword } = useMutation(authenticationService.firebaseForgotPassword, {
    mutationKey: QUERY_STRINGS.FORGOTTEN_PASSWORD,
    onError: (e: ErrorContent) => addSnackbar({ text: e.message }),
    onSuccess: () => navigate(PATHS.login),
  });

  return { isLoading, handleResetPassword };
};
