import { useState } from "react";

import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "../constants";

interface ReturnType {
  page: number;
  perPage: number;
  handleChangePage: (newPage: number) => void;
  handleChangePerPage: (newPage: number) => void;
}

export const useChangePage = (): ReturnType => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);

  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleChangePerPage = (newPage: number) => {
    setPage(DEFAULT_PAGE);
    setPerPage(newPage);
  };

  return { page, perPage, handleChangePage, handleChangePerPage };
};
