import { FC, useContext, useState } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, CardHeader, SuperadminsTable } from "../components";
import { addSuperadminBuilder } from "../components/forms/builders/addSuperadminBuilder";
import { AuthContext } from "../contexts/AuthContext";
import { useEditUser } from "../hooks/users/useEditUser";
import Loading from "../layouts/Loading";
import { FieldOptionNames } from "../types/default";
import { UserBaseTypes, UserRoles } from "../types/users";

const Superadmins: FC = () => {
  const { t } = useTranslation();

  const { registerUser, isLoadingInComponent: isLoading } = useContext(AuthContext);
  const { editUser, isLoading: isEditLoading } = useEditUser(UserRoles.superadmin);

  const [editMode, setEditMode] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<null | UserBaseTypes>(null);

  const handleChangeMode = (data: UserBaseTypes) => () => {
    setEditMode(true);
    setCurrentUserData(data);
  };

  const handleCloseEditMode = () => setEditMode(false);

  const handleSubmitForm = (data: UserBaseTypes) => {
    editMode && currentUserData
      ? editUser({ ...data, id: currentUserData.id })
      : registerUser({ ...data, role: UserRoles.superadmin, companyIds: [] });
  };

  const editSuperadminFields = addSuperadminBuilder.fields.map(field => ({
    ...field,
    defaultValue: currentUserData ? currentUserData[field.name] : field.defaultValue,
    disabledField: field.name === FieldOptionNames.EMAIL,
  }));

  if (isLoading || isEditLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("superadmins:description")} />
        <title>
          {t("superadmins:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <CardHeader
        title={t("sidebar:superadmins")}
        btnLabel={t("superadmins:addNewBtn")}
        modalTitle={editMode ? t("superadmins:edit") : t("superadmins:addNew")}
        formConfig={{ ...addSuperadminBuilder, fields: editMode ? editSuperadminFields : addSuperadminBuilder.fields }}
        modalBtnTitle={editMode ? t("formFields:buttons.done") : t("superadmins:addNewBtn")}
        size={"md"}
        isEditMode={editMode}
        handleSubmitForm={handleSubmitForm}
        closeEditMode={handleCloseEditMode}
      />
      <CardContentSection table={<SuperadminsTable handleChangeMode={handleChangeMode} />} />
    </>
  );
};

export default Superadmins;
