import kommlinkApi, { BackendError } from "../lib/axios";
import { PaginatedData } from "../types/default";
import {
  AddConsultantsToRequirementData,
  CreateRequirement,
  EditRequirement,
  ManagerCreateRequirement,
  RequirementData,
  UpdateRequirementStatus,
} from "../types/requirements";

const getCurrentUserRequirements = async (
  page: number,
  perPage?: number,
  masterUserList = true
): Promise<PaginatedData<RequirementData>> => {
  try {
    const res = await kommlinkApi.get<PaginatedData<RequirementData>>("/users/me/job-requirements", {
      params: {
        page,
        perPage,
        masterUserList,
      },
    });

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const createSuperadminJobRequirement = async (body: CreateRequirement): Promise<void> => {
  try {
    await kommlinkApi.post<void>("/superadmin/job-requirements", { ...body });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const createManagerJobRequirement = async (body: ManagerCreateRequirement): Promise<void> => {
  try {
    await kommlinkApi.post<void>("/manager/mails/requirements", { ...body });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const addConsultantsToRequirement = async ({
  requirementId,
  consultantIds,
}: AddConsultantsToRequirementData): Promise<void> => {
  try {
    await kommlinkApi.patch<void>(`/job-requirements/${requirementId}/consultants`, { consultantIds });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const changeRequirementStatus = async ({ requirementId, status }: UpdateRequirementStatus): Promise<void> => {
  try {
    await kommlinkApi.patch<void>(`/superadmin/job-requirements/${requirementId}/status`, {
      jobRequirementsStatus: status,
    });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editSuperadminJobRequirement = async ({ body, id }: EditRequirement): Promise<void> => {
  try {
    await kommlinkApi.put<void>(`/superadmin/job-requirements/${id}`, { ...body });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteJobRequirement = async (id: string) => {
  try {
    await kommlinkApi.delete<void>(`/superadmin/job-requirements/${id}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const requirementsService = {
  getCurrentUserRequirements,
  createSuperadminJobRequirement,
  createManagerJobRequirement,
  addConsultantsToRequirement,
  changeRequirementStatus,
  editSuperadminJobRequirement,
  deleteJobRequirement,
};

export default requirementsService;
