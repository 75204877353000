import { useContext } from "react";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import { PATHS, QUERY_STRINGS } from "../../constants";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import authenticationService from "../../services/authentication";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { ErrorContent } from "../../types/default";
import { UserReturnType, UserRoles } from "../../types/users";
import { useSendRegistrationToken } from "../notifications/useSendRegistrationToken";

interface ReturnTypes {
  isLoading: boolean;
}

const updateUserInfoSelector = (state: UserState) => ({
  updateUserInfo: state.updateUserInfo,
  resetUserInfo: state.resetUserInfo,
});
const userInfoSelector = (state: UserState) => ({
  firstName: state.firstName,
  lastName: state.lastName,
  role: state.role,
  isLoggedIn: state.isLoggedIn,
});
const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useUserInfo = (): ReturnTypes => {
  const { mixpanelIdentify, mixpanelSetCurrentPerson, mixpanelRegister } = useContext(MixpanelContext);
  const { updateUserInfo, resetUserInfo } = useUserStore(updateUserInfoSelector, shallow);
  const { firstName, lastName, isLoggedIn } = useUserStore(userInfoSelector, shallow);
  const addSnackBar = useSnackbar(addSnackBarSelector);
  const navigate = useNavigate();

  const { registrationToken } = useContext(FirebaseContext);
  const { sendRegistrationToken } = useSendRegistrationToken();

  const { isLoading } = useQuery<UserReturnType, ErrorContent>(
    QUERY_STRINGS.USER_INFO,
    authenticationService.getUserInfo,
    {
      retry: 3,
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
      onSuccess: ({ role, data }) => {
        if (role !== UserRoles.consultant) {
          updateUserInfo({ ...data, role });

          registrationToken && sendRegistrationToken(registrationToken);

          mixpanelIdentify(data.id);
          mixpanelSetCurrentPerson(`${data.firstName} ${data.lastName}`, data.email, {
            role,
          });

          mixpanelRegister({
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            role,
          });
          return;
        }

        resetUserInfo();
        navigate(PATHS.login);
      },
      enabled: (!firstName || !lastName) && isLoggedIn,
    }
  );

  return { isLoading };
};
