import { FC, useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Avatar, ChannelPreviewUIComponentProps } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { makeStyles } from "tss-react/mui";
import shallow from "zustand/shallow";

import { ChannelOptions } from "..";
import { useConsultantById } from "../../hooks/consultants/useConsultantById";
import { useConsultants } from "../../hooks/consultants/useConsultants";
import Loading from "../../layouts/Loading";
import { ConsultantsState, useConsultantsStore } from "../../store/useConsultantsStore";
import { UserState, useUserStore } from "../../store/useUserStore";

const useStyles = makeStyles<{ active?: boolean; unreadMessage?: boolean }>()((theme, { active, unreadMessage }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderBottom: `1px solid ${theme.palette.dark.light}`,
    backgroundColor: active
      ? theme.palette.primaryWithOpacity.light
      : unreadMessage
      ? theme.palette.primaryWithOpacity.dark
      : "unset",
    width: "100%",
    height: "100%",
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(3),
    "& > div": {
      padding: "0px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginLeft: theme.spacing(0.5),
  },
  latestMessage: {
    "& > p": {
      margin: "0px",
      width: theme.spacing(25),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    color: theme.palette.dark.main,
  },
}));

const userIdSelector = (state: UserState) => state.getStreamId;
const consultantsDataSelector = (state: ConsultantsState) => ({
  lastSelected: state.lastSelectedConsultant,
  addLastSelected: state.addLastSelectedConsultant,
  resetLastSelected: state.resetLastSelectedConsultant,
});

const CustomChannelPreview: FC<
  React.PropsWithChildren<ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>>
> = props => {
  const { addLastSelected, resetLastSelected } = useConsultantsStore(consultantsDataSelector, shallow);

  const { channel, setActiveChannel, active, displayTitle, displayImage, unread, latestMessage } = props;
  const unreadMessage = Boolean(unread && unread >= 1 && !active);
  const { classes } = useStyles({ active, unreadMessage });

  const [selectedUserId, setSelectedUserId] = useState<undefined | string>(undefined);
  const { usersData, isLoading } = useConsultants();

  const currentConsultant = usersData?.data.filter(el => el.id === selectedUserId);

  const { consultantData } = useConsultantById(
    currentConsultant && currentConsultant[0] ? currentConsultant[0].id : undefined
  );

  const userId = useUserStore(userIdSelector);

  const avatarName = displayTitle || channel.data?.name;
  const getFontWeight = (isBold: boolean) => (isBold ? 700 : 400);

  const handleChannelClick = () => {
    resetLastSelected();
    channel && setActiveChannel && setActiveChannel(channel);
    setSelectedUserId(Object.keys(channel.state.members).find(member => member !== userId));
  };

  useEffect(() => {
    if (consultantData) {
      addLastSelected(consultantData.id);
      setSelectedUserId(undefined);
    }

    return () => {
      resetLastSelected();
    };
  }, [consultantData]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box onClick={handleChannelClick} role="option" className={classes.container}>
      <Box>
        <Avatar image={displayImage} name={avatarName} size={40} />
      </Box>

      <Box className={classes.content}>
        <Box>
          <Typography variant={"subtitle1"} color={"textPrimary"} fontWeight={getFontWeight(unreadMessage)}>
            {avatarName}
          </Typography>
        </Box>

        <Box>
          <Typography variant={"caption"} className={classes.latestMessage} fontWeight={getFontWeight(unreadMessage)}>
            {latestMessage}
          </Typography>
        </Box>
      </Box>

      <ChannelOptions consultantCV={consultantData?.recentlyCVs} />
    </Box>
  );
};

export default CustomChannelPreview;
