import { Dayjs } from "dayjs";
import { Auth } from "firebase/auth";

import { BaseEntity, SelectOptionsType } from "./default";

export interface UserBaseTypes extends BaseEntity {
  firstName: string;
  lastName: string;
  email: string;
  companyId?: string;
  //TODO Update when users without id are removed from BE
  getStreamId?: string;
  imageUrl?: string;
}

export interface UserWithRole extends UserBaseTypes {
  role: UserRoles | null;
}

interface RegisterConsultantAdditionalFields {
  dailyRate?: string;
  jobAvailability?: Dayjs | number;
  interviewAvailability?: Dayjs | number;
  description?: string;
  consultantCv?: FormData | string;
  isMarked?: boolean;
  profileImage?: FormData;
}

export interface RegisterConsultantTypes extends UserBaseTypes, RegisterConsultantAdditionalFields {
  companyIds?: string[];
  skillIds?: string[];
  managerIds?: string[];
  jobRequirementsIds?: string[];
}

export interface RegisterUserTypesWithRole extends Omit<UserWithRole, "companyId">, RegisterConsultantTypes {}

export interface RegisterConsultantFormTypes extends UserBaseTypes, RegisterConsultantAdditionalFields {
  skillDetails: SelectOptionsType[];
  managerDetails: SelectOptionsType[];
  jobRequirementsDetails: SelectOptionsType[];
}

export interface RegisterUserTypes extends RegisterUserTypesWithRole {
  auth: Auth;
}

export interface UserReturnType {
  role: UserRoles;
  data: UserBaseTypes;
}

export enum UserRoles {
  manager = "MANAGER",
  superadmin = "SUPERADMIN",
  consultant = "CONSULTANT",
}

export interface TeamInfo {
  title: string;
  dateUpdated: string;
  members: number;
  consultantsData: ConsultantsData[];
}

export interface ConsultantsData extends RegisterConsultantFormTypes {
  managerFirstName: string;
  managerLastName: string;
  averageRating: number;
  sublistDetails: SelectOptionsType[];
  isFlagged: boolean;
  recentlyCVs: string;
  isInterviewed: boolean;
}

export interface jobRequirementsDetails extends BaseEntity {
  title: string;
}
export interface ConsultantsDataWithTitle extends Omit<ConsultantsData, "jobRequirementsDetails"> {
  jobRequirementsDetails: jobRequirementsDetails[];
}
