import { useContext } from "react";

import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import flaggedCvsService from "../../services/flaggedCvs";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";
import { MixpanelEvents } from "../../types/mixpanel";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useUnflagCv = () => {
  const { mixpanelTrack } = useContext(MixpanelContext);
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { mutate: unflagConsultantCv, isLoading } = useMutation(flaggedCvsService.unflagConsultantCv, {
    mutationKey: QUERY_STRINGS.UNFLAG_CV,
    onError: (data: ErrorContent) => addSnackbar({ text: data.message }),
    onSuccess: () => {
      mixpanelTrack(MixpanelEvents.UNFLAG_CV);

      queryClient.invalidateQueries([QUERY_STRINGS.GET_CONSULTANTS_BY_SUBLIST]);
      queryClient.invalidateQueries([QUERY_STRINGS.GET_FLAGGED_CVS]);
      queryClient.invalidateQueries([QUERY_STRINGS.GET_CONSULTANT_BY_ID]);
    },
  });

  return { unflagConsultantCv, isLoading };
};
