import { FC, useState } from "react";

import { Box, Button, FormHelperText, Typography } from "@mui/material";
import { ResourceKey } from "i18next";
import { Controller, ControllerRenderProps, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AWS_MAX_FILE_SIZE } from "../../constants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { BaseFormFieldProps, FieldOptionTypes } from "../../types/default";

interface Props extends BaseFormFieldProps {
  showPreviousUploadedFile?: boolean;
  fileType?: FieldOptionTypes;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

const FileField: FC<Props> = ({ label, formFieldProps, showPreviousUploadedFile, fileType }) => {
  const { t } = useTranslation();
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const [fileName, setFileName] = useState("");

  const { value, ref, ...rest } = formFieldProps.field;
  const { error } = formFieldProps.fieldState;

  const handleFileChange =
    (field: ControllerRenderProps<FieldValues, string>) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        if (e.target.files[0].size >= AWS_MAX_FILE_SIZE) {
          addSnackbar({ text: t("formFields:errors.maxFileSize") });
          return;
        }
        setFileName(e.target?.files[0].name);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        field.onChange(formData);
      }
    };

  const getFileType = (fileType?: FieldOptionTypes) => {
    if (fileType === FieldOptionTypes.IMAGE) {
      return ".png, .jpg, .jpeg";
    }

    if (fileType === FieldOptionTypes.FILE) {
      return ".pdf, .doc";
    }

    return ".pdf";
  };

  return (
    <Box>
      <Controller
        {...rest}
        render={({ field }) => (
          <>
            <Box marginBottom={1}>
              <Button variant={"contained"} component={"label"}>
                {t(label as ResourceKey)}
                <Box
                  component={"input"}
                  ref={ref}
                  hidden
                  accept={getFileType(fileType)}
                  type={"file"}
                  onChange={handleFileChange(field)}
                />
              </Button>
              <Typography variant={"caption"} paddingLeft={1}>
                {fileName}
              </Typography>
            </Box>
            {error && error.message && <FormHelperText>{error.message}</FormHelperText>}
          </>
        )}
      />

      {showPreviousUploadedFile && typeof value === "string" && (
        <Button variant={"contained"} href={value} LinkComponent={"a"} target={"_blank"} rel={"noopener noreferrer"}>
          {t("users:form.viewPreviousCV")}
        </Button>
      )}
    </Box>
  );
};

export default FileField;
