import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useConsultantById = (consultantId?: string | null) => {
  const addSnackBar = useSnackbar(addSnackBarSelector);

  const { isLoading, data: consultantData } = useQuery(
    [QUERY_STRINGS.GET_CONSULTANT_BY_ID, consultantId],
    () => consultantsService.getConsultantById(consultantId!),
    {
      enabled: !!consultantId,
      keepPreviousData: true,
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
    }
  );

  return {
    isLoading,
    consultantData,
  };
};
