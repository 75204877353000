import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import companyService from "../../services/companies";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

interface ReturnType {
  deleteCompany: UseMutateFunction<void, ErrorContent, string, unknown>;
  isLoading: boolean;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteCompany = (): ReturnType => {
  const addSnackBar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: deleteCompany, isLoading } = useMutation(companyService.deleteCompany, {
    mutationKey: QUERY_STRINGS.DELETE_COMPANY,
    onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_COMPANIES),
  });

  return { deleteCompany, isLoading };
};
