import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import skillsService from "../../services/skills";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useEditSkill = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { isLoading, mutate: editSkill } = useMutation(skillsService.editSkills, {
    mutationKey: QUERY_STRINGS.EDIT_SKILL,
    onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
    onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_SKILLS),
  });

  return { editSkill, isLoading };
};
