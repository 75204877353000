export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const TEXT_AND_NUMBERS_REGEX = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z])[a-zA-Z0-9 '~?!,.-]{3,}$/g;
export const DESCRIPTION_REGEX = /^[\S\s]{3,3000}$/g;
export const TEXT_REGEX = /^(?!\s)(?!.*\s$)([-'., a-zA-Z]){3,}$/g;
export const DATE_REGEX = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
export const NUMBER_REGEX = /^[0-9]+$/;
export const TYPEFORM_REGEX = /.typeform.com\/to\//;
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATEPICKER_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm a";
export const TOKEN = "kommlink-token";
export const DEFAULT_PAGE = 0;
export const DEFAULT_PER_PAGE = 10;
export const OVERVIEW_DEFAULT_PER_PAGE = 5;
export const DEFAULT_ROWS_PER_PAGE = 3;
export const MASTER_USER_LIST = "Master User List";
export const FLAGGED_ID = "flagged";
export const NULL_DAILY_RATE = "---";
export const STRING_FIELD_DEFAULT_VALUE = "";
export const AWS_MAX_FILE_SIZE = 5_242_880;

export const PATHS = {
  root: "/",
  login: "/login",
  superadmins: "/superadmins",
  managers: "/managers",
  consultants: "/consultants",
  consultantId: ":consultantId",
  upcomingInterviews: "/upcoming-interviews",
  jobs: "/jobs",
  sentQuestions: "/sent-questions",
  messages: "/messages",
  forgottenPassword: "/forgotten-password",
  sublist: "/sublist",
  sublistId: ":sublistId",
  // admin
  admin: "admin",
  companies: "/companies",
  skills: "/skills",
  upcomingCVs: "/upcoming-cvs",
};

export const QUERY_STRINGS = {
  USER_SIGNIN: "user-signin",
  REFRESH_TOKEN: "refresh_token",
  USER_LOGOUT: "user-logout",
  FORGOTTEN_PASSWORD: "forgotten-password",
  USER_INFO: "user-info",
  GET_USERS: "get-users",
  GET_SUPERADMINS: "get-superadmins",
  GET_MANAGERS_IN_COMPANY: "get-managers-in-company",
  GET_MANAGERS: "get-managers",
  GET_CONSULTANTS: "get-consultants",
  GET_CONSULTANT_BY_ID: "get-consultant-by-id",
  GET_CONSULTANTS_BY_SUBLIST: "get-consultants-by-sublist",
  CREATE_USER: "create-user",
  EDIT_USER: "edit-user",
  DELETE_USER: "delete-user",
  //companies
  CREATE_COMPANY: "create-company",
  GET_COMPANIES: "get-companies",
  DELETE_COMPANY: "delete-company",
  EDIT_COMPANY: "edit-company",
  //skills
  GET_SKILLS: "get-skills",
  CREATE_SKILL: "create-skill",
  EDIT_SKILL: "edit-skill",
  DELETE_SKILL: "delete-skill",
  //consultants
  RATE_CONSULTANT: "rate-consultant",
  EDIT_CONSULTANT: "edit-consultant",
  DELETE_CONSULTANT: "delete-consultant",
  MAKE_AN_OFFER: "make-an-offer",
  SEND_QUESTIONAIRE: "send-questionaire",
  SHARE_CONSULTANT: "share-consultant",
  //interviews
  GET_INTERVIEWS: "get-interviews",
  SCHEDULE_INTERVIEW: "schedule-interview",
  EDIT_INTERVIEW: "edit-interview",
  DELETE_INTERVIEW: "delete-interview",
  //requirements
  CREATE_REQUIREMENT: "create-requirement",
  CREATE_MANAGER_JOB_REQUIREMENT: "create-manager-job-requirement",
  EDIT_REQUIREMENT: "edit-requirement",
  DELETE_REQUIREMENT: "delete-requirement",
  GET_REQUIREMENTS: "get-requirements",
  ADD_CONSULTANT_TO_REQUIREMENT: "add-consultant-to-requirement",
  CHANGE_REQUIREMENT_STATUS: "change-requirement-status",
  //sublists
  GET_SUBLISTS: "get-sublists",
  CREATE_SUBLIST: "create-sublist",
  DELETE_SUBLIST: "delete-sublist",
  EDIT_SUBLIST: "edit-sublist",
  ADD_CONSULTANT_TO_SUBLIST: "add-consultant-to-sublist",
  REMOVE_CONSULTANT_FROM_SUBMIST: "remove-consultant-from-sublist",
  //messages
  SEND_FIRST_MESSAGE: "send-first-message",
  //flagged cvs
  GET_FLAGGED_CVS: "get-flagged-cvs",
  FLAG_CV: "flag-cv",
  UNFLAG_CV: "unflag-cv",
  //notifications
  SEND_NOTIFICATION: "send-notification",
  SEND_REGISTRATION_TOKEN: "send-registration-token",
  MARK_VIEWED_NOTIFICATION: "mark-viewed-notification",
  GET_NOTIFICATIONS: "get-notifications",
  UNREAD_NOTIFICATIONS: "unread-notifications",
  //questions
  CREATE_QUESTIONAIRE: "create-questionaire",
  GET_QUESTIONAIRES: "get-questionaires",
  EDIT_QUESTIONAIRE: "edit-questionaire",
  //upcoming CVs
  GET_UPCOMING_CVS: "get-upcoming-cvs",
  SEND_UPCOMING_CV: "send-upcoming-cv",
  DELETE_UPCOMING_CV: "delete-upcoming-cv",
};

export enum SnackbarStatus {
  error = "error",
  success = "success",
  info = "info",
  warning = "warning",
}
