import { FC } from "react";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { BaseFormFieldProps } from "../../types/default";

const useStyles = makeStyles()(theme => ({
  checkbox: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0),
  },
}));

const CheckboxField: FC<BaseFormFieldProps> = ({ label, formFieldProps }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { value, ref, ...rest } = formFieldProps.field;

  return (
    <FormControlLabel
      control={<Checkbox {...rest} checked={Boolean(value)} inputRef={ref} className={classes.checkbox} />}
      label={
        <Typography variant="subtitle1" color={"textPrimary"}>
          {t(label as ResourceKey)}
        </Typography>
      }
    />
  );
};

export default CheckboxField;
