import dayjs from "dayjs";

import { FieldOptionTypes, FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { QuestionsBody } from "../../../types/questions";

export const createQuestionsBuilder: FormConstructor<QuestionsBody> = {
  fields: [
    {
      name: "date",
      label: "questions:fields.date",
      type: FieldType.date,
      defaultValue: dayjs(),
      validationOptions: {
        required: true,
      },
      disablePastDate: false,
    },
    {
      name: "consultant",
      label: "questions:fields.consultant",
      validationOptions: {
        required: true,
      },
      type: FieldType.select,
      defaultValue: "",
      options: [],
      isFullWidth: true,
    },
    {
      name: "sentQuestions",
      label: "questions:fields.questions",
      type: FieldType.file,
      validationOptions: {
        required: true,
      },
      fileType: FieldOptionTypes.FILE,
      defaultValue: "",
    },
    {
      name: "answers",
      label: "questions:fields.answers",
      type: FieldType.file,
      defaultValue: "",
      fileType: FieldOptionTypes.FILE,
    },
  ],
};
