import { FC, useState } from "react";

import { Add } from "@mui/icons-material";
import { Breakpoint, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { PATHS } from "../../constants";
import { BaseEntityName, FormConstructor } from "../../types/default";
import FormConsumer from "../forms/FormConsumer";
import ModalContainer from "../ModalContainer";

interface Props {
  btnLabel: string;
  modalTitle: string;
  formConfig: FormConstructor<BaseEntityName>;
  modalBtnTitle: string;
  btnGapLarge?: boolean;
  size: Breakpoint;
  handleSubmitForm: (data: BaseEntityName) => void;
}

const NewSkillButton: FC<Props> = ({
  btnLabel,
  modalTitle,
  formConfig,
  modalBtnTitle,
  btnGapLarge,
  size,
  handleSubmitForm,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [openModal, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);

  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      {location.pathname === PATHS.root && (
        <Button
          variant={"contained"}
          color={"primary"}
          startIcon={<Add />}
          onClick={handleOpenModal}
          aria-label={t("main:newSkill.btnAriaLabel")}
        >
          <Typography variant={"button"}>{btnLabel}</Typography>
        </Button>
      )}

      <ModalContainer isOpened={openModal} title={modalTitle} closeModal={handleCloseModal} size={size}>
        <FormConsumer<BaseEntityName>
          closeModal={handleCloseModal}
          btnLabel={modalBtnTitle}
          formConfig={formConfig}
          btnGapLarge={btnGapLarge}
          handleSubmitForm={handleSubmitForm}
        />
      </ModalContainer>
    </>
  );
};

export default NewSkillButton;
