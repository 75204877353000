import kommlinkApi, { BackendError } from "../lib/axios";
import { PaginatedData, SelectOptionsType } from "../types/default";

const createSkill = async (name: string): Promise<void> => {
  try {
    await kommlinkApi.post<void>("/skills", { name });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const getSkills = async (page?: number, perPage?: number): Promise<PaginatedData<SelectOptionsType>> => {
  try {
    const res = await kommlinkApi.get<PaginatedData<SelectOptionsType>>("/skills", {
      params: {
        page,
        perPage,
      },
    });

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editSkills = async ({ id, name }: SelectOptionsType) => {
  try {
    await kommlinkApi.patch<void>(`/superadmin/skills/${id}`, { name });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteSkills = async (id: string) => {
  try {
    await kommlinkApi.delete<void>(`/superadmin/skills/${id}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const skillsService = {
  createSkill,
  getSkills,
  editSkills,
  deleteSkills,
};

export default skillsService;
