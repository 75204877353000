import { FC } from "react";

import { Edit, DeleteOutline } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

interface Props {
  deleteClick?: () => void;
  editClick?: () => void;
}

const TableActions: FC<Props> = ({ deleteClick, editClick }) => {
  return (
    <Box display={"flex"} justifyContent={"flex-end"}>
      {editClick && (
        <IconButton onClick={editClick}>
          <Edit color={"primary"} />
        </IconButton>
      )}
      {deleteClick && (
        <IconButton onClick={deleteClick}>
          <DeleteOutline color={"primary"} />
        </IconButton>
      )}
    </Box>
  );
};

export default TableActions;
