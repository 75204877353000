import { useContext } from "react";

import { Auth } from "firebase/auth";
import { UseMutateFunction, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { PATHS, QUERY_STRINGS } from "../../constants";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import authenticationService from "../../services/authentication";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { ErrorContent } from "../../types/default";

type ReturnType = {
  handleLogout: UseMutateFunction<void, ErrorContent, Auth, unknown>;
};

const resetUserSelector = (state: UserState) => state.resetUserInfo;
const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useLogout = (): ReturnType => {
  const { mixpanelLogout } = useContext(MixpanelContext);
  const navigate = useNavigate();

  const resetUserInfo = useUserStore(resetUserSelector);
  const addSnackBar = useSnackbar(addSnackbarSelector);

  const { mutate: handleLogout } = useMutation(authenticationService.firebaseLogout, {
    mutationKey: QUERY_STRINGS.USER_LOGOUT,
    onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
    onSuccess: () => {
      resetUserInfo();
      mixpanelLogout();
      navigate(PATHS.login);
    },
  });

  return { handleLogout };
};
