import { FC, useCallback } from "react";

import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { ReactComponent as DropzoneIcon } from "../../assets/dropzoneIcon.svg";
import { AWS_MAX_FILE_SIZE } from "../../constants";
import { useSendUpcomingCV } from "../../hooks/upcomingCVs/useSendUpcomingCV";
import Loading from "../../layouts/Loading";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";

const useStyles = makeStyles<{ isDragReject: boolean }>()((theme, { isDragReject }) => ({
  dropzoneContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
  },
  dropzone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "259px",
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: !isDragReject ? theme.palette.dropzoneText : theme.palette.error.main,
    cursor: "pointer",
    overflow: "hidden",
    boxSizing: "border-box",
    minHeight: "183px",
    borderRadius: theme.spacing(0.5),
    backgroundColor: !isDragReject ? "rgba(26,115,232,0.38)" : "rgba(255, 59, 48, 0.38)",
  },
  dropzoneTitle: {
    fontWeight: 400,
    letterSpacing: "0.25px",
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    color: theme.palette.dropzoneText,
  },
  iconContainer: {
    width: theme.spacing(6.25),
    height: theme.spacing(6.25),
    borderRadius: "50%",
    backgroundColor: "rgba(255,255,255,0.06)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropzoneAdditionalText: {
    marginTop: theme.spacing(2),
    width: "136px",
    height: theme.spacing(5),
    textAlign: "center",
    bottom: theme.spacing(2.5),
    color: theme.palette.dropzoneText,
    fontWeight: 400,
    letterSpacing: "0.25px",
  },
}));

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

const Dropzone: FC = () => {
  const { t } = useTranslation();
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const { isLoading, sendUpcomingCV } = useSendUpcomingCV();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0].size >= AWS_MAX_FILE_SIZE) {
      addSnackbar({ text: t("formFields:errors.maxFileSize") });
      return;
    }
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    sendUpcomingCV(formData);
  }, []);

  const { getRootProps, isDragReject } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  });

  const { classes } = useStyles({ isDragReject });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box className={classes.dropzoneContainer}>
      <Box {...getRootProps()} className={classes.dropzone}>
        <Typography variant={"subtitle2"} className={classes.dropzoneTitle}>
          {t("sidebar:dropzone.title")}
        </Typography>
        <Box className={classes.iconContainer}>
          <DropzoneIcon />
        </Box>
        <Typography variant={"subtitle2"} fontWeight={400} className={classes.dropzoneAdditionalText}>
          {t("sidebar:dropzone.subTitle")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Dropzone;
