import kommlinkApi, { BackendError } from "../lib/axios";
import { Sublist, SublistBody } from "../types/sublist";

const getSublists = async (): Promise<Sublist[]> => {
  try {
    const res = await kommlinkApi.get<Sublist[]>("/sublists");

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const createSublist = async (data: SublistBody): Promise<void> => {
  try {
    await kommlinkApi.post<void>("/sublists", data);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const editSublist = async ({ data, id }: { data: SublistBody; id: string }): Promise<void> => {
  try {
    await kommlinkApi.patch<void>(`/sublists/${id}`, data);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const deleteSublist = async (id: string): Promise<void> => {
  try {
    await kommlinkApi.delete(`/sublists/${id}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const addConsultantToSublist = async ({
  consultantId,
  sublistId,
}: {
  consultantId: string;
  sublistId: string;
}): Promise<void> => {
  try {
    await kommlinkApi.post<void>(`/sublists/${sublistId}/consultants/${consultantId}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const removeConsultantFromSublist = async ({
  consultantId,
  sublistId,
}: {
  consultantId: string;
  sublistId: string;
}): Promise<void> => {
  try {
    await kommlinkApi.delete<void>(`/sublists/${sublistId}/consultants/${consultantId}`);
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const sublistService = {
  createSublist,
  getSublists,
  editSublist,
  deleteSublist,
  addConsultantToSublist,
  removeConsultantFromSublist,
};

export default sublistService;
