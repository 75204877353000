import { FC, ReactElement, useState } from "react";

import { RemoveCircleOutline } from "@mui/icons-material";
import { Box, Collapse, Divider, IconButton, Tab, Tabs, Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { Actions, ConsultantInfoModalContent, ConsultantInfoModalHeader, History } from "..";
import { ReactComponent as Account } from "../../assets/account.svg";
import { ReactComponent as Settings } from "../../assets/settings.svg";
import { ReactComponent as SyncClock } from "../../assets/sync-clock.svg";
import { DATE_FORMAT, NULL_DAILY_RATE } from "../../constants";
import { useConsultantById } from "../../hooks/consultants/useConsultantById";
import { ConsultantsState, useConsultantsStore } from "../../store/useConsultantsStore";
import { useUserStore } from "../../store/useUserStore";
import { ConsultantInfoTypes } from "../../types/rightSidebar";
import { UserRoles } from "../../types/users";

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    top: 20,
    right: 0,
    zIndex: 1,
  },
  tabGroup: {
    borderRadius: theme.spacing(0),
    height: "228px",
    overflow: "hidden",
    position: "relative",
    boxShadow: open ? theme.shadows[0] : theme.shadows[2],
    backgroundColor: theme.palette.secondary.main,
    zIndex: 2,
  },
  iconButton: {
    borderBottom: `1px solid ${theme.palette.dark.light}`,
    height: theme.spacing(9.5),
  },
  rightBorderHidden: {
    borderRight: "none",
  },
  rightBorderShown: {
    borderRight: `1px solid ${theme.palette.dark.light}`,
  },
  optionIcons: {
    height: "100%",
    alignItems: "flex-start",
  },
  content: {
    width: "580px",
    height: "680px",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(3, 4, 4, 4),
    zIndex: 1,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  fakeBox: {
    height: "228px",
    width: "670px",
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
  contentCollapse: {
    zIndex: 1,
  },
}));

const lastSelectedConsultantSelector = (state: ConsultantsState) => state.lastSelectedConsultant;

const VerticalButtonsModal: FC = () => {
  const { t } = useTranslation();
  const lastSelectedConsultant = useConsultantsStore(lastSelectedConsultantSelector);
  const userRole = useUserStore(state => state.role);

  const { consultantData } = useConsultantById(lastSelectedConsultant);

  const [tabsValue, setTabsValue] = useState<number | boolean>(false);
  const [open, setOpen] = useState(false);

  const { classes } = useStyles({ open });

  const tabs: { icon: ReactElement }[] = [{ icon: <Account /> }, { icon: <Settings /> }, { icon: <SyncClock /> }];

  const handleTabClick = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabsValue(newValue);

  const handleCloseSidebar = () => {
    setTabsValue(false);
    setOpen(false);
  };

  const consultantInfoConfig: ConsultantInfoTypes[] = [
    {
      title: "main:tableHeaders.manager",
      info: consultantData?.managerDetails.map(manager => manager.name).toString() ?? "",
      isPrivate: true,
    },
    {
      title: "main:tableHeaders.dailyRate",
      info: consultantData?.dailyRate ?? NULL_DAILY_RATE,
      isPrivate: false,
    },
    {
      title: "main:tableHeaders.availability",
      info: consultantData ? dayjs(consultantData.jobAvailability).format(DATE_FORMAT) : "",
      isPrivate: false,
    },
  ];

  return (
    <Box className={classes.container}>
      <Tabs value={tabsValue} onChange={handleChange} orientation={"vertical"} className={classes.tabGroup}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className={clsx(
              classes.iconButton,
              tabsValue === index ? classes.rightBorderHidden : classes.rightBorderShown
            )}
            onClick={handleTabClick}
            icon={tab.icon}
          />
        ))}
      </Tabs>

      <Box className={classes.fakeBox} />

      {lastSelectedConsultant && consultantData && (
        <Collapse
          in={open && !!lastSelectedConsultant}
          orientation={"horizontal"}
          unmountOnExit
          className={classes.contentCollapse}
        >
          <Box className={classes.content}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <ConsultantInfoModalHeader large data={consultantData} />
              <IconButton className={classes.optionIcons} onClick={handleCloseSidebar}>
                <RemoveCircleOutline color={"secondary"} />
              </IconButton>
            </Box>

            <Box display={"flex"} gap={3} paddingTop={3}>
              {consultantInfoConfig.map(infoElement =>
                userRole === UserRoles.manager && infoElement.isPrivate ? null : (
                  <Box key={infoElement.title as string}>
                    <Typography variant="subtitle2" paddingBottom={0.5}>
                      {t(infoElement.title)}
                    </Typography>
                    <Typography variant="subtitle1" color={"primary"} fontWeight={700}>
                      {infoElement.info}
                    </Typography>
                  </Box>
                )
              )}
            </Box>

            <Divider className={classes.divider} />

            {tabsValue === 0 && <ConsultantInfoModalContent consultantDescription={consultantData.description} />}
            {tabsValue === 1 && <Actions consultant={consultantData} />}
            {tabsValue === 2 && <History consultant={consultantData} />}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};
export default VerticalButtonsModal;
