import { FC } from "react";

import AdapterDayjs from "@date-io/dayjs";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ResourceKey, t } from "i18next";

import { DATE_FORMAT } from "../../constants";
import { BaseFormFieldProps } from "../../types/default";

interface Props extends BaseFormFieldProps {
  disablePastDate?: boolean;
}

const CustomDatePicker: FC<Props> = ({ label, formFieldProps, disablePastDate }) => {
  const { ...rest } = formFieldProps.field;
  const { error } = formFieldProps.fieldState;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...rest}
        renderInput={props => (
          <TextField
            {...props}
            fullWidth
            helperText={error && error.message}
            error={Boolean(error && error.message)}
            margin={"normal"}
          />
        )}
        label={t(label as ResourceKey)}
        disablePast={disablePastDate}
        inputFormat={DATE_FORMAT}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
