import { FC, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { MakeAnOffer, ModalContainer, ScheduleInterview } from "..";
import { ReactComponent as MakeAnOfferIcon } from "../../assets/make-an-offer.svg";
import { ReactComponent as ScheduleInterviewIcon } from "../../assets/schedule-interview.svg";
import { ReactComponent as SendQuestions } from "../../assets/send-questions.svg";
import { ReactComponent as ShareConsultantIcon } from "../../assets/share-consultant.svg";
import { useSendQuestionaire } from "../../hooks/consultants/useSendQuestionaire";
import { useShareConsultant } from "../../hooks/consultants/useShareConsultant";
import { useManagers } from "../../hooks/users/useManagers";
import Loading from "../../layouts/Loading";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { UserState, useUserStore } from "../../store/useUserStore";
import { BaseUserProfileActions, ShareConsultant, SidebarActionsModalType } from "../../types/rightSidebar";
import { ConsultantsData, UserRoles } from "../../types/users";
import { shareConsultantBuilder } from "../forms/builders/shareConsultantBuilder";
import FormConsumer from "../forms/FormConsumer";

const useStyles = makeStyles()(theme => ({
  button: {
    justifyContent: "start",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1.5, 2),
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primaryWithOpacity.dark,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1.5),
  },
}));

interface Props {
  consultant: ConsultantsData;
}

const userRoleSelector = (state: UserState) => state.role;
const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

const Actions: FC<Props> = ({ consultant }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { sendQuestionaire } = useSendQuestionaire();
  const userRole = useUserStore(userRoleSelector);
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { usersData, isLoading } = useManagers();
  const { shareConsultant, isLoading: isSharingLoading } = useShareConsultant();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<SidebarActionsModalType | null>(null);

  const handleOpenModal = (type: SidebarActionsModalType) => () => {
    setModalOpen(true);
    setModalType(type);
  };
  const handleCloseModal = () => setModalOpen(false);

  const handleSendQuestionaire = () => {
    if (userRole === UserRoles.manager) {
      sendQuestionaire(consultant.id);
      return;
    }

    addSnackbar({ type: "warning", text: t("userProfile:actions.sendQuestions.errors.notAllowedSendQuestions") });
  };

  const handleSubmitShareConsultant = (data: ShareConsultant) =>
    shareConsultant({ managerId: data.manager, consultantId: consultant.id });

  const buttonsConfig: BaseUserProfileActions[] = [
    {
      label: "userProfile:actions.buttons.sendQuestions",
      icon: <SendQuestions />,
      action: handleSendQuestionaire,
    },
    {
      label: "userProfile:actions.buttons.scheduleInterview",
      icon: <ScheduleInterviewIcon />,
      action: handleOpenModal(SidebarActionsModalType.SCHEDULE_INTERVIEW),
    },
    {
      label: "userProfile:actions.buttons.makeAnOffer",
      icon: <MakeAnOfferIcon />,
      action: handleOpenModal(SidebarActionsModalType.MAKE_AN_OFFER),
    },
    {
      label: "userProfile:actions.buttons.shareConsultant",
      icon: <ShareConsultantIcon />,
      action: handleOpenModal(SidebarActionsModalType.SHARE_CONSULTANT),
    },
  ];

  const getModalTitle = () => {
    switch (modalType) {
      case SidebarActionsModalType.MAKE_AN_OFFER:
        return t("userProfile:actions.makeAnOffer.title");
      case SidebarActionsModalType.SCHEDULE_INTERVIEW:
        return t("userProfile:actions.scheduleAnInterview.title");
      case SidebarActionsModalType.SHARE_CONSULTANT:
        return t("userProfile:actions.shareConsultant.title");
      default:
        return t("userProfile:actions.makeAnOffer.title");
    }
  };

  if (isLoading || !usersData || isSharingLoading) {
    return <Loading />;
  }

  const managersData = usersData.data.map(el => ({ id: el.id, name: `${el.firstName} ${el.lastName}` }));

  const shareConsultantFieldsWithManagers = shareConsultantBuilder.fields.map(field => ({
    ...field,
    options: managersData,
  }));

  return (
    <>
      <Typography variant={"subtitle1"} color={"textSecondary"} marginBottom={3}>
        {t("userProfile:actions.title")}
      </Typography>

      {buttonsConfig.map((button, index) => (
        <Button
          key={index}
          variant={"outlined"}
          fullWidth
          size={"large"}
          className={classes.button}
          startIcon={<Box className={classes.iconContainer}>{button.icon}</Box>}
          onClick={button.action}
        >
          <Typography variant={"button"}>{t(button.label)}</Typography>
        </Button>
      ))}

      <ModalContainer
        isOpened={modalOpen}
        title={getModalTitle()}
        closeModal={handleCloseModal}
        size={modalType === SidebarActionsModalType.SCHEDULE_INTERVIEW ? "xs" : "sm"}
      >
        {modalType === SidebarActionsModalType.MAKE_AN_OFFER && (
          <MakeAnOffer
            closeModal={handleCloseModal}
            consultantRate={consultant.dailyRate}
            consultantId={consultant.id}
          />
        )}

        {modalType === SidebarActionsModalType.SCHEDULE_INTERVIEW && (
          <ScheduleInterview closeModal={handleCloseModal} consultantId={consultant.id} />
        )}

        {modalType === SidebarActionsModalType.SHARE_CONSULTANT && (
          <FormConsumer<ShareConsultant>
            closeModal={handleCloseModal}
            btnLabel={t("userProfile:actions.shareConsultant.shareBtn")}
            formConfig={{ ...shareConsultantBuilder, fields: shareConsultantFieldsWithManagers }}
            btnGapLarge={true}
            handleSubmitForm={handleSubmitShareConsultant}
          />
        )}
      </ModalContainer>
    </>
  );
};

export default Actions;
