import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent, PaginatedData } from "../../types/default";
import { FilterOptions } from "../../types/main";
import { ConsultantsData } from "../../types/users";

interface ReturnType {
  isLoading: boolean;
  usersData?: PaginatedData<ConsultantsData>;
}

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useConsultants = (
  page?: number,
  perPage?: number,
  handleChangePage?: (newPage: number) => void,
  filterOptions?: FilterOptions
): ReturnType => {
  const addSnackBar = useSnackbar(addSnackBarSelector);

  const { isLoading, data: usersData } = useQuery(
    [QUERY_STRINGS.GET_CONSULTANTS, page, perPage, filterOptions],
    () => consultantsService.getConsultants(page, perPage, filterOptions),
    {
      keepPreviousData: true,
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
      onSuccess: data => {
        !data.data.length && data.totalElements && page && handleChangePage && handleChangePage(page - 1);
      },
    }
  );

  return {
    isLoading,
    usersData,
  };
};
