import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import interviewsService from "../../services/interviews";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useUpcomingInterviews = (page: number, perPage: number, handleChangePage?: (newPage: number) => void) => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { data: upcomingInterviewsData, isLoading } = useQuery(
    [QUERY_STRINGS.GET_INTERVIEWS, page, perPage],
    () => interviewsService.getUpcomingInterviews(page, perPage),
    {
      keepPreviousData: true,
      onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
      onSuccess: data =>
        !data.data.length && data.totalElements && page && handleChangePage && handleChangePage(page - 1),
    }
  );

  return { upcomingInterviewsData, isLoading };
};
