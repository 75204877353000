import { EMAIL_REGEX } from "../../../constants";
import { EmailType } from "../../../types/authentication";
import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";

export const forgottenPasswordConfig: FormConstructor<EmailType> = {
  fields: [
    {
      name: "email",
      label: "login:form.formFields.email",
      validationOptions: {
        required: true,
        pattern: {
          pattern: EMAIL_REGEX,
          label: "login:form.errors.invalidEmail",
        },
      },
      type: FieldType.text,
      defaultValue: "",
    },
  ],
};
