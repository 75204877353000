import { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  btnGapLarge?: boolean;
}

const FormSubmitButton: FC<Props> = ({ label, btnGapLarge }) => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} justifyContent={"flex-end"} marginTop={btnGapLarge ? 7 : 3}>
      <Button variant="contained" color="primary" type="submit" aria-label={t("formFields:btnAriaLabel")}>
        <Typography variant={"button"}>{label}</Typography>
      </Button>
    </Box>
  );
};

export default FormSubmitButton;
