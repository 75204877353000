import { useMutation } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import messagesService from "../../services/messages";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useSendFirstMessage = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const { mutate: sendFirstMessage, isLoading } = useMutation(messagesService.sendFirstMessageToConsultant, {
    mutationKey: QUERY_STRINGS.SEND_FIRST_MESSAGE,
    onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
  });

  return { sendFirstMessage, isLoading };
};
