import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { UserState, useUserStore } from "../store/useUserStore";
import { OrderType } from "../types/default";
import { UserRoles } from "../types/users";
import Pagination from "./Pagination";

const useStyles = makeStyles()(() => ({
  tableHeaderFirstCell: {
    borderLeft: "6px solid transparent",
  },
}));

interface TableBorder {
  borderLeft: string;
}

export interface ColumnData<T> {
  dataKey: string;
  label?: string | React.ReactNode[] | React.ReactNode;
  dataRender: (data: T) => string | React.ReactNode[] | React.ReactNode;
  sortable: boolean;
  selectable?: boolean;
  hasOptions?: boolean;
  centered?: boolean;
  private?: boolean;
}

export interface Column<TType> {
  columns: ColumnData<TType>[];
  rowStyles?: (data: TType) => TableBorder | null;
  isRowSelected?: (data: TType) => boolean;
  rowClickFn?: (data: TType) => () => void;
}

interface TProps<TType> {
  tableBuilder: Column<TType>;
  data: TType[];
  showPagination: boolean;
  page?: number;
  perPage?: number;
  totalElements?: number;
  handleChangePage?: (page: number) => void;
  handleChangePerPage?: (perPage: number) => void;
  showRowsPerPage?: boolean;
  order?: OrderType;
  sortBy?: keyof TType;
  handleSortClick?: (name: keyof TType) => void;
}

const userRoleSelector = (state: UserState) => state.role;

function CustomTable<Type>(props: TProps<Type>): JSX.Element {
  const {
    tableBuilder,
    data,
    page,
    perPage,
    handleChangePerPage,
    handleChangePage,
    totalElements,
    order,
    sortBy,
    handleSortClick,
    showPagination,
    showRowsPerPage,
  } = props;

  const { classes } = useStyles();
  const userRole = useUserStore(userRoleSelector);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={tableBuilder.rowStyles && classes.tableHeaderFirstCell}>
              {tableBuilder.columns.map(header =>
                userRole === UserRoles.manager && header.private ? null : (
                  <TableCell key={header.dataKey as string} align={header.centered ? "center" : "left"}>
                    {header.sortable ? (
                      <TableSortLabel
                        direction={sortBy === header.dataKey ? order : undefined}
                        onClick={() => handleSortClick && handleSortClick(header.dataKey as keyof Type)}
                      >
                        <Typography variant={"subtitle1"} color={"textSecondary"}>
                          {header.label}
                        </Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography variant={"subtitle1"} color={"textSecondary"}>
                        {header.label}
                      </Typography>
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                hover
                key={index}
                selected={tableBuilder.isRowSelected && tableBuilder.isRowSelected(row)}
                sx={tableBuilder.rowStyles && { borderLeft: tableBuilder.rowStyles(row) }}
                onClick={tableBuilder.rowClickFn && tableBuilder.rowClickFn(row)}
              >
                {tableBuilder.columns.map(column =>
                  userRole === UserRoles.manager && column.private ? null : (
                    <TableCell
                      key={`${column.dataKey}-${index}`}
                      size={"small"}
                      align={column.centered ? "center" : "left"}
                    >
                      <Typography variant={"subtitle1"} color={"textSecondary"}>
                        {column.dataRender(row)}
                      </Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showPagination && handleChangePage && (
        <Pagination
          page={page}
          perPage={perPage}
          totalElements={totalElements}
          handleChangePage={handleChangePage}
          handleChangePerPage={handleChangePerPage}
          showRowsPerPage={showRowsPerPage}
        />
      )}
    </>
  );
}

export default CustomTable;
