import { useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { t } from "i18next";

import { editUpcomingInterviewBuilder } from "../../components/forms/builders/editUpcomingConsultantBuilder";
import { scheduleInterviewBuilder } from "../../components/forms/builders/scheduleInterviewBuilder";
import { FormFieldsConstructor, SelectOptionsType } from "../../types/default";
import { InterviewType, ScheduleInterviewResponse, ScheduleInterviewTypes } from "../../types/interviews";

export const useInterviewFormBuilder = () => {
  const [currentInterview, setCurrentInterview] = useState<ScheduleInterviewResponse | null>(null);

  const interviewTypes: SelectOptionsType[] = [
    {
      id: InterviewType.PHONE,
      name: t("userProfile:actions.scheduleAnInterview.formFields.interviewTypes.PHONE") as string,
    },
    {
      id: InterviewType.VIDEO,
      name: t("userProfile:actions.scheduleAnInterview.formFields.interviewTypes.VIDEO") as string,
    },
  ];

  const getDefaultValue = (field: FormFieldsConstructor<ScheduleInterviewTypes<Dayjs>>) => {
    if (currentInterview) {
      if (field.name === "interviewDate" || field.name === "timeFrom" || field.name === "timeTo") {
        return dayjs(currentInterview[field.name]);
      }
      return currentInterview[field.name];
    }

    return field.defaultValue;
  };

  const editInterviewFormBuilderFields = editUpcomingInterviewBuilder.fields.map(field => ({
    ...field,
    defaultValue: getDefaultValue(field),
    options: interviewTypes,
  }));

  const builderFieldsWithInterviewTypes = scheduleInterviewBuilder.fields.map(field => ({
    ...field,
    options: interviewTypes,
  }));

  const handleAddCurrentInterview = (interview: ScheduleInterviewResponse) => setCurrentInterview(interview);

  return {
    editInterviewFormBuilderFields,
    handleAddCurrentInterview,
    currentInterview,
    builderFieldsWithInterviewTypes,
  };
};
