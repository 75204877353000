import { useInfiniteQuery } from "react-query";

import { DEFAULT_PER_PAGE, QUERY_STRINGS } from "../../constants";
import notificationsService from "../../services/notifications";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useNotifications = () => {
  const addSnackBar = useSnackbar(addSnackBarSelector);

  const {
    isLoading,
    data: notifications,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [QUERY_STRINGS.GET_NOTIFICATIONS],
    ({ pageParam }) => notificationsService.getNotifications(pageParam),

    {
      getNextPageParam: ({ totalElements, currentPage, data }) =>
        Math.floor(totalElements / DEFAULT_PER_PAGE) > currentPage &&
        currentPage < Math.floor(totalElements / data.length)
          ? currentPage + 1
          : undefined,
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
    }
  );

  return {
    isLoading,
    notifications,
    hasNextPage,
    fetchNextPage,
    // totalNotifications,
  };
};
