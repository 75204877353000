import { FC } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, JobOrderTable } from "../components";

const JobOrder: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="description" content={t("jobOrderPage:description")} />
        <title>
          {t("jobOrderPage:title")} | {t("kommlink:title")}
        </title>
      </Helmet>
      <CardContentSection title={t("jobOrderPage:title")} table={<JobOrderTable />} />
    </>
  );
};

export default JobOrder;
