import { FC, useState } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, CardHeader } from "../components";
import { skillConfig } from "../components/forms/builders/skillBuilder";
import SkillsTable from "../components/tables/SkillsTable";
import { useCreateSkill } from "../hooks/skills/useCreateSkill";
import { useEditSkill } from "../hooks/skills/useEditSkill";
import Loading from "../layouts/Loading";
import { BaseEntityName, SelectOptionsType } from "../types/default";

const Skills: FC = () => {
  const { t } = useTranslation();

  const { createSkill, isLoading } = useCreateSkill();
  const { editSkill, isLoading: isEditLoading } = useEditSkill();

  const [editMode, setEditMode] = useState(false);
  const [currentSkillData, setCurrentSkillData] = useState<SelectOptionsType | null>(null);

  const handleSubmitForm = (skill: BaseEntityName) => createSkill(skill.name);
  const handleEditForm = (skill: BaseEntityName) => editSkill({ id: currentSkillData!.id, name: skill.name });

  const handleChangeMode = (data: SelectOptionsType) => () => {
    setEditMode(true);
    setCurrentSkillData(data);
  };

  const handleCloseEditMode = () => setEditMode(false);

  const editSkillConfig = skillConfig.fields.map(el => {
    return {
      ...el,
      defaultValue: currentSkillData ? currentSkillData[el.name] : el.defaultValue,
    };
  });

  if (isLoading || isEditLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("skills:description")} />
        <title>
          {t("skills:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <CardHeader
        title={t("skills:title")}
        btnLabel={t("main:newSkill.addSkill")}
        modalTitle={editMode ? t("skills:editSkill") : t("main:newSkill.title")}
        formConfig={editMode ? { ...skillConfig, fields: editSkillConfig } : skillConfig}
        modalBtnTitle={editMode ? t("formFields:buttons.done") : t("main:newSkill.btnText")}
        size={"md"}
        handleSubmitForm={editMode ? handleEditForm : handleSubmitForm}
        isEditMode={editMode}
        closeEditMode={handleCloseEditMode}
      />
      <CardContentSection table={<SkillsTable handleChangeMode={handleChangeMode} />} />
    </>
  );
};

export default Skills;
