import { UserWithRole } from "../types/users";
import { createStore } from "./storeUtils";

export type UsersState = {
  data: UserWithRole[];
  addUsersData: (data: UserWithRole[]) => void;
  resetUsersData: () => void;
};

const initialState = {
  data: [],
};

export const useUsersStore = createStore<UsersState>("users", set => ({
  ...initialState,
  addUsersData: (data: UserWithRole[]) => set(state => ({ ...state, data })),
  resetUsersData: () => set(() => initialState),
}));
