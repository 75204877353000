import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import skillsService from "../../services/skills";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteSkill = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { isLoading, mutate: deleteSkill } = useMutation(skillsService.deleteSkills, {
    mutationKey: QUERY_STRINGS.DELETE_SKILL,
    onError: (err: ErrorContent) => addSnackbar({ text: err.message }),
    onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_SKILLS),
  });

  return { deleteSkill, isLoading };
};
