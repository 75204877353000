import { FC, useState } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, CardHeader, CompaniesTable } from "../components";
import { addCompanyConfig } from "../components/forms/builders/addCompanyBuilder";
import { useCreateCompany } from "../hooks/company/useCreateCompany";
import { useEditCompany } from "../hooks/company/useEditCompany";
import Loading from "../layouts/Loading";
import { CompaniesData } from "../types/companies";
import { SelectOptionsType } from "../types/default";

const Companies: FC = () => {
  const { t } = useTranslation();
  const { createCompany, isLoading } = useCreateCompany();
  const { isLoading: isEditingCompany, editCompany } = useEditCompany();

  const [editMode, setEditMode] = useState(false);
  const [currentCompanyData, setCurrentCompanyData] = useState<CompaniesData | null>(null);

  const handleSubmitForm = (data: SelectOptionsType) => createCompany(data.name);
  const handleEditForm = (data: SelectOptionsType) =>
    editCompany({ companyId: currentCompanyData!.id, name: data.name });

  const handleChangeMode = (data: CompaniesData) => () => {
    setEditMode(true);
    setCurrentCompanyData(data);
  };

  const handleCloseEditMode = () => setEditMode(false);

  const editCompanyConfig = addCompanyConfig.fields.map(el => ({
    ...el,
    defaultValue: currentCompanyData ? currentCompanyData[el.name] : el.defaultValue,
  }));

  if (isLoading || isEditingCompany) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("companies:description")} />
        <title>
          {t("companies:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <CardHeader
        title={t("sidebar:companies")}
        btnLabel={t("companies:addCompany")}
        modalTitle={editMode ? t("companies:editCompany") : t("companies:addNewCompany")}
        formConfig={editMode ? { ...addCompanyConfig, fields: editCompanyConfig } : addCompanyConfig}
        modalBtnTitle={editMode ? t("formFields:buttons.done") : t("companies:addCompany")}
        size={"md"}
        handleSubmitForm={editMode ? handleEditForm : handleSubmitForm}
        isEditMode={editMode}
        closeEditMode={handleCloseEditMode}
      />
      <CardContentSection table={<CompaniesTable handleChangeMode={handleChangeMode} />} />
    </>
  );
};

export default Companies;
