import { createTheme } from "@mui/material/styles";

import { shadows } from "./shadows";

declare module "@mui/material/styles" {
  interface Palette {
    icon: {
      light?: string;
      main: string;
      dark?: string;
    };
    dropzoneText: string;
    textLight: {
      light?: string;
      main: string;
      dark?: string;
    };
    dark: {
      light?: string;
      main: string;
      dark?: string;
      darker?: string;
    };
    primaryWithOpacity: {
      light?: string;
      main: string;
      dark?: string;
    };
  }

  interface PaletteOptions {
    icon?: {
      light?: string;
      main: string;
      dark?: string;
    };
    dropzoneText?: string;
    textLight?: {
      light?: string;
      main: string;
      dark?: string;
    };
    dark?: {
      light?: string;
      main: string;
      dark?: string;
      darker?: string;
    };
    primaryWithOpacity?: {
      light?: string;
      main: string;
      dark?: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      dark: "#2C323E",
      main: "#1A73E8",
      light: "#A3C7F6",
    },
    secondary: {
      light: "rgba(0,0,0,0.08)",
      main: "#FFFFFF",
    },
    error: {
      light: "#EEB2B2",
      main: "#FF3B30",
    },
    success: {
      light: "#A6E7AB",
      main: "#66bb6a",
    },
    warning: {
      light: "#FDE5BF",
      main: "#ffa726",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0,0,0,0.74)",
    },
    icon: {
      main: "rgba(255,255,255,0.38)",
    },
    dropzoneText: "#D1E3FA",
    textLight: {
      main: "rgba(255, 255, 255, 0.87)",
      dark: "rgba(255, 255, 255, 0.6)",
    },
    dark: {
      light: "rgba(0, 0, 0, 0.12)",
      main: "rgba(0, 0, 0, 0.38)",
      dark: "rgba(0, 0, 0, 0.6)",
      darker: "rgba(0, 0, 0, 0.87)",
    },
    primaryWithOpacity: {
      light: "rgba(26, 115, 232, 0.04)",
      main: "rgba(26, 115, 232, 0.08)",
      dark: "rgba(26, 115, 232, 0.12)",
    },
  },
  shadows: shadows,
  typography: {
    fontFamily: "Nunito",
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        actions: ({ theme }) => ({
          "& .MuiButtonBase-root": {
            margin: theme.spacing(2.5, 0, 0, 0),
            paddingBlock: theme.spacing(0),
            color: theme.palette.text.secondary,
          },
        }),
        displayedRows: ({ theme }) => ({
          fontSize: "15px",
          letterSpacing: "0.25px",
          lineHeight: "20px",
          margin: theme.spacing(2.5, 0, 0, 0),
          color: theme.palette.text.secondary,
        }),
        selectLabel: ({ theme }) => ({
          fontSize: "15px",
          letterSpacing: "0.25px",
          lineHeight: "20px",
          margin: theme.spacing(2.5, 0, 0, 0),
          color: theme.palette.text.secondary,
        }),
        select: ({ theme }) => ({
          fontSize: "15px",
          letterSpacing: "0.25px",
          lineHeight: "20px",
          margin: theme.spacing(2.5, 0, 0, 0),
          color: theme.palette.text.secondary,
        }),
        selectIcon: {
          top: "48%",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.32)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#979797",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          left: 0,
          backgroundColor: theme.palette.primary.main,
          width: theme.spacing(0.5),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: ({ theme }) => ({
          height: theme.spacing(6),
        }),
        outlinedPrimary: ({ theme }) => ({
          height: theme.spacing(6),
        }),
        containedSecondary: ({ theme }) => ({
          height: theme.spacing(6),
          backgroundColor: "rgba(26,115,232,0.12)",
          color: theme.palette.primary.main,
        }),
        contained: ({ theme }) => ({
          ":hover": {
            backgroundColor: theme.palette.primary.light,
          },
        }),
        sizeLarge: ({ theme }) => ({
          height: theme.spacing(8),
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: "63px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.MuiTableRow-hover:hover": {
            backgroundColor: theme.palette.primaryWithOpacity.light,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: theme.spacing(0),
          color: theme.palette.error.main,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: theme.spacing(6),
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: "40px",
        },
        colorPrimary: ({ theme }) => ({
          color: theme.palette.dark.main,
        }),
        colorSecondary: ({ theme }) => ({
          color: theme.palette.dark.dark,
        }),
        colorAction: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthXl: {
          width: "923px",
        },
        paperWidthLg: {
          width: "734px",
        },
        paperWidthMd: {
          width: "580px",
        },
        paperWidthSm: {
          width: "544px",
        },
        paperWidthXs: {
          width: "514px",
        },
      },
      defaultProps: {
        transitionDuration: 0,
        fullWidth: true,
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.dark.main,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
        },
        colorPrimary: ({ theme }) => ({
          backgroundColor: "#E8F1FD",
          color: theme.palette.primary.main,
        }),
        colorSecondary: ({ theme }) => ({
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          color: theme.palette.dark.main,
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.MuiListItemButton-root": {
            "&.Mui-selected": {
              backgroundColor: theme.palette.primary.main,
            },
            "&.Mui-selected:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          fontWeight: 700,
          fontSize: "15px",
          letterSpacing: "1.25px",
          lineHeight: "16px",
        },
        h1: {
          fontWeight: 400,
          fontSize: "37px",
          letterSpacing: "0.25px",
          lineHeight: "36px",
        },
        h2: {
          fontWeight: 600,
          fontSize: "26px",
          letterSpacing: "0px",
          lineHeight: "24px",
        },
        h3: {
          fontWeight: 600,
          fontSize: "22px",
          letterSpacing: "0.17px",
          lineHeight: "24px",
        },

        subtitle1: ({ theme }) => ({
          fontWeight: 400,
          fontSize: "17px",
          letterSpacing: "0.5px",
          lineHeight: "24px",
          color: theme.palette.dark.dark,
        }),
        subtitle2: ({ theme }) => ({
          fontWeight: 600,
          fontSize: "15px",
          letterSpacing: "0.1px",
          lineHeight: "20px",
          color: theme.palette.text.primary,
        }),
        caption: {
          fontWeight: 400,
          fontSize: "13px",
          letterSpacing: "0.4px",
          lineHeight: "16px",
        },
      },
    },
  },
});
