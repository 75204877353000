import { FC } from "react";

import dayjs from "dayjs";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";

import { EmptyState } from "..";
import { DATE_FORMAT, TIME_FORMAT } from "../../constants";
import { useDeleteInterview } from "../../hooks/interviews/useDeleteInterview";
import { useUpcomingInterviews } from "../../hooks/interviews/useUpcomingInterviews";
import { useChangePage } from "../../hooks/useChangePage";
import { useDeleteItem } from "../../hooks/useDeleteItem";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { ScheduleInterviewResponse } from "../../types/interviews";
import DeletePopup from "../DeletePopup";
import ModalContainer from "../ModalContainer";
import TableActions from "./TableActions";

interface Props {
  handleChangeMode: (data: ScheduleInterviewResponse) => () => void;
}

const parseBooleanValue = (value?: boolean) => {
  if (value === true) {
    return "upcomingInterviews:interviewedValues.yes";
  }
  return "upcomingInterviews:interviewedValues.no";
};

const UpcomingInterviewsTable: FC<Props> = ({ handleChangeMode }) => {
  const { t } = useTranslation();

  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();
  const { deleteInterview, isLoading: isDeletingInterview } = useDeleteInterview();
  const { itemToDelete, handleCloseModal, handleDeleteIconClick, handleDeleteItem } = useDeleteItem(deleteInterview);

  const { upcomingInterviewsData, isLoading } = useUpcomingInterviews(page, perPage, handleChangePage);

  const tableBuilder: Column<ScheduleInterviewResponse> = {
    columns: [
      {
        dataKey: "date",
        label: t("upcomingInterviews:table.date"),
        dataRender: data => dayjs(data.interviewDate).format(DATE_FORMAT),
        sortable: false,
      },
      {
        dataKey: "from",
        label: t("upcomingInterviews:table.from"),
        dataRender: data => (
          <div style={{ textTransform: "uppercase" }}>{dayjs(data.timeFrom).format(TIME_FORMAT)}</div>
        ),
        sortable: false,
      },
      {
        dataKey: "to",
        label: t("upcomingInterviews:table.to"),
        dataRender: data => <div style={{ textTransform: "uppercase" }}>{dayjs(data.timeTo).format(TIME_FORMAT)}</div>,
        sortable: false,
      },
      {
        dataKey: "isInterviewed",
        label: t("upcomingInterviews:table.interviewed"),
        dataRender: data => t(parseBooleanValue(data.isInterviewed)),
        sortable: false,
      },
      {
        dataKey: "type",
        label: t("upcomingInterviews:table.type"),
        dataRender: data =>
          t(`userProfile:actions.scheduleAnInterview.formFields.interviewTypes.${data.type}` as ResourceKey),
        sortable: false,
      },
      {
        dataKey: "manager",
        label: t("upcomingInterviews:table.manager"),
        dataRender: data => data.manager.name,
        sortable: false,
        private: true,
      },
      {
        dataKey: "consultant",
        label: t("upcomingInterviews:table.consultant"),
        dataRender: data => data.consultant.name,
        sortable: false,
      },
      {
        dataKey: "actions",
        dataRender: data => (
          <TableActions deleteClick={handleDeleteIconClick(data.id)} editClick={handleChangeMode(data)} />
        ),
        sortable: false,
        private: true,
      },
    ],
  };

  if (isDeletingInterview || isLoading || !upcomingInterviewsData) {
    return <Loading />;
  }

  if (!upcomingInterviewsData.totalElements) {
    return <EmptyState text={t("emptyState:texts.upcomingInterviews")} />;
  }

  return (
    <>
      <CustomTable
        tableBuilder={tableBuilder}
        data={upcomingInterviewsData.data}
        page={page}
        perPage={perPage}
        totalElements={upcomingInterviewsData.totalElements}
        showPagination
        showRowsPerPage
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
      />
      <ModalContainer
        isOpened={!!itemToDelete}
        title={t("deletePopup:title", { label: t("upcomingInterviews:interview") })}
        closeModal={handleCloseModal}
        size={"md"}
      >
        <DeletePopup
          label={t("upcomingInterviews:interview")}
          handleDeleteAction={handleDeleteItem}
          cancelDeleteAction={handleCloseModal}
        />
      </ModalContainer>
    </>
  );
};

export default UpcomingInterviewsTable;
