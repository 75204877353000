import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteConsultant = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: deleteConsultant, isLoading } = useMutation(consultantsService.deleteConsultant, {
    mutationKey: QUERY_STRINGS.DELETE_CONSULTANT,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries([QUERY_STRINGS.GET_CONSULTANTS]),
  });

  return { deleteConsultant, isLoading };
};
