import { FC, useContext, useEffect, useState } from "react";

import {
  Add,
  AddBusinessOutlined,
  AdminPanelSettings,
  ChatBubble,
  ContactPage,
  Dvr,
  FlagOutlined,
  ListOutlined,
  ManageAccountsOutlined,
  Psychology,
  RecentActors,
  ThreeP,
  Work,
} from "@mui/icons-material";
import { Box, Chip, Drawer, List } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ReactComponent as SentQuestions } from "../assets/sent-interviews.svg";
import { ReactComponent as UpcomingInterviews } from "../assets/upcoming-interview.svg";
import { Dropzone, ListItems, SublistModal } from "../components";
import { addSublistConfig } from "../components/forms/builders/addSublistBuilder";
import { PATHS } from "../constants";
import { ChatContext } from "../contexts/ChatContext";
import { useSidebarSublistDataFetch } from "../hooks/useSidebarSublistDataFetch";
import { UserState, useUserStore } from "../store/useUserStore";
import { ActionTypes, FieldOptionNames, SelectOptionsType } from "../types/default";
import {
  ListProps,
  ListType,
  NestedListItem,
  NestedListItemType,
  NestedSublistItem,
  NestedSublistItemType,
} from "../types/sidebar";
import { Sublist, SublistBuilder } from "../types/sublist";
import { UserRoles } from "../types/users";
import Loading from "./Loading";

const useStyles = makeStyles()(theme => ({
  drawer: {
    height: "100%",
    maxHeight: "calc(100vh - 80px)",
    overflowY: "auto",
    overflowX: "hidden",
    "& .MuiDrawer-paper": {
      position: "relative",
      width: 291,
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.dark,
      fontWeight: 600,
      color: theme.palette.textLight.main,
    },
  },
  listContainer: {
    overflow: "auto",
    marginTop: theme.spacing(2.5),
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  list: {
    padding: theme.spacing(0),
  },
}));

const userRoleSelector = (state: UserState) => state.role;

const FIREBASE_NEW_NOTIFICATION = "notification.message_new";
const FIREBASE_NEW_MESSAGE = "message.new";
const FIREBASE_READ_MESSAGE = "notification.mark_read";

const Sidebar: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chatClient } = useContext(ChatContext);

  const { usersData, createSublist, sublistsData, editSublist, deleteSublist, isLoading, flaggedCvs } =
    useSidebarSublistDataFetch();

  const [modalType, setModalType] = useState<ActionTypes | null>(null);
  const [currentSublist, setCurrentSublist] = useState<Sublist | null>(null);
  const [unreadMessages, setUnreadMessages] = useState(false);

  useEffect(() => {
    chatClient?.on(event => {
      (event.type === FIREBASE_NEW_MESSAGE || event.type === FIREBASE_NEW_NOTIFICATION) &&
        event.total_unread_count &&
        setUnreadMessages(true);

      event.type === FIREBASE_READ_MESSAGE && !event.total_unread_count && setUnreadMessages(false);
    });
  }, [chatClient]);

  const currentUser = useUserStore(userRoleSelector);

  const handleAddSublist = () => setModalType(ActionTypes.ADD);
  const handleCloseModal = () => {
    setModalType(null);
    setCurrentSublist(null);
  };

  const handleSubmitSublistForm = (sublistData: SublistBuilder) => {
    const consultantIds = sublistData.consultants.map(consultant => consultant.id);
    const data = { name: sublistData.name, consultantIds };

    modalType === ActionTypes.ADD && createSublist(data);
    modalType === ActionTypes.EDIT && editSublist({ data, id: currentSublist!.id });

    handleCloseModal();
  };

  const handleSetSublistAction = (sublist: Sublist, modalType: ActionTypes) => {
    setModalType(modalType);
    setCurrentSublist(sublist);
  };

  const handleDeleteSublist = () => {
    currentSublist && deleteSublist(currentSublist.id);
    handleCloseModal();
  };

  if (isLoading || !sublistsData) {
    return <Loading />;
  }

  const listRecent: NestedListItem[] | undefined = usersData?.data.slice(0, 5).map(user => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    action: () => navigate(`${PATHS.consultants}/${user.id}`),
  }));

  const flaggedCvsTransformed = flaggedCvs?.map(flaggedCv => ({
    ...flaggedCv,
    type: NestedListItemType.share,
    action: () => navigate(`${PATHS.consultants}/${flaggedCv.id}`),
  }));

  const consultantsDataMapped: SelectOptionsType[] | undefined = usersData?.data.map(consultant => ({
    id: consultant.id,
    name: `${consultant.firstName} ${consultant.lastName}`,
  }));

  const sublistItemsMapped: NestedSublistItem[] = sublistsData.map(sublist => ({
    id: sublist.id,
    name: sublist.name,
    type: NestedSublistItemType.default,
    action: () => navigate(`${PATHS.sublist}/${sublist.id}`),
    deleteAction: () => handleSetSublistAction(sublist, ActionTypes.DELETE),
    editAction: () => handleSetSublistAction(sublist, ActionTypes.EDIT),
  }));

  const list: ListProps[] = [
    { label: t("sidebar:overview"), icon: <Dvr />, path: PATHS.root, type: ListType.default, isPrivate: false },
    {
      label: t("sidebar:superadmins"),
      icon: <AdminPanelSettings />,
      path: PATHS.superadmins,
      type: ListType.default,
      isPrivate: true,
    },
    {
      label: t("sidebar:managers"),
      icon: <ManageAccountsOutlined />,
      path: PATHS.managers,
      type: ListType.default,
      isPrivate: true,
    },
    {
      label: t("sidebar:consultants"),
      icon: <ThreeP />,
      path: PATHS.consultants,
      type: ListType.default,
      isPrivate: false,
    },
    {
      label: t("sidebar:upcomingInterviews"),
      icon: <UpcomingInterviews />,
      path: PATHS.upcomingInterviews,
      type: ListType.default,
      isPrivate: false,
    },
    {
      label: t("sidebar:jobOrder"),
      icon: <Work />,
      path: PATHS.jobs,
      type: ListType.default,
      isPrivate: false,
    },
    {
      label: t("sidebar:sentQuestions"),
      icon: <SentQuestions />,
      path: PATHS.sentQuestions,
      type: ListType.default,
      isPrivate: false,
    },
    {
      label: t("sidebar:messages"),
      icon: <ChatBubble />,
      path: PATHS.messages,
      type: ListType.default,
      isPrivate: false,
      badge: unreadMessages ? <Chip label="new" color="error" size={"small"} variant="outlined" /> : undefined,
    },
    {
      label: t("sidebar:recent"),
      icon: <RecentActors />,
      type: ListType.expandable,
      items: listRecent,
      isPrivate: false,
    },
    {
      label: t("sidebar:recentlyFlagged"),
      icon: <FlagOutlined />,
      type: ListType.expandable,
      items: flaggedCvsTransformed,
      isPrivate: false,
    },
    {
      label: t("sidebar:sublists.title"),
      icon: <ListOutlined />,
      type: ListType.expandable,
      sublists: [
        {
          id: "create",
          icon: <Add />,
          name: t("sidebar:sublists.add"),
          type: NestedSublistItemType.add,
          action: handleAddSublist,
        },
        ...sublistItemsMapped,
      ],
      isPrivate: false,
    },
    {
      label: t("sidebar:skills"),
      icon: <Psychology />,
      type: ListType.default,
      path: PATHS.skills,
      isPrivate: true,
    },
    {
      label: t("sidebar:companies"),
      icon: <AddBusinessOutlined />,
      type: ListType.default,
      path: PATHS.companies,
      isPrivate: true,
    },
    {
      label: t("sidebar:upcomingCVs"),
      icon: <ContactPage />,
      type: ListType.default,
      path: PATHS.upcomingCVs,
      isPrivate: true,
    },
  ];

  const addSublistConfigFields = addSublistConfig.fields.map(field => ({
    ...field,
    options: field.name === FieldOptionNames.CONSULTANTS ? consultantsDataMapped : field.options,
  }));

  const editSublistFields = addSublistConfigFields.map(field => {
    const consultants = currentSublist?.consultants.map(consultant => ({
      id: consultant.id,
      name: `${consultant.firstName} ${consultant.lastName}`,
    }));

    return {
      ...field,
      defaultValue: field.name === FieldOptionNames.NAME ? currentSublist?.name : consultants,
    };
  });

  return (
    <Drawer variant="permanent" className={classes.drawer} anchor="left">
      <Box className={classes.listContainer}>
        <List className={classes.list}>
          {currentUser === UserRoles.manager
            ? list
                .filter(item => !item.isPrivate)
                .map((item, index) => <ListItems key={item.label} item={item} index={index} />)
            : list.map((item, index) => <ListItems key={item.label} item={item} index={index} />)}
        </List>
        {currentUser === UserRoles.manager && <Dropzone />}
      </Box>

      <SublistModal
        modalType={modalType}
        formConfig={{
          ...addSublistConfig,
          fields: modalType === ActionTypes.EDIT ? editSublistFields : addSublistConfigFields,
        }}
        handleSubmitForm={handleSubmitSublistForm}
        handleCloseModal={handleCloseModal}
        handleDeleteSublist={handleDeleteSublist}
      />
    </Drawer>
  );
};

export default Sidebar;
