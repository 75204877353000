import { EMAIL_REGEX } from "../../../constants";
import { LoginTypes } from "../../../types/authentication";
import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";

export const loginFormConfig: FormConstructor<LoginTypes> = {
  fields: [
    {
      name: "email",
      label: "login:form.formFields.email",
      validationOptions: {
        pattern: {
          pattern: EMAIL_REGEX,
          label: "login:form.errors.invalidEmail",
        },
        required: true,
      },
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "password",
      label: "login:form.formFields.password",
      validationOptions: {
        required: true,
        validate: {
          minLength: 5,
          label: "login:form.errors.invalidPassword",
        },
      },
      type: FieldType.password,
      defaultValue: "",
      hasEndAdornment: true,
    },
    {
      name: "rememberMe",
      label: "login:form.formFields.rememberMe",
      type: FieldType.checkbox,
      defaultValue: "",
    },
  ],
};
