import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import upcomingCVsService from "../../services/upcomingCVs";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useDeleteUpcomingCV = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { mutate: deleteUpcomingCV, isLoading } = useMutation(upcomingCVsService.deleteUpcomingCV, {
    mutationKey: QUERY_STRINGS.DELETE_UPCOMING_CV,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries([QUERY_STRINGS.GET_UPCOMING_CVS]),
  });

  return { deleteUpcomingCV, isLoading };
};
