import { FC } from "react";

import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import { AvailabilityBoxes } from "..";
import { PATHS } from "../../constants";
import { UserState, useUserStore } from "../../store/useUserStore";
import { UserRoles } from "../../types/users";

interface Props {
  title?: string;
  table: React.ReactNode;
  hasAvailabilityData?: boolean;
}

const userRoleSelector = (state: UserState) => state.role;

const CardContentSection: FC<Props> = ({ title, table, children, hasAvailabilityData }) => {
  const userRole = useUserStore(userRoleSelector);
  const location = useLocation();

  const getTablePadding = () => {
    if (userRole === UserRoles.superadmin && location.pathname === PATHS.root) {
      return 1;
    }

    if (!hasAvailabilityData && !children) {
      return 5.5;
    }

    return 0;
  };

  return (
    <Grid container>
      {title && (
        <Grid item xs={12}>
          <Typography variant={"h2"} color={"textPrimary"} marginTop={1} marginBottom={3}>
            {title}
          </Typography>
        </Grid>
      )}

      {children && (
        <Grid item xs={12} paddingBottom={4}>
          {children}
        </Grid>
      )}

      {hasAvailabilityData && (
        <Grid item xs={12} display={"flex"} gap={2} paddingTop={!children ? 5.5 : 0}>
          <AvailabilityBoxes />
        </Grid>
      )}

      <Grid item xs={12} paddingTop={getTablePadding()}>
        {table}
      </Grid>
    </Grid>
  );
};

export default CardContentSection;
