import { FC } from "react";

import { RemoveCircleOutline, Share } from "@mui/icons-material";
import { Avatar, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { getInitials } from "../../functions";
import { NestedListItem, NestedListItemType } from "../../types/sidebar";

const useStyles = makeStyles()(theme => ({
  nestedListItem: {
    paddingLeft: theme.spacing(5),
  },
  nestedListIcon: {
    minWidth: theme.spacing(4.25),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    fontSize: "11px",
    letterSpacing: "1.55px",
    lineHeight: "16px",
  },
  iconColor: {
    color: theme.palette.icon.main,
  },
  shareIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.25),
  },
  personTextColor: {
    color: theme.palette.textLight.main,
  },
}));

interface Props {
  item: NestedListItem;
}

const CustomListItem: FC<Props> = ({ item }) => {
  const { classes } = useStyles();
  const { consultantId } = useParams<{ consultantId: string }>();

  return (
    <>
      <ListItemButton className={classes.nestedListItem} onClick={item.action} selected={consultantId === item.id}>
        <ListItemIcon className={classes.nestedListIcon}>
          <Avatar alt="avatar" className={classes.avatar}>
            {getInitials(item.firstName, item.lastName)}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="subtitle2"
              className={classes.personTextColor}
            >{`${item.firstName} ${item.lastName}`}</Typography>
          }
        />
        {item.type === NestedListItemType.remove && <RemoveCircleOutline className={classes.iconColor} />}
        {item.type === NestedListItemType.share && <Share className={clsx(classes.shareIcon, classes.iconColor)} />}
      </ListItemButton>
    </>
  );
};

export default CustomListItem;
