import { ReactElement } from "react";

import { Dayjs } from "dayjs";
import { ControllerFieldState, ControllerRenderProps, Path } from "react-hook-form";

export type OrderType = "asc" | "desc";

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export interface BaseEntity {
  id: string;
}

export interface BaseEntityName {
  name: string;
}

export enum Availability {
  small = "small",
  medium = "medium",
  large = "large",
}

export interface FormFieldProps {
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
}

export interface BaseFormFieldProps {
  label: string;
  formFieldProps: FormFieldProps;
}

export interface FieldBaseTypes {
  name: string;
  label: string;
}

export interface CustomValidationOptions {
  required?: boolean;
  pattern?: {
    pattern: RegExp;
    label: string;
  };
  validate?: {
    minLength: number;
    label: string;
  };
  validateDate?: {
    date: Dayjs;
    label: string;
  };
  valueAsDate?: boolean;
}

export interface FormConstructorTypes extends FieldBaseTypes {
  type: string;
  defaultValue?:
    | string
    | string[]
    | SelectOptionsType[]
    | SelectOptionsType
    | Dayjs
    | boolean
    | number
    | File
    | FormData;
  validationOptions?: CustomValidationOptions;
  isWatched?: boolean;
  options?: SelectOptionsType[];
  isFullWidth?: boolean;
  isPrivate?: boolean;
  multiline?: boolean;
  disabledField?: boolean;
  hasEndAdornment?: boolean;
  endAdornment?: string;
  showPreviousUploadedFile?: boolean;
  fileType?: FieldOptionTypes;
  disablePastDate?: boolean;
  isFreeText?: boolean;
}

export interface FormConstructor<Type> {
  global?: GlobalConstructor<Type>;
  fields: FormFieldsConstructor<Type>[];
}

interface GlobalConstructor<Type> {
  watchedField: Path<Type>;
  watchedFieldValue: string;
}

export interface FormFieldsConstructor<Type> extends Omit<FormConstructorTypes, "name"> {
  name: keyof Type;
}

export interface SelectConfig extends FieldBaseTypes {
  value: string;
  defaultValue: string | string[];
  options: SelectOptionsType[];
}

export interface ErrorContent {
  message: string;
  applicationStatus: string;
}

export interface ErrorData {
  data: ErrorContent;
  status: number;
  statusText: number;
}

export interface CustomError {
  response: ErrorData;
}

export enum FormFieldValidations {
  required = "required",
  pattern = "pattern",
  validate = "validate",
  validateDate = "validateDate",
}

export enum CardTypes {
  company = "company",
  consultants = "consultants",
}

export interface CardButtonTypes {
  label: string;
  icon?: ReactElement;
  action: (path: string, requirementId?: string) => void;
}

export interface PaginatedDataBase {
  currentPage: number;
  totalElements: number;
}

export interface Pagination {
  perPage: number;
  page: number;
}

export interface PaginatedData<TType> extends PaginatedDataBase {
  data: TType[];
}

export interface SelectOptionsType extends BaseEntity, BaseEntityName {}

export enum FieldOptionNames {
  SKILLS = "skillDetails",
  SKILL_IDS = "skillIds",
  MANAGER = "managerId",
  MANAGERS = "managerDetails",
  COMMITMENT = "commitment",
  FUTURE_COMMITMENT = "futureCommitment",
  COMPANIES = "companies",
  COMPANY = "companyId",
  JOB_REQUIREMENTS = "jobRequirementsDetails",
  CONSULTANTS = "consultants",
  NAME = "name",
  EMAIL = "email",
  DAILY_RATE = "dailyRate",
  DESCRIPTION = "description",
  CONSULTANT_CV = "consultantCv",
  PROFILE_IMAGE = "profileImage",
  INTERVIEW_AVAILABILITY = "interviewAvailability",
}

export enum FieldOptionTypes {
  PDF = "pdf",
  FILE = "file",
  IMAGE = "image",
}

export enum ActionTypes {
  ADD = "Add",
  EDIT = "Edit",
  DELETE = "Delete",
}
