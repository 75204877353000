import { FC, useState } from "react";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { CustomListItem } from "..";
import { ListProps, ListType } from "../../types/sidebar";
import SublistItem from "./SublistItem";

interface Props {
  item: ListProps;
  index: number;
}

const useStyles = makeStyles<{ index: number; isItemSelected: boolean }>()((theme, { index, isItemSelected }) => ({
  listItem: {
    marginBottom: !index ? theme.spacing(4) : theme.spacing(0),
  },
  listIcon: {
    color: isItemSelected ? theme.palette.textLight.main : theme.palette.textLight.dark,
  },
  listItemColor: {
    color: theme.palette.textLight.main,
  },
  iconColor: {
    color: theme.palette.icon.main,
  },
  listBadge: {
    justifyContent: "flex-end",
  },
}));

const ListItems: FC<Props> = ({ item, index }) => {
  const location = useLocation();
  const isItemSelected = location.pathname === item.path;
  const { classes } = useStyles({ index, isItemSelected });

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(prev => !prev);

  const dropdownArrow =
    item.type === ListType.expandable ? (
      open ? (
        <ArrowDropUp className={classes.iconColor} />
      ) : (
        <ArrowDropDown className={classes.iconColor} />
      )
    ) : null;

  return (
    <>
      <ListItemButton
        className={classes.listItem}
        onClick={handleClick}
        component={NavLink}
        to={item.path ? item.path : "#"}
        selected={isItemSelected}
      >
        <ListItemIcon className={classes.listIcon}>{item.icon}</ListItemIcon>

        <ListItemText
          primary={item.label}
          primaryTypographyProps={{ variant: "subtitle2", className: classes.listItemColor }}
        />

        {item.badge && <ListItemIcon classes={{ root: classes.listBadge }}>{item.badge}</ListItemIcon>}

        {dropdownArrow}
      </ListItemButton>

      {item.type === ListType.expandable && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.items && item.items.map(nestedItem => <CustomListItem key={nestedItem.id} item={nestedItem} />)}

            {item.sublists && item.sublists.map(el => <SublistItem key={el.id} sublist={el} />)}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default ListItems;
