import { FC, useEffect } from "react";

import { Link } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import shallow from "zustand/shallow";

import { EmptyState, TableActions } from "..";
import { DATE_FORMAT } from "../../constants";
import { useQuestionaires } from "../../hooks/questions/useQuestionaires";
import { useChangePage } from "../../hooks/useChangePage";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { ConsultantsState, useConsultantsStore } from "../../store/useConsultantsStore";
import { QuestionsData } from "../../types/questions";

const consultantsStoreSelector = (state: ConsultantsState) => ({
  addLastSelectedConsultant: state.addLastSelectedConsultant,
  resetLastSelectedConsultant: state.resetLastSelectedConsultant,
  lastSelectedConsultant: state.lastSelectedConsultant,
});

interface Props {
  handleChangeMode: (data: QuestionsData) => () => void;
}

const SentQuestionsTable: FC<Props> = ({ handleChangeMode }) => {
  const { t } = useTranslation();

  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();
  const { lastSelectedConsultant, addLastSelectedConsultant, resetLastSelectedConsultant } = useConsultantsStore(
    consultantsStoreSelector,
    shallow
  );
  const { questionaires, isLoading } = useQuestionaires(page, perPage);

  useEffect(() => {
    return () => {
      resetLastSelectedConsultant();
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const tableBuilder: Column<QuestionsData> = {
    isRowSelected: rowData => lastSelectedConsultant === rowData.consultantDetails.id,
    rowClickFn: data => () => addLastSelectedConsultant(data.consultantDetails.id),
    columns: [
      {
        dataKey: "date",
        label: t("questions:fields.date"),
        dataRender: data => dayjs(data.date).format(DATE_FORMAT),
        sortable: false,
      },
      {
        dataKey: "consultant",
        label: t("questions:fields.consultant"),
        dataRender: data => data.consultantDetails.name,
        sortable: false,
      },
      {
        dataKey: "requirements",
        label: t("questions:fields.requirements"),
        dataRender: data => (data.jobRequirements ? data.jobRequirements : t("questions:fields.notAdded")),
        sortable: false,
      },
      {
        dataKey: "questions",
        label: t("questions:fields.questions"),
        dataRender: data => (
          <Link underline={"none"} href={data.sentFilePath} target={"_blank"} rel={"noopener noreferrer"}>
            {t("questions:fields.viewQuestions")}
          </Link>
        ),
        sortable: false,
      },
      {
        dataKey: "answers",
        label: t("questions:fields.answers"),
        dataRender: data =>
          data.answeredFilePath ? (
            <Link underline={"none"} href={data.answeredFilePath} target={"_blank"} rel={"noopener noreferrer"}>
              {t("questions:fields.viewQuestions")}
            </Link>
          ) : (
            t("questions:fields.pending")
          ),
        sortable: false,
      },
      {
        dataKey: "actions",
        label: undefined,
        dataRender: data => <TableActions editClick={handleChangeMode(data)} />,
        sortable: false,
        private: true,
      },
    ],
  };

  if (!questionaires?.data.length) {
    return <EmptyState text={t("emptyState:texts.questions")} />;
  }

  return (
    <CustomTable
      tableBuilder={tableBuilder}
      data={questionaires?.data}
      page={page}
      perPage={perPage}
      totalElements={questionaires.totalElements}
      showPagination
      showRowsPerPage
      handleChangePage={handleChangePage}
      handleChangePerPage={handleChangePerPage}
    />
  );
};

export default SentQuestionsTable;
