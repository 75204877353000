import { FC } from "react";

import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  rootContainer: {
    height: "100vh",
    fontSmooth: "antialiased",
  },
  imageContainer: {
    backgroundImage: "url(/assets/login-picture.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  overlay: {
    position: "relative",
    top: theme.spacing(0),
    left: theme.spacing(0),
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    opacity: 0.24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
  },
}));

const AuthLayout: FC = () => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.rootContainer}>
      <Grid item xs={false} sm={4} md={6} className={classes.imageContainer}>
        <Box className={classes.overlay} />
      </Grid>
      <Grid item xs={12} sm={8} md={6}>
        <Box className={classes.formContainer}>
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
