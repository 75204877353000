import dayjs from "dayjs";

import { NUMBER_REGEX } from "../../../constants";
import { FormConstructor } from "../../../types/default";
import { FieldType } from "../../../types/login";
import { MakeAnOfferFormTypes } from "../../../types/rightSidebar";

export const makeAnOfferConfig: FormConstructor<MakeAnOfferFormTypes> = {
  fields: [
    {
      name: "onsiteRate",
      label: "userProfile:actions.makeAnOffer.formFields.onsiteRate",
      validationOptions: {
        required: true,
        pattern: {
          pattern: NUMBER_REGEX,
          label: "userProfile:actions.makeAnOffer.errors.invalidValue",
        },
      },
      type: FieldType.number,
      defaultValue: "",
      hasEndAdornment: true,
      endAdornment: "userProfile:actions.makeAnOffer.euroPerDay",
    },
    {
      name: "remoteRate",
      label: "userProfile:actions.makeAnOffer.formFields.remoteRate",
      validationOptions: {
        required: true,
        pattern: {
          pattern: NUMBER_REGEX,
          label: "userProfile:actions.makeAnOffer.errors.invalidValue",
        },
      },
      type: FieldType.number,
      defaultValue: "",
      hasEndAdornment: true,
      endAdornment: "userProfile:actions.makeAnOffer.euroPerDay",
    },
    {
      name: "startDate",
      label: "userProfile:actions.makeAnOffer.formFields.startDate",
      type: FieldType.date,
      defaultValue: dayjs(),
      validationOptions: {
        required: true,
        validateDate: {
          date: dayjs(),
          label: "userProfile:actions.makeAnOffer.errors.invalidDate",
        },
      },
      disablePastDate: true,
    },
    {
      name: "initialDuration",
      label: "userProfile:actions.makeAnOffer.formFields.initialDuration",
      validationOptions: {
        required: true,
        pattern: {
          pattern: NUMBER_REGEX,
          label: "userProfile:actions.makeAnOffer.errors.invalidValue",
        },
      },
      type: FieldType.number,
      defaultValue: "",
      hasEndAdornment: true,
      endAdornment: "userProfile:actions.makeAnOffer.months",
    },
    {
      name: "onsiteHoursPerWeek",
      label: "userProfile:actions.makeAnOffer.formFields.onsiteHoursPerWeek",
      validationOptions: {
        required: true,
        pattern: {
          pattern: NUMBER_REGEX,
          label: "userProfile:actions.makeAnOffer.errors.invalidValue",
        },
      },
      type: FieldType.number,
      defaultValue: "",
      hasEndAdornment: true,
      endAdornment: "userProfile:actions.makeAnOffer.hours",
    },
    {
      name: "remoteHoursPerWeek",
      label: "userProfile:actions.makeAnOffer.formFields.remoteHoursPerWeek",
      validationOptions: {
        required: true,
        pattern: {
          pattern: NUMBER_REGEX,
          label: "userProfile:actions.makeAnOffer.errors.invalidValue",
        },
      },
      type: FieldType.number,
      defaultValue: "",
      hasEndAdornment: true,
      endAdornment: "userProfile:actions.makeAnOffer.hours",
    },
  ],
};
