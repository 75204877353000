import { FC, useContext, useState } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, CardHeader, ManagersTable } from "../components";
import { addManagerBuilder } from "../components/forms/builders/addManagerBuilder";
import { AuthContext } from "../contexts/AuthContext";
import { useAllCompanies } from "../hooks/company/useAllCompanies";
import { useEditUser } from "../hooks/users/useEditUser";
import Loading from "../layouts/Loading";
import { FieldOptionNames, FormFieldsConstructor } from "../types/default";
import { UserBaseTypes, UserRoles } from "../types/users";

const Managers: FC = () => {
  const { t } = useTranslation();

  const { isLoading: isCompaniesLoading, companiesData } = useAllCompanies();

  const { registerUser, isLoadingInComponent: isLoading } = useContext(AuthContext);
  const { editUser, isLoading: isEditLoading } = useEditUser(UserRoles.manager);

  const [editMode, setEditMode] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<null | UserBaseTypes>(null);

  const handleChangeMode = (data: UserBaseTypes) => () => {
    setEditMode(true);
    setCurrentUserData(data);
  };

  const handleCloseEditMode = () => {
    setEditMode(false);
    setCurrentUserData(null);
  };

  const handleSubmitForm = (data: UserBaseTypes) => {
    const { companyId, ...rest } = data;

    editMode && currentUserData
      ? editUser({ ...data, id: currentUserData.id })
      : registerUser({ ...rest, role: UserRoles.manager, companyIds: [companyId!] });
  };

  if (isLoading || isEditLoading || isCompaniesLoading || !companiesData?.totalElements) {
    return <Loading />;
  }

  const mappedCompaniesData = companiesData.data.map(company => ({ id: company.id, name: company.name }));

  const getOptions = (field: FormFieldsConstructor<UserBaseTypes>) => {
    if (currentUserData && editMode) {
      if (field.name === FieldOptionNames.COMPANY) {
        return currentUserData.companyId;
      }

      return currentUserData[field.name];
    }

    return field.defaultValue;
  };

  const managerFields = addManagerBuilder.fields.map(field => ({
    ...field,
    options: field.name === FieldOptionNames.COMPANY ? mappedCompaniesData : field.options,
  }));

  const editManagerFields = managerFields
    .filter(field => field.name !== FieldOptionNames.COMPANY)
    .map(field => ({
      ...field,
      defaultValue: getOptions(field),
      disabledField: field.name === FieldOptionNames.EMAIL,
    }));

  return (
    <>
      <Helmet>
        <meta name="description" content={t("managers:description")} />
        <title>
          {t("managers:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <CardHeader
        title={t("sidebar:managers")}
        btnLabel={t("managers:addNewBtn")}
        modalTitle={editMode ? t("managers:edit") : t("managers:addNew")}
        formConfig={{ ...addManagerBuilder, fields: editMode ? editManagerFields : managerFields }}
        modalBtnTitle={editMode ? t("formFields:buttons.done") : t("managers:addNewBtn")}
        size={"md"}
        isEditMode={editMode}
        handleSubmitForm={handleSubmitForm}
        closeEditMode={handleCloseEditMode}
      />
      <CardContentSection table={<ManagersTable handleChangeMode={handleChangeMode} />} />
    </>
  );
};

export default Managers;
