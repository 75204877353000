import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import notificationsService from "../../services/notifications";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useUnreadNotifications = () => {
  const addSnackBar = useSnackbar(addSnackBarSelector);

  const { isLoading, data: unreadNotifications } = useQuery(
    QUERY_STRINGS.UNREAD_NOTIFICATIONS,
    notificationsService.getUnreadNotifications,
    {
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
    }
  );

  return {
    isLoading,
    unreadNotifications,
  };
};
