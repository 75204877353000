import kommlinkApi, { BackendError } from "../lib/axios";

const uploadFile = async (file: FormData): Promise<string> => {
  try {
    const res = await kommlinkApi.post<string>("/file/upload", file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const uploadConsultantCv = async (file: FormData, consultantId: string): Promise<void> => {
  try {
    await kommlinkApi.post<void>(`/superadmin/${consultantId}/cvs`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const filesService = {
  uploadFile,
  uploadConsultantCv,
};

export default filesService;
