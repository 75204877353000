import { BaseEntity, SelectOptionsType } from "./default";

interface ScheduleInterviewBase {
  type: InterviewType;
  meetingURL?: string;
}

export interface EditInterviewTypes<TType> extends ScheduleInterviewBase {
  interviewDate: TType;
  timeFrom: TType;
  timeTo: TType;
  isInterviewed?: boolean;
}

export interface ScheduleInterviewTypes<TType> extends ScheduleInterviewBase {
  interviewDate: TType;
  timeFrom: TType;
  timeTo: TType;
}

export interface ScheduleInterviewResponse extends BaseEntity, EditInterviewTypes<number> {
  consultant: SelectOptionsType;
  manager: SelectOptionsType;
}

export enum InterviewType {
  PHONE = "PHONE",
  VIDEO = "VIDEO",
}
