import { useConsultants } from "./consultants/useConsultants";
import { useFlaggedCvs } from "./flaggedCvs/useGetFlaggedCvs";
import { useCreateSublist } from "./sublists/useCreateSublist";
import { useDeleteSublist } from "./sublists/useDeleteSublist";
import { useEditSublist } from "./sublists/useEditSublist";
import { useSublists } from "./sublists/useSublists";

export const useSidebarSublistDataFetch = () => {
  const { usersData, isLoading: isConsultantsLoading } = useConsultants(undefined, undefined, undefined, {
    recentlyAdded: true,
  });
  const { createSublist, isLoading: isCreatingSublisLoading } = useCreateSublist();
  const { sublistsData, isLoading: isLoadingSublists } = useSublists();
  const { editSublist, isLoading: isEditingSublistLoading } = useEditSublist();
  const { deleteSublist, isLoading: isDeletingSublist } = useDeleteSublist();
  const { flaggedCvs, isLoading: isFlaggedCvsLoading } = useFlaggedCvs();

  const isLoading =
    isConsultantsLoading ||
    isCreatingSublisLoading ||
    isLoadingSublists ||
    isEditingSublistLoading ||
    isDeletingSublist ||
    isFlaggedCvsLoading;

  return { usersData, createSublist, sublistsData, editSublist, deleteSublist, isLoading, flaggedCvs };
};
