import { BaseEntity, CardButtonTypes, SelectOptionsType } from "./default";

export interface RequirementData extends BaseEntity {
  numberOfConsultants: number;
  title: string;
  status: string;
  lastUpdatedTimeStamp: number;
  commitmentTimestamp: number;
  managerName: string;
  managerId: string;
  skillDetails: SelectOptionsType[];
}

export interface ManagerCreateRequirement {
  title: string;
  meetingDate?: number;
  commitmentDate?: number;
  skillIds: string[];
}

export interface CreateRequirement extends Omit<ManagerCreateRequirement, "meetingDate"> {
  managerId: string;
}

export interface Requirement {
  title: string;
  managerId: string;
  futureCommitment: string;
  commitment: string;
  skillIds: SelectOptionsType[];
}

export interface EditRequirement extends BaseEntity {
  body: CreateRequirement;
}

export interface ConsultantsToRequirement {
  consultants: SelectOptionsType[];
}

export interface AddConsultantsToRequirementData {
  requirementId: string;
  consultantIds: string[];
}

export interface RequirementOptionTypes extends Required<Omit<CardButtonTypes, "action">> {
  action: () => void;
}

export enum RequirementStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface UpdateRequirementStatus {
  requirementId: string;
  status: RequirementStatus;
}

export enum RequirementCommitmentMonths {
  ONE = "1",
  TWO = "2",
  THREE = "3",
}
