import { createContext, FC, useEffect, useState } from "react";

import { getMessaging, getToken, isSupported, Messaging, onMessage } from "firebase/messaging";
import { useTranslation } from "react-i18next";

import { app } from "../firebase";
import { useMarkViewedNotifications } from "../hooks/notifications/useMarkViewedNotifications";
import config from "../lib/config";
import { SnackbarState, useSnackbar } from "../store/useSnackbar";
import { UserState, useUserStore } from "../store/useUserStore";

export type FirebaseContextState = {
  registrationToken: string | null;
  requestPermissions: () => void;
};

export const FirebaseContext = createContext<FirebaseContextState>(null!);

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;
const isUserLoggedInSelector = (state: UserState) => state.isLoggedIn;

const PERMISSIONS_GRANTED = "granted";

const FirebaseContextProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const isUserLoggedIn = useUserStore(isUserLoggedInSelector);
  const { markViewedNotification } = useMarkViewedNotifications();

  const [messaging, setMessaging] = useState<Messaging | null>(null);
  const [registrationToken, setRegistrationToken] = useState<string | null>(null);
  const [permissionsGranted, setPermissionsGranted] = useState<boolean | null>(null);

  const requestPermissions = async () => {
    const permission = await Notification.requestPermission();
    setPermissionsGranted(permission === PERMISSIONS_GRANTED);
  };

  const isNotificationSupported = async (): Promise<boolean> => {
    try {
      return await isSupported();
    } catch (error) {
      throw new Error(t("notifications:errors.default"));
    }
  };

  useEffect(() => {
    (async () => {
      if (!permissionsGranted) {
        await requestPermissions();
      }

      if ((await isNotificationSupported()) && !registrationToken && permissionsGranted) {
        const messaging = getMessaging(app);
        setMessaging(messaging);

        try {
          const token = await getToken(messaging, {
            vapidKey: config.FIREBASE_VAPID_KEY,
          });

          if (token) {
            setRegistrationToken(token);
            return;
          }

          addSnackbar({ text: t("notifications:errors.notificationsDisabled"), type: "warning" });
          setRegistrationToken(null);
          requestPermissions();
        } catch (error) {
          addSnackbar({ text: t("notifications:errors.default"), type: "error" });
        }
      }
    })();

    return () => {
      setRegistrationToken(null);
    };
  }, [permissionsGranted]);

  if (messaging && isUserLoggedIn) {
    onMessage(messaging, payload => {
      if (payload.notification && payload.notification.body) {
        addSnackbar({ text: payload.notification?.body, type: "info" });

        payload.data && markViewedNotification(payload.data.notificationId);
      }
    });
  }

  const contextValue = {
    registrationToken,
    requestPermissions,
  };

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>;
};

export default FirebaseContextProvider;
