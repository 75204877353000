import { FC } from "react";

import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { MakeAnOfferForm } from "..";
import { NULL_DAILY_RATE } from "../../constants";
import { useMakeAnOffer } from "../../hooks/consultants/useMakeAnOffer";
import Loading from "../../layouts/Loading";
import { MakeAnOfferFormTypes } from "../../types/rightSidebar";
import { makeAnOfferConfig } from "../forms/builders/makeAnOfferBuilder";

interface Props {
  closeModal: () => void;
  consultantRate?: string;
  consultantId: string;
}

const MakeAnOffer: FC<Props> = ({ closeModal, consultantRate, consultantId }) => {
  const { t } = useTranslation();

  const { isLoading, makeAnOffer } = useMakeAnOffer();

  const handleSubmitOffer = (data: MakeAnOfferFormTypes) =>
    makeAnOffer({ ...data, startDate: dayjs(data.startDate).valueOf(), consultantId });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant={"subtitle1"} color={"textSecondary"}>
        {t("userProfile:actions.makeAnOffer.rateDetails")}
      </Typography>

      <Box display={"flex"} gap={2} marginY={2}>
        <Box>
          <Typography variant={"caption"} color={"textSecondary"}>
            {t("userProfile:actions.makeAnOffer.consultantRate")}
          </Typography>
          <Typography>
            {consultantRate ? `${consultantRate} ${t("userProfile:actions.makeAnOffer.euroPerDay")}` : NULL_DAILY_RATE}
          </Typography>
        </Box>
      </Box>

      <MakeAnOfferForm
        closeModal={closeModal}
        btnLabel={t("userProfile:actions.makeAnOffer.submitBtn")}
        formConfig={makeAnOfferConfig}
        handleSubmitForm={handleSubmitOffer}
      />
    </>
  );
};

export default MakeAnOffer;
