import create, { State, StateCreator } from "zustand";
import { devtools } from "zustand/middleware";

export const createStore = <TState extends State>(name: string, config: StateCreator<TState>) => {
  const isDevelop = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  const devConfig = isDevelop ? devtools(config, { name }) : config;

  return create<TState>(devConfig);
};
