import { FC, useContext, useState } from "react";

import { CircularProgress, Container, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ConsultantInfoModalContent, ConsultantInfoModalHeader, FullCvDialog } from ".";
import { MixpanelContext } from "../contexts/MixpanelContext";
import { useConsultantById } from "../hooks/consultants/useConsultantById";
import { MixpanelEvents } from "../types/mixpanel";
import { ConsultantsData } from "../types/users";

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
    maxWidth: "654px",
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  data: ConsultantsData;
}

const ConsultantOverviewPopperContent: FC<Props> = ({ data }) => {
  const { classes } = useStyles();
  const { mixpanelTrack } = useContext(MixpanelContext);

  const { consultantData, isLoading: isLoadingConsultant } = useConsultantById(data.id);

  const [showFullCv, setShowFullCv] = useState(false);

  const handleFullCvClick = () => {
    setShowFullCv(true);
    mixpanelTrack(MixpanelEvents.FULL_CV_VIEW);
  };
  const handleCloseFullCv = () => setShowFullCv(false);

  return (
    <Container className={classes.container}>
      {isLoadingConsultant || !consultantData ? (
        <CircularProgress />
      ) : (
        <>
          <ConsultantInfoModalHeader data={consultantData} />

          <Divider className={classes.divider} />

          <ConsultantInfoModalContent
            hasMaxHeight
            hasFullCvButton
            handleFullCvClick={handleFullCvClick}
            consultantDescription={data.description}
          />

          <FullCvDialog fullCvOpen={showFullCv} handleCloseFullCv={handleCloseFullCv} filePath={data.recentlyCVs} />
        </>
      )}
    </Container>
  );
};

export default ConsultantOverviewPopperContent;
