import { FC } from "react";

import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { DeletePopup, EmptyState, ModalContainer, TableActions } from "..";
import { DATE_FORMAT } from "../../constants";
import { useAllCompanies } from "../../hooks/company/useAllCompanies";
import { useDeleteCompany } from "../../hooks/company/useDeleteCompany";
import { useChangePage } from "../../hooks/useChangePage";
import { useDeleteItem } from "../../hooks/useDeleteItem";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { CompaniesData } from "../../types/companies";

interface Props {
  handleChangeMode: (data: CompaniesData) => () => void;
}

const CompaniesTable: FC<Props> = ({ handleChangeMode }) => {
  const { t } = useTranslation();

  const { page, perPage, handleChangePage, handleChangePerPage } = useChangePage();
  const { isLoading: isDeletingCompany, deleteCompany } = useDeleteCompany();
  const { itemToDelete, handleCloseModal, handleDeleteIconClick, handleDeleteItem } = useDeleteItem(deleteCompany);

  const { isLoading, companiesData } = useAllCompanies(page, perPage, handleChangePage);

  const tableBuilder: Column<CompaniesData> = {
    columns: [
      {
        dataKey: "name",
        label: t("companies:table.name"),
        dataRender: data => data.name,
        sortable: false,
      },
      {
        dataKey: "users",
        label: t("companies:table.users"),
        dataRender: data => data.usersInCompany,
        sortable: false,
      },
      {
        dataKey: "dateUpdated",
        label: t("companies:table.dateUpdated"),
        dataRender: data => `${t("companies:table.lastUpdated")} ${dayjs(data.lastUpdated).format(DATE_FORMAT)}`,
        sortable: false,
      },
      {
        dataKey: "actions",
        dataRender: data => (
          <TableActions deleteClick={handleDeleteIconClick(data.id)} editClick={handleChangeMode(data)} />
        ),
        sortable: false,
      },
    ],
  };

  if (isLoading || !companiesData || isDeletingCompany) {
    return <Loading />;
  }

  if (!companiesData.totalElements) {
    return <EmptyState text={t("emptyState:texts.companies")} />;
  }

  return (
    <Box>
      <CustomTable
        tableBuilder={tableBuilder}
        data={companiesData.data}
        page={page}
        perPage={perPage}
        totalElements={companiesData.totalElements}
        showPagination
        showRowsPerPage
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
      />
      <ModalContainer
        isOpened={!!itemToDelete}
        title={t("deletePopup:title", { label: t("companies:company") })}
        closeModal={handleCloseModal}
        size={"md"}
      >
        <DeletePopup
          label={t("companies:company")}
          handleDeleteAction={handleDeleteItem}
          cancelDeleteAction={handleCloseModal}
        />
      </ModalContainer>
    </Box>
  );
};

export default CompaniesTable;
