import { FC } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { CardContentSection, UpcomingCVsTable } from "../components";

const UpcomingCVs: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="description" content={t("upcomingCVs:description")} />
        <title>
          {t("upcomingCVs:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <CardContentSection title={t("upcomingCVs:title")} table={<UpcomingCVsTable />} />
    </>
  );
};

export default UpcomingCVs;
