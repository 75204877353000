import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import companyService from "../../services/companies";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { EditCompanyData } from "../../types/companies";
import { ErrorContent } from "../../types/default";

interface ReturnType {
  editCompany: UseMutateFunction<void, ErrorContent, EditCompanyData, unknown>;
  isLoading: boolean;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useEditCompany = (): ReturnType => {
  const addSnackBar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: editCompany, isLoading } = useMutation(companyService.editCompany, {
    mutationKey: QUERY_STRINGS.EDIT_COMPANY,
    onError: (error: ErrorContent) => addSnackBar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries(QUERY_STRINGS.GET_COMPANIES),
  });

  return { editCompany, isLoading };
};
