import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { AuthErrorCodes } from "firebase/auth";

import { DATE_FORMAT, TIME_FORMAT } from "./constants";
import { theme } from "./theme/index";
import { RequirementCommitmentMonths } from "./types/requirements";

dayjs.extend(isBetween);

export const getInitials = (firstName: string, lastName: string) => `${firstName[0]}${lastName[0]}`.toUpperCase();

export const getAvailabilityBorderColor = (number: number) => {
  const time = dayjs(number).valueOf();

  if (time < dayjs().add(3, "hours").valueOf()) {
    return { borderLeft: `6px solid ${theme.palette.success.light}` };
  }

  if (time >= dayjs().add(3, "hours").valueOf() && time <= dayjs().add(1, "day").valueOf()) {
    return { borderLeft: `6px solid ${theme.palette.warning.light}` };
  }

  if (time > dayjs().add(1, "day").valueOf()) {
    return { borderLeft: `6px solid ${theme.palette.error.light}` };
  }

  return null;
};

export const getFirebaseMessage = (error: string): string => {
  switch (error) {
    case AuthErrorCodes.EMAIL_EXISTS:
      return "Email already in use";
    case AuthErrorCodes.INVALID_PASSWORD:
      return "Wrong password";
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return "Too many requests, try again later";
    case AuthErrorCodes.USER_DELETED:
      return "User not found";
    default:
      return "Something went wrong";
  }
};

export const formatDate = (date: Date) => dayjs(date).format(DATE_FORMAT);
export const formatTime = (date: Date) => dayjs(date).format(TIME_FORMAT);

export const addMonthsToCurrentDate = (months: number) => dayjs().add(months, "months").valueOf();

export const getCommitmentDateAsMilliseconds = (futureCommitment?: number): number => {
  if (futureCommitment) {
    return addMonthsToCurrentDate(futureCommitment);
  }

  return dayjs().valueOf();
};

export const getCommitmentDate = (commitmentTimestamp: number): string | undefined => {
  if (dayjs(commitmentTimestamp).isBetween(addMonthsToCurrentDate(1), dayjs(addMonthsToCurrentDate(2)))) {
    return RequirementCommitmentMonths.ONE;
  }

  if (dayjs(commitmentTimestamp).isBetween(addMonthsToCurrentDate(2), dayjs(addMonthsToCurrentDate(3)))) {
    return RequirementCommitmentMonths.TWO;
  }

  if (dayjs(commitmentTimestamp).isAfter(dayjs(addMonthsToCurrentDate(3)))) {
    return RequirementCommitmentMonths.THREE;
  }

  return;
};

export const parseFalseValue = (value?: boolean) => {
  if (value) {
    return value;
  }
};
