import { AlertColor } from "@mui/material";

import { SnackbarStatus } from "../constants";
import { createStore } from "./storeUtils";

interface SnackbarType {
  text: string;
  type?: AlertColor;
}

export type SnackbarState = {
  data: SnackbarType;
  addSnackbar: (error: SnackbarType) => void;
  clearSnackbar: () => void;
};

const initialData = {
  text: "",
  type: SnackbarStatus.error,
};

export const useSnackbar = createStore<SnackbarState>("snackbar", set => ({
  data: initialData,
  addSnackbar: (data: SnackbarType) =>
    set(() => ({ data: { text: data.text, type: data.type ? data.type : SnackbarStatus.error } })),
  clearSnackbar: () => set(() => ({ data: initialData })),
}));
