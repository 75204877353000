import { FC, useContext } from "react";

import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ChannelFilters } from "stream-chat";
import { Channel, ChannelList, Chat, MessageInput, MessageList, Window } from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { makeStyles } from "tss-react/mui";
import shallow from "zustand/shallow";

import { CustomChannelPreview, CustomDateSeparator, CustomMessageInput } from "../components";
import "../components/Messages/Messages.scss";
import { ChatContext } from "../contexts/ChatContext";
import { formatTime } from "../functions";
import Loading from "../layouts/Loading";
import { UserState, useUserStore } from "../store/useUserStore";
import { UserRoles } from "../types/users";

const useStyles = makeStyles()(theme => ({
  container: {
    marginRight: theme.spacing(2.5),
    "& .str-chat.messaging": {
      borderRight: `1px solid ${theme.palette.dark.light}`,

      "& .str-chat__channel-list-messenger__main": {
        paddingInline: 0,
        paddingBlock: 0,
      },
    },
  },
}));

const currentUserSelector = (state: UserState) => ({
  userRole: state.role,
  getStreamId: state.getStreamId,
  firstName: state.firstName,
  lastName: state.lastName,
});

const Messages: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const id = useUserStore(state => state.id);
  const { userRole } = useUserStore(currentUserSelector, shallow);
  const { chatClient } = useContext(ChatContext);

  const filters: ChannelFilters<DefaultStreamChatGenerics> = {
    type: "messaging",
    members: { $in: [id!] },
    //TODO use to check for 24-48-72 hours???
    // last_message_at:
  };

  if (!chatClient) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t("messages:description")} />
        <title>
          {t("messages:title")} | {t("kommlink:title")}
        </title>
      </Helmet>

      <Box className={classes.container}>
        <Chat
          client={chatClient!}
          theme="messaging light"
          customStyles={{
            "--primary-color": "#A3C7F6",
            "--grey-gainsboro": "#ffffff",
            "--main-font": "unset",
            "--second-font": "unset",
          }}
        >
          <ChannelList
            filters={filters}
            showChannelSearch={userRole === UserRoles.superadmin}
            sort={{ last_message_at: -1 }}
            options={{ limit: 8 }}
            Preview={CustomChannelPreview}
          />
          <Channel Input={CustomMessageInput} DateSeparator={CustomDateSeparator}>
            <Window>
              <MessageList disableQuotedMessages messageActions={["edit", "delete", "react"]} formatDate={formatTime} />
              <MessageInput grow maxRows={4} />
            </Window>
          </Channel>
        </Chat>
      </Box>
    </>
  );
};

export default Messages;
