import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import usersService from "../../services/users";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";
import { RegisterUserTypes } from "../../types/users";

interface ReturnType {
  createUser: UseMutateFunction<void, ErrorContent, RegisterUserTypes, unknown>;
  isLoading: boolean;
}

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useCreateUser = (): ReturnType => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: createUser, isLoading } = useMutation(usersService.createUser, {
    mutationKey: QUERY_STRINGS.CREATE_USER,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_STRINGS.GET_CONSULTANTS);
      queryClient.invalidateQueries(QUERY_STRINGS.GET_MANAGERS);
      queryClient.invalidateQueries(QUERY_STRINGS.GET_SUPERADMINS);
    },
  });

  return { createUser, isLoading };
};
