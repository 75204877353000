import kommlinkApi, { BackendError } from "../lib/axios";
import { InitialMessageTypes } from "../types/notifications";

const sendFirstMessageToConsultant = async ({
  data,
  consultantId,
}: {
  data: InitialMessageTypes;
  consultantId: string;
}): Promise<void> => {
  try {
    await kommlinkApi.post<void>("/notifications/messages/initial", { ...data, consultantId });
  } catch (err: unknown) {
    const error = err as BackendError;

    throw new Error(error.message);
  }
};

const messagesService = {
  sendFirstMessageToConsultant,
};

export default messagesService;
