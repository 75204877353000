import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  containerAvailability: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2.25),
  },
  iconSize: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  error: {
    backgroundColor: theme.palette.error.light,
  },
}));

interface AvailabilityProps {
  className: string;
  label: string;
}

const AvailabilityBoxes: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const config: AvailabilityProps[] = [
    { className: `${classes.iconSize} ${classes.success}`, label: t("main:availability.small") },
    { className: `${classes.iconSize} ${classes.warning}`, label: t("main:availability.medium") },
    { className: `${classes.iconSize} ${classes.error}`, label: t("main:availability.large") },
  ];

  return (
    <>
      {config.map(box => (
        <Box className={classes.containerAvailability} key={box.label}>
          <Box className={box.className} />
          <Typography variant={"caption"} color={"textPrimary"} fontWeight={600} marginLeft={1.25}>
            {box.label}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default AvailabilityBoxes;
