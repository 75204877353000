import dayjs, { Dayjs } from "dayjs";

import { FormConstructor } from "../../../types/default";
import { EditInterviewTypes } from "../../../types/interviews";
import { FieldType } from "../../../types/login";

export const editUpcomingInterviewBuilder: FormConstructor<EditInterviewTypes<Dayjs>> = {
  fields: [
    {
      name: "interviewDate",
      label: "userProfile:actions.scheduleAnInterview.formFields.date",
      type: FieldType.date,
      defaultValue: dayjs(),
      validationOptions: {
        required: true,
        validateDate: {
          date: dayjs(),
          label: "userProfile:actions.scheduleAnInterview.errors.invalidDate",
        },
      },
      disablePastDate: true,
    },
    {
      name: "timeFrom",
      label: "userProfile:actions.scheduleAnInterview.formFields.timeFrom",
      validationOptions: {
        required: true,
      },
      type: FieldType.time,
      defaultValue: dayjs(),
    },
    {
      name: "timeTo",
      label: "userProfile:actions.scheduleAnInterview.formFields.timeTo",
      validationOptions: {
        required: true,
      },
      type: FieldType.time,
      defaultValue: dayjs(),
    },
    {
      name: "type",
      label: "userProfile:actions.scheduleAnInterview.formFields.type",
      validationOptions: {
        required: true,
      },
      type: FieldType.select,
      defaultValue: "",
      options: [],
      isFullWidth: true,
    },
    {
      name: "meetingURL",
      label: "userProfile:actions.scheduleAnInterview.formFields.meetingUrl",
      type: FieldType.text,
      defaultValue: "",
    },
    {
      name: "isInterviewed",
      label: "userProfile:actions.scheduleAnInterview.formFields.interviewed",
      type: FieldType.checkbox,
      defaultValue: "",
    },
  ],
};
