import { FC, useContext } from "react";

import { Box, Card, Grid } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import shallow from "zustand/shallow";

import { VerticalButtonsModal } from "../components";
import { PATHS } from "../constants";
import { AuthContext } from "../contexts/AuthContext";
import { UserState, useUserStore } from "../store/useUserStore";
import Loading from "./Loading";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const useStyles = makeStyles()(theme => ({
  container: {
    height: "calc(100vh - 80px)",
    overflow: "hidden",
  },
  sidebarItem: {
    width: "290px",
  },
  contentWrapper: {
    height: "100%",
  },
  contentCard: {
    height: "100%",
    borderRadius: "0",
    margin: theme.spacing(2.5, 2.5, 0, 2.5),
    boxShadow: theme.shadows[1],
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  content: {
    padding: theme.spacing(2.5, 4, 5, 4),
    alignItems: "center",
  },
}));

const authSelector = (state: UserState) => ({
  isLoggedIn: state.isLoggedIn,
});
const DashboardLayout: FC = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const { isLoading } = useContext(AuthContext);
  const { isLoggedIn } = useUserStore(authSelector, shallow);

  if (isLoading || !isLoggedIn) {
    return <Loading />;
  }

  const isRightSidebarVisible =
    location.pathname.includes(PATHS.consultants) ||
    location.pathname.includes(PATHS.messages) ||
    location.pathname.includes(PATHS.sublist) ||
    location.pathname.includes(PATHS.sentQuestions);

  return (
    <>
      <Navbar />
      <Grid container className={classes.container}>
        <Grid item className={classes.sidebarItem}>
          <Sidebar />
        </Grid>

        <Grid item xs className={classes.contentWrapper}>
          {location.pathname !== PATHS.messages ? (
            <Card raised className={classes.contentCard}>
              <Box className={classes.content}>
                <Outlet />
              </Box>
            </Card>
          ) : (
            <Box>
              <Outlet />
            </Box>
          )}
        </Grid>

        {isRightSidebarVisible && (
          <Grid item position={"relative"} width={"88px"}>
            <VerticalButtonsModal />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DashboardLayout;
