import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import questionsService from "../../services/questions";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useCreateQuestionaire = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: createQuestionaire, isLoading } = useMutation(questionsService.createQuestionaire, {
    mutationKey: QUERY_STRINGS.CREATE_QUESTIONAIRE,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries([QUERY_STRINGS.GET_QUESTIONAIRES]),
  });

  return { createQuestionaire, isLoading };
};
