import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import questionsService from "../../services/questions";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useEditQuestionaire = () => {
  const addSnackbar = useSnackbar(addSnackbarSelector);

  const queryClient = useQueryClient();

  const { mutate: editQuestionaire, isLoading } = useMutation(questionsService.editQuestionaire, {
    mutationKey: QUERY_STRINGS.EDIT_QUESTIONAIRE,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => queryClient.invalidateQueries([QUERY_STRINGS.GET_QUESTIONAIRES]),
  });

  return { editQuestionaire, isLoading };
};
