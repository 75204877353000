import { useQuery } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import questionsService from "../../services/questions";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackBarSelector = (state: SnackbarState) => state.addSnackbar;

export const useQuestionaires = (page: number, perPage: number) => {
  const addSnackBar = useSnackbar(addSnackBarSelector);

  const { isLoading, data: questionaires } = useQuery(
    [QUERY_STRINGS.GET_QUESTIONAIRES, page, perPage],
    () => questionsService.getQuestionaires(page, perPage),
    {
      keepPreviousData: true,
      onError: (e: ErrorContent) => addSnackBar({ text: e.message }),
    }
  );

  return {
    isLoading,
    questionaires,
  };
};
