import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import { QUERY_STRINGS } from "../../constants";
import consultantsService from "../../services/consultants";
import { SnackbarState, useSnackbar } from "../../store/useSnackbar";
import { ErrorContent } from "../../types/default";

const addSnackbarSelector = (state: SnackbarState) => state.addSnackbar;

export const useShareConsultant = () => {
  const { t } = useTranslation();
  const addSnackbar = useSnackbar(addSnackbarSelector);
  const queryClient = useQueryClient();

  const { mutate: shareConsultant, isLoading } = useMutation(consultantsService.shareConsultant, {
    mutationKey: QUERY_STRINGS.SHARE_CONSULTANT,
    onError: (error: ErrorContent) => addSnackbar({ text: error.message }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_STRINGS.GET_CONSULTANTS]);
      addSnackbar({ type: "success", text: t("userProfile:actions.shareConsultant.sharedConsultant") });
    },
  });

  return { shareConsultant, isLoading };
};
