import { ReactNode } from "react";

import { BaseEntity } from "./default";
import { Sublist } from "./sublist";
import { ConsultantsData } from "./users";

export enum NestedListItemType {
  share = "share",
  remove = "remove",
}

export enum NestedSublistItemType {
  add = "add",
  default = "default",
}

export enum ListType {
  default = "default",
  expandable = "expandable",
}

export interface NestedListItem extends BaseEntity {
  firstName: string;
  lastName: string;
  type?: NestedListItemType;
  action?: () => void;
}

export interface NestedSublistItem extends BaseEntity, Omit<Sublist, "consultants" | "owner"> {
  icon?: JSX.Element;
  type: NestedSublistItemType;
  consultants?: ConsultantsData[];
  action?: () => void;
  deleteAction?: () => void;
  editAction?: () => void;
}

export interface ListProps {
  label: string;
  icon: JSX.Element;
  type: ListType;
  path?: string;
  items?: NestedListItem[];
  sublists?: NestedSublistItem[];
  isPrivate: boolean;
  badge?: ReactNode;
}
