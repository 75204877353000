import React, { FC, useState } from "react";

import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardMedia, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

import { ModalContainer, RequirementOptions } from "..";
import { MASTER_USER_LIST, PATHS } from "../../constants";
import { UserState, useUserStore } from "../../store/useUserStore";
import { ActionTypes, CardButtonTypes, CardTypes } from "../../types/default";
import { RequirementData, RequirementOptionTypes } from "../../types/requirements";
import { UserRoles } from "../../types/users";

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: "rgba(26,115,232,0.04)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    paddingBlock: theme.spacing(3.5, 5),
    "&:hover": {
      cursor: "pointer",
      boxShadow: theme.shadows[2],
    },
  },
  cardHeader: {
    height: theme.spacing(9),
    width: theme.spacing(9),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBlock: theme.spacing(2.5, 4),
  },
  cardUpdated: {
    color: theme.palette.dark.main,
    marginBlock: theme.spacing(0.5),
  },
  consultantsContainer: {
    gap: theme.spacing(1),
    display: "flex",
  },
  consultantsIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  iconButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.dark.main,
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  },
  menuList: {
    padding: theme.spacing(0),
    border: "none",
  },
}));

interface Props {
  team: RequirementData;
  cardClick: () => void;
  hasOptions?: boolean;
  cardType: CardTypes;
  cardButton: CardButtonTypes;
}

const userRoleSelector = (state: UserState) => state.role;

const TeamCard: FC<Props> = ({ team, cardClick, hasOptions, cardType, cardButton }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const userRole = useUserStore(userRoleSelector);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ActionTypes | null>(null);
  const [modalTitle, setModalTitle] = useState("");

  const handleCloseOptions = () => setAnchorEl(null);
  const handleCloseModal = () => setModalOpen(false);

  const handleSetModalData = (type: ActionTypes, title: string) => () => {
    handleCloseOptions();
    setModalOpen(true);
    setModalType(type);
    setModalTitle(title);
  };

  const handleClickButton = () => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    cardButton.action(PATHS.consultants, team.title !== MASTER_USER_LIST ? team.id : undefined);
  };

  const handleMoreOptionsClick = () => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const cardTypeText =
    cardType === CardTypes.consultants ? "main:requirementCard.consultants" : "main:requirementCard.users";

  const dropDownMenuOptions: RequirementOptionTypes[] = [
    {
      label: t("main:requirementCard.actions.add"),
      icon: <Add color={"primary"} />,
      action: handleSetModalData(ActionTypes.ADD, t("main:requirementCard.modalTitle.addConsultantsToRequirement")),
    },
    {
      label: t("main:requirementCard.actions.edit"),
      icon: <Edit color={"primary"} />,
      action: handleSetModalData(ActionTypes.EDIT, t("main:requirementCard.modalTitle.edit")),
    },
    {
      label: t("main:requirementCard.actions.delete"),
      icon: <Delete color={"primary"} />,
      action: handleSetModalData(ActionTypes.DELETE, t("main:requirementCard.modalTitle.delete")),
    },
  ];

  return (
    <Card className={classes.container} onClick={cardClick}>
      <CardMedia component="img" image="/assets/main-pool-icon.svg" alt="sheet" className={classes.cardHeader} />

      <CardContent className={classes.cardContent}>
        <Typography variant={"h3"} color={"textPrimary"}>
          {team.title}
        </Typography>
        {/* TODO Add when ready on BE */}
        {/* <Typography variant={"caption"} fontWeight={600} className={classes.cardUpdated}>
          {t("main:requirementCard.updated")} {team.dateUpdated}
        </Typography> */}
        <Box className={classes.consultantsContainer}>
          <Box component={"img"} src={"/assets/users-icon.svg"} alt={"users"} className={classes.consultantsIcon} />

          <Typography variant={"caption"} color={"textSecondary"} alignSelf={"center"}>
            <Trans i18nKey={cardTypeText} color={"textSecondary"}>
              {{ members: team.numberOfConsultants }}
            </Trans>
          </Typography>
        </Box>
        {userRole === UserRoles.superadmin && <Typography variant={"caption"}>{team.managerName}</Typography>}
      </CardContent>

      {hasOptions && team.title !== MASTER_USER_LIST && (
        <>
          <IconButton className={classes.iconButton} onClick={handleMoreOptionsClick()}>
            <MoreVert />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseOptions}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            classes={{ list: classes.menuList }}
          >
            {dropDownMenuOptions.map(item => (
              <MenuItem key={item.label} className={classes.menuItem} onClick={item.action}>
                <Typography variant={"subtitle1"} color={"textPrimary"}>
                  {item.label}
                </Typography>
                {item.icon}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}

      <Button color="secondary" variant="contained" onClick={handleClickButton()}>
        {cardButton.icon}
        <Typography variant={"button"} marginLeft={cardButton.icon ? 0.5 : 0}>
          {cardButton.label}
        </Typography>
      </Button>

      <ModalContainer isOpened={openModal} title={modalTitle} closeModal={handleCloseModal} size={"lg"}>
        <RequirementOptions modalType={modalType} handleCloseModal={handleCloseModal} team={team} />
      </ModalContainer>
    </Card>
  );
};

export default TeamCard;
