import React, { FC, useEffect, useState } from "react";

import { Box, Checkbox, ClickAwayListener, Popper } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import shallow from "zustand/shallow";

import {
  AvatarCell,
  ConsultantCheckbox,
  ConsultantOverviewPopperContent,
  DeletePopup,
  EmptyState,
  ModalContainer,
  TableActions,
} from "..";
import { DATE_FORMAT, NULL_DAILY_RATE } from "../../constants";
import { getAvailabilityBorderColor } from "../../functions";
import { useDeleteConsultant } from "../../hooks/consultants/useDeleteConsultant";
import { useDeleteItem } from "../../hooks/useDeleteItem";
import useSort from "../../hooks/useSort";
import CustomTable, { Column } from "../../layouts/CustomTable";
import Loading from "../../layouts/Loading";
import { ConsultantsState, useConsultantsStore } from "../../store/useConsultantsStore";
import { UserState, useUserStore } from "../../store/useUserStore";
import { SortOrder } from "../../types/default";
import { FilterOptions } from "../../types/main";
import { ConsultantsData, UserRoles } from "../../types/users";

interface Props {
  filterOptions?: FilterOptions;
  handleChangeMode?: (data: ConsultantsData) => () => void;
  usersData?: ConsultantsData[];
  page?: number;
  perPage?: number;
  totalElements?: number;
  handleChangePerPage?: (newPage: number) => void;
  handleChangePage?: (newPage: number) => void;
  showPagination?: boolean;
  showRowsPerPage?: boolean;
  hasOptions?: boolean;
}

const useStyles = makeStyles()(theme => ({
  popperElement: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const consultantsDataSelector = (state: ConsultantsState) => ({
  selectedConsultants: state.selectedConsultants,
  addSelected: state.addSelected,
  resetSelected: state.resetSelected,
  resetLastSelected: state.resetLastSelectedConsultant,
});

const userRoleSelector = (state: UserState) => state.role;

const ConsultantsTable: FC<Props> = ({
  handleChangeMode,
  usersData,
  page,
  perPage,
  handleChangePage,
  handleChangePerPage,
  totalElements,
  showPagination = false,
  showRowsPerPage = false,
  hasOptions = false,
}) => {
  const { order, sortBy, handleSortClick } = useSort<ConsultantsData>(SortOrder.ASC, "dailyRate");
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { selectedConsultants, addSelected, resetSelected, resetLastSelected } = useConsultantsStore(
    consultantsDataSelector,
    shallow
  );
  const userRole = useUserStore(userRoleSelector);

  const { deleteConsultant, isLoading: isDeleteLoading } = useDeleteConsultant();
  const { itemToDelete, handleCloseModal, handleDeleteIconClick, handleDeleteItem } = useDeleteItem(deleteConsultant);

  const [anchorEl, setAnchorEl] = useState<null | undefined | HTMLElement>(null);
  const [currentConsultantData, setCurrentConsultantData] = useState<null | ConsultantsData>(null);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && usersData) {
      const newSelecteds = usersData.map(selected => selected.id);
      addSelected(newSelecteds);
      return;
    }

    resetSelected();
  };

  const handleRowHover = (data: ConsultantsData) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    setCurrentConsultantData(data);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setCurrentConsultantData(null);
  };

  useEffect(() => {
    return () => {
      resetSelected();
      resetLastSelected();
    };
  }, []);

  const tableBuilder: Column<ConsultantsData> = {
    rowStyles: data => getAvailabilityBorderColor(Number(data.interviewAvailability)),
    isRowSelected: rowData => selectedConsultants.includes(rowData.id),
    columns: [
      {
        dataKey: "select",
        label: (
          <Checkbox
            color="primary"
            onChange={handleSelectAllClick}
            checked={usersData && !!usersData?.length && selectedConsultants.length === usersData.length}
          />
        ),
        dataRender: rowData => (
          <ConsultantCheckbox
            data={rowData}
            selected={selectedConsultants.includes(rowData.id)}
            consultantsData={usersData}
          />
        ),
        sortable: false,
        selectable: true,
      },
      {
        dataKey: "name",
        label: t("main:tableHeaders.name"),
        dataRender: data =>
          data.firstName && (
            <AvatarCell
              imageUrl={data.imageUrl}
              firstName={data.firstName}
              lastName={data.lastName}
              onHover={handleRowHover(data)}
            />
          ),
        sortable: false,
      },
      {
        dataKey: "manager",
        label: t("main:tableHeaders.manager"),
        dataRender: data => data.managerDetails.map(manager => manager.name).toString(),
        sortable: false,
        private: true,
      },
      {
        dataKey: "dailyRate",
        label: t("main:tableHeaders.dailyRate"),
        dataRender: data => data.dailyRate ?? NULL_DAILY_RATE,
        sortable: true,
      },
      {
        dataKey: "jobAvailability",
        label: t("main:tableHeaders.availability"),
        dataRender: data => dayjs(data.jobAvailability).format(DATE_FORMAT),
        sortable: true,
      },
      {
        dataKey: "actions",
        label: undefined,
        dataRender: data =>
          userRole === UserRoles.superadmin &&
          hasOptions && (
            <TableActions
              deleteClick={handleDeleteIconClick(data.id)}
              editClick={handleChangeMode && handleChangeMode(data)}
            />
          ),
        sortable: false,
      },
    ],
  };

  if (!usersData || isDeleteLoading) {
    return <Loading />;
  }

  if (!usersData.length) {
    return <EmptyState text={t("emptyState:texts.consultants")} />;
  }

  return (
    <Box>
      <CustomTable
        tableBuilder={tableBuilder}
        data={usersData}
        page={page}
        perPage={perPage}
        totalElements={totalElements}
        order={order}
        sortBy={sortBy}
        handleSortClick={handleSortClick}
        showPagination={showPagination}
        handleChangePage={handleChangePage}
        handleChangePerPage={handleChangePerPage}
        showRowsPerPage={showRowsPerPage}
      />
      {currentConsultantData && (
        <ClickAwayListener onClickAway={handleClosePopover}>
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement={"top-start"}
            onMouseLeave={handleClosePopover}
            className={classes.popperElement}
            modifiers={[
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: false,
                  altBoundary: false,
                  tether: true,
                  rootBoundary: "viewport",
                  padding: 8,
                },
              },
            ]}
          >
            <ConsultantOverviewPopperContent data={currentConsultantData} />
          </Popper>
        </ClickAwayListener>
      )}

      <ModalContainer
        isOpened={!!itemToDelete}
        title={t("deletePopup:title", { label: t("consultants:consultant") })}
        closeModal={handleCloseModal}
        size={"md"}
      >
        <DeletePopup
          label={t("consultants:consultant")}
          handleDeleteAction={handleDeleteItem}
          cancelDeleteAction={handleCloseModal}
        />
      </ModalContainer>
    </Box>
  );
};

export default ConsultantsTable;
